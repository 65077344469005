.order-cta-cnt {
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;

  > div {
    @include align-items(center);
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(center);
    padding-bottom: 40px;
    padding-top: 40px;

    .order-cta {
      margin: 0 0 0 24px;
      max-width: 284px;
      overflow: hidden;

      @media (max-width: 980px) {
        max-width: 230px;
      }
    }
    .order-cta-btn {
      a.btn {
        margin-left: 16px;

        @media (max-width: 980px) {
          font-size: 14px;
          line-height: 32px;
          margin: 0 8px;
          padding: 0 16px;
        }
      }
    }

    @media (max-width: 980px) {
      @include align-items(flex-start);
      padding-bottom: 20px;
      padding-top: 0;
    }
  }

  h2 {
    font-size: 21px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 8px;
    text-transform: uppercase;

    @media (max-width: 980px) {
      font-size: 20px;
      line-height: 20px;
      margin-top: 22px;
      margin-bottom: 4px;
    }
  }

  p {
    line-height: 19px;
  }

  @media (max-width: 980px) {
    p {
      padding-top: 8px;
    }

    .order-cta-btn {
      margin: 24px 0 0;
      text-align: center;
      @include flex-basis(100%);
    }
  }

  a.white.btn {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 0 32px;
    text-transform: uppercase;
  }

  img {
    height: 48px;
    @media (max-width: 980px) {
      margin: 20px -10px 0 0;
    }
  }
}

.large-view,
.small-view {
  display: none;
}

@media (max-width: 979px) {
  .small-view {
    display: block;
  }
}
@media (min-width: 980px) {
  .large-view {
    display: block;
  }
}

.homepage {
  .video-cnt {
    background: #000;
    max-height: 720px;
    overflow: hidden;

    video {
      display: block;
    }
  }
}

.home-menu {
  padding: 56px 0;

  h3,
  h4 {
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }

  h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    margin: 8px 0;
  }

  .menu-cnt {
    @include justify-content(center);
    padding-top: 32px;

    .item {
      margin: 0 auto;
      position: relative;
      text-align: center;

      .bottom-bar {
        @include justify-content(center);
        margin-top: -48px;

        b {
          font-size: 18px;
          line-height: 22px;
          text-align: center;
        }

        img {
          height: 32px;
        }
      }

      @media (min-width: 980px) {
        margin-bottom: 32px;
      }

      small {
        font-size: 14px;
        line-height: 18px;
      }

      .instore-sticker {
        background: #000;
        border-radius: 100%;
        box-sizing: border-box;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        height: 68px;
        padding: 4px;
        position: absolute;
        right: 16px;
        top: 16px;
        text-transform: uppercase;
        width: 68px;

        img {
          display: block;
          margin: 3px auto;
        }
      }
    }

    @media (min-width: 980px) {
      @include display-flex();
      @include flex-wrap(wrap);

      > * {
        @include flex(0 0 33%);
      }
    }
  }
}

.mobile-app {
  background: #000;
  color: #fff;
  padding: 40px 0;

  h2,
  h3 {
    text-transform: uppercase;
  }

  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    margin: 80px 0 40px;

    @media (max-width: 979px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 300;
    line-height: 34px;

    @media (max-width: 979px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .btn.white {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    border-radius: 2em;
    margin: 24px 16px 0 0;
    max-width: 220px;
    line-height: 21px;
    padding: 0.75em 1.5em;

    img {
      margin-right: 8px;
    }

    @media (max-width: 979px) {
      font-size: 11px;
      line-height: 14px;
    }
  }

  .page-width > div {
    margin-left: 48px;
  }

  .page-width {
    @include align-items(flex-start);
    @include display-flex();
    @include justify-content(center);

    > img {
      margin-bottom: -101px;
    }

    > div {
      @include flex(0 0 50%);
    }
  }

  @media (max-width: 979px) {
    .page-width {
      overflow: hidden;
      padding: 0;

      > img {
        margin-bottom: 0;
        margin-left: -200px;
      }
    }
  }

  @media (min-width: 800px) {
    .btns-cnt {
      @include display-flex();

      > * {
        margin-right: 16px;
      }
    }
  }

  .download-qr {
    @include align-items(flex-start);
    @include display-flex();
    max-width: 400px;

    img {
      margin-right: 16px;
      width: 150px;
    }
    div {
      line-height: 32px;
      padding-top: 8px;
      font-size: 24px;
      text-transform: uppercase;
    }
  }

  @media (max-width: 979px) {
    .download-buttons {
      display: block;
    }
    .download-qr {
      display: none;
    }
  }
  @media (min-width: 980px) {
    .download-buttons {
      display: none;
    }
    .download-qr {
      @include display-flex();
    }
  }
}

.join-crumbl {
  padding: 100px 0 60px;

  .page-width > img {
    opacity: 0.25;
  }

  @media (max-width: 979px) {
    padding: 32px 0;

    .page-width > img {
      display: none;
    }
  }

  @media (min-width: 980px) {
    .page-width {
      @include display-flex();
      @include justify-content(space-between);

      margin-top: 20px;
      margin-bottom: -20px;

      > div {
        @include flex(0 0 38%);
      }
    }
  }

  .page-width {
    > div {
      @include align-items(flex-start);
      @include display-flex();
      @include justify-content(center);
      max-width: 360px;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      img {
        margin-right: 16px;
      }

      @media (max-width: 979px) {
        img {
          max-width: 70px;
        }
      }

      .btn {
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }

      h3 {
        font-size: 21px;
        font-weight: 600;
        line-height: 29px;
        margin-bottom: 8px;
        text-transform: uppercase;

        @media (max-width: 979px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      p {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;

        @media (max-width: 979px) {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}

.home-image {
  display: block;
  max-width: 200px;
  max-height: 400px;
  width: auto;
  height: auto;
}

@media (min-width: 1320px) {
  ._video-controller-icon {
    margin-top: 0.25rem;
    margin-right: 0.25rem;
    top: 0;
  }
}
