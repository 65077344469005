._site ._home {
  margin-top: -88px; //to overlap "._header-shadow"
  overflow-x: hidden;

  ._order-options {
    background: black;
    background-size: cover;

    ._page-width {
      color: #fff;
      padding-bottom: 30px;
      padding-top: 40px;
      text-align: center;

      h1 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 700;
      }

      p {
        font: 14px $regularFont;
        line-height: 18px;
        margin-bottom: 10px;
      }

      ._buttons {
        @include display-flex();
        @include flex-wrap(wrap);

        @include non-phone {
          @include justify-content(center);
        }

        > * {
          border: 0;
          box-sizing: border-box;
          font: 24px $regularFont;
          font-weight: bold;
          margin-top: 6px;
          padding: 8px;
          width: 20%;

          @include for-phone {
            @include align-items(center);
            @include display-flex();
            @include justify-content(space-between);

            background: #fff;
            border-radius: 8px;
            color: #000;
            font-size: 16px;
            margin: 1.5%;
            padding: 8px 16px;
            width: 47%;
          }

          > span {
            @include align-items(center);
            @include display-flex();
            @include justify-content(center);
          }

          span {
            height: 40px;
            @include justify-content(center);
            @include flex-shrink(0);
            @include flex-grow(1);

            @include for-phone {
              margin-right: 10px;
            }
          }
        }
      }

      @include non-phone {
        h1 {
          font-size: 40px;
          line-height: 49px;
          font-weight: 700;
        }

        p {
          font-size: 20px;
          line-height: 26px;
        }

        p,
        ._buttons {
          box-sizing: border-box;
          margin: 0 auto;
          max-width: 730px;
          padding: 0 20px;
        }

        ._buttons > * {
          @include flex-direction(column);
        }

        img {
          filter: invert(1);
        }
      }
    }
  }

  ._cookie-page {
    @include non-phone {
      display: none;
    }

    @include align-items(flex-start);
    @include display-flex();
    @include flex-direction(column);
    margin-top: 40px;
    opacity: 0;
    padding: 30px;
    transition: opacity 0.35s;

    ._exit {
      font-size: 40px;
      transition: transform 0.35s;
    }

    ._serving {
      line-height: 18px;
      margin-top: 10px;
      text-transform: uppercase;
    }

    p {
      padding: 20px 0 0;

      &._description {
        color: #777777;
      }
    }

    ._image-container {
      margin: 0 -30px;
      overflow: hidden;
      width: 100vw;

      img {
        width: 140vw;
        max-width: unset;
        margin: 0 -20vw;
        transition: transform 0.35s;
        transform: scale(0.5) translateY(-50vw) translateX(-80vw) rotate(-45deg);
      }

      &._orientation-right img {
        transform: scale(0.5) translateY(-50vw) translateX(80vw) rotate(45deg);
      }
    }

    h3 {
      font-size: 24px;
      line-height: 24px;
      padding-top: 20px;
    }

    &._move {
      opacity: 1;

      ._exit {
        transform: rotate(45deg);
      }

      ._image-container img {
        transform: none;
      }
    }
  }

  @include for-phone {
    &._cookie-selected > *:not(._cookie-page-container) {
      display: none;
    }
  }
}

._free-delivery {
  h3,
  h4 {
    font-weight: bold;
  }

  h3 {
    text-transform: uppercase;
  }

  ._button {
    display: block;
  }

  ._desktop-only {
    font: 20px $regularFont;
    line-height: 24px;
    position: relative;

    &:after {
      background: url(/images/cookies-icons-horizontal.png) no-repeat 100% 50%;
      background-position-x: 0;
      background-size: cover;
      bottom: 0;
      content: "";
      display: block;
      left: calc(((100vw - min(100vw, 1280px)) / 2) + 490px);
      position: absolute;
      right: 0;
      top: 0;

      @include for-x2 {
        background-image: url(/images/cookies-icons-horizontal@2x.png);
      }
    }

    ._page-width {
      padding-bottom: 60px;
      padding-top: 60px;
    }

    h3 {
      font-size: 55px;
      line-height: 67px;
    }

    h4 {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 10px;
    }

    ._button {
      margin-top: 24px;
    }
  }

  ._small-view {
    > div {
      font-size: 14px;
      line-height: 18px;
      padding: 40px 20px 0;
    }

    h3 {
      font-size: 40px;
      line-height: 49px;
    }

    h4 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    ._button {
      margin-top: 30px;
      width: 100%;
    }

    > img {
      display: block;
    }
  }

  @include for-phone {
    &._modal {
      @include align-items(center);
    }

    ._modal-inner {
      margin: 0 10px;
    }
  }

  ._modal-inner {
    max-width: 374px !important;

    ._modal-content {
      border-radius: 20px;
      overflow: hidden;
      padding: 0;
    }

    ._small-view > div {
      padding-top: 60px;
    }

    h3 {
      font-size: 38px;
      line-height: 47px;
    }
  }
}

._curbside-modal {
  b {
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
    margin: 20px 0;

    @include for-tablet {
      font-size: 24px;
      line-height: 29px;
      margin-top: 40px;
    }
  }

  ._content {
    @include align-items(center);
    @include display-flex();
    > img {
      margin-bottom: 20px;
      margin-right: 20px;
      max-width: 256px;

      @include for-tablet {
        margin: 20px 20px 0 -100px;
        max-width: 200px;
      }
    }
  }

  > div {
    @include flex-grow(1);
    margin-left: 20px;
  }
}

._qr-code {
  @include display-flex();
  font: 20px $mediumFont;
  line-height: 24px;

  img {
    margin-right: 20px;
    max-width: 150px;
  }
}

._mobile-view {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 40px;
  margin-right: 10px;

  ._button {
    background: #fff;
    color: #000;
    font-size: 11px;
    margin-top: 20px;
    padding: 10px;

    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    img {
      margin-right: 6px;
    }

    &:hover {
      color: #000;
    }
  }
}
