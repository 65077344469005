@media (min-width: 1024px) {
  .press-hero {
    background: url("/images/press-bg.jpg") no-repeat 100% 0;
    background-size: contain;
    margin-top: -88px;
    min-height: 50vw;
    padding-top: 200px;
  }
}

.press-image {
  background: url("/images/press-bg.jpg") no-repeat 100% 0;
  background-size: 600px;
}
