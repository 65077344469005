/* reset */
@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-basis($basis) {
  -moz-flex-basis: $basis;
  -webkit-flex-basis: $basis;
  flex-basis: $basis;
}

@mixin flex-direction($direction) {
  -moz-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-flow($flow) {
  -moz-flex-flow: $flow;
  -webkit-flex-flow: $flow;
  flex-flow: $flow;
}

@mixin flex-grow($grow) {
  -moz-flex-grow: $grow;
  -webkit-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
  -moz-flex-shrink: $shrink;
  -webkit-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

@mixin flex-wrap($wrap) {
  -moz-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-order($order) {
  -moz-order: $order;
  -webkit-order: $order;
  order: $order;
}

@mixin flex($flex) {
  -moz-flex: $flex;
  -webkit-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  justify-content: $justify;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
  align-content: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  align-self: $align;
}

@if $enable-global-styles {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote {
    &:before,
    &:after {
      content: "";
      content: none;
    }
  }

  q {
    &:before,
    &:after {
      content: "";
      content: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    outline: none;
  }
}
