.locations-page {
  .visit-stores {
    ._header {
      padding: 2em 1em 1em;
      text-align: center;

      h4,
      h3 {
        font-family: inherit;
        text-transform: uppercase;
      }

      h4 {
        font-size: 1.75em;
        letter-spacing: 0.15em;
        margin: 1em 0 0.5em;
      }
      h3 {
        font-size: 3em;
        font-weight: bold;
      }
    }

    ._states {
      @include display-flex();
      @include flex-wrap(wrap);
      text-align: center;

      > div {
        @include flex(0 0 30%);
        margin: 1em 1.5%;

        @media screen and (max-width: 600px) {
          @include flex(0 0 47%);
        }
      }

      b {
        display: block;
        font-size: 1.25em;
        font-weight: 400;
        margin-bottom: 1em;
        text-transform: uppercase;
      }

      li {
        margin: 0.25em 0;
        cursor: pointer;
        text-transform: capitalize;

        a {
          color: inherit;
        }
      }
    }
  }

  ._map-container {
    background: #000;
    height: 80vh;
    overflow: hidden;
    position: relative;
  }

  #locations-map {
    bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;

    //required attribution
    .mapboxgl-control-container {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      background: rgba(255, 255, 255, 0.25);
      bottom: 0;
      left: 0;
      padding: 8px;
      position: absolute;
      right: 0;

      button:empty {
        display: none;
      }

      a {
        color: #000;
        font-size: 12px;
      }
    }

    ._marker {
      background-size: contain;
      left: 0;
      position: absolute;
      top: 0;
    }

    .mapboxgl-popup {
      background: #fff;
      border-radius: 20px;
      box-shadow: 0px 3px 8px #00000026;
      left: 0;
      padding: 8px;
      position: absolute;
      top: 0;

      ._store-contact {
        font-size: 15px;
        line-height: 18px;
        margin: 10px auto 0;
        text-align: center;
        width: 90%;

        b {
          display: block;
          font-size: 21px;
          line-height: 24px;
        }

        p {
          font-weight: 500;
          margin-bottom: 4px;
        }

        a {
          color: #000;
          display: block;
        }
      }

      ._store-hours {
        border-top: 1px dashed #eee;
        line-height: 19px;
        margin-top: 20px;
        text-align: center;

        b {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  @include non-phone {
    ._mobile-view {
      display: none;
    }
  }

  @include for-phone {
    ._desktop-view {
      display: none;
    }
  }

  ._locations-head {
    background: #eee;
    padding: 20px;

    ._input {
      background: #fff url(/images/search-icon.svg) no-repeat 20px 50%;
      border: 0;
      border-radius: 30px;
      box-sizing: border-box;
      padding: 12px 12px 12px 46px;
      width: 100%;
    }

    ._tabs {
      border: 1px solid #000;
      border-radius: 30px;
      margin-top: 20px;
      overflow: hidden;

      > li {
        color: #000;
        padding: 10px 0;
        text-transform: lowercase;

        &:after {
          display: none;
        }

        &._selected {
          background: #000;
          color: #fff;
        }
      }
    }
  }

  ._tabs-content {
    > * {
      display: none;

      &._active {
        display: block;
      }
    }
  }

  ._mobile-locations {
    max-height: 50vh;
    overflow: auto;

    li {
      border-bottom: 1px solid #eee;
      line-height: 19px;
      padding: 20px;

      b {
        display: block;
      }
    }
  }

  ._loader-container {
    padding: 100px 0;
  }
}

.locations-page {
  .mapboxgl-ctrl-geocoder {
    > *:not(input, svg) {
      display: none !important;
    }

    > svg {
      height: 20px;
      left: 20px;
      opacity: 0.15;
      position: absolute;
      top: 12px;
      width: 20px;
    }

    > input {
      border: 1px solid #ccc;
      border-radius: 26px;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      padding: 12px 24px 12px 48px;
      width: 100%;
    }
  }
}
