$page-width: 1260px;
$page-width-padding: 15px;
@mixin _page-width() {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: $page-width;
  padding: 10px $page-width-padding;
}

@mixin _ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@function _page-offset() {
  @return unquote("max(calc(((100vw - #{$page-width}) / 2) + #{$page-width-padding}), #{$page-width-padding})");
}

body._hasPopup {
  height: 100vh;
  overflow: hidden; //the popup acts weird with a scroll from <body>
}

._large-loader {
  border-color: rgba(0, 0, 0, 0.05);
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 20px;
  height: 100px;
  margin: 0 auto;
  width: 100px;
}

._site {
  ._page-width {
    @include _page-width();
  }

  a,
  a:hover {
    color: inherit;
  }

  h2,
  ._bold {
    font-weight: bold;
  }

  ._button {
    background: #000;
    border: 0;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-family: $regularFont;
    font-weight: bold;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;

    &[disabled] {
      opacity: 0.15;

      @include for-phone {
        filter: invert(1);
        opacity: 0.75;
      }
    }

    &._bare-button {
      background: none;
      color: #aaaaaa;
    }

    &._white-button {
      background: #fff;
      color: #000;
    }
  }

  ._input {
    border: 1px solid #ccc;
    font: inherit;

    &._select {
      background: #fff url(/images/arrow-down.svg) no-repeat calc(100% - 24px) 50%;
      moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }
  }

  ._input-box {
    @include align-items(center);
    @include display-flex();
    border: 1px solid #aaa;
    border-radius: 50px;
    font: 16px $regularFont;
    padding: 12px 12px 12px 20px;
    transition: all 0.25s;

    img {
      height: 18px;
    }

    > input {
      background: none;
      border: 0;
      font: inherit;
      @include flex-grow(1);
    }

    > span {
      border-left: 1px solid #ddd;
      margin-left: 10px;
      padding: 0 10px;
    }

    &::placeholder,
    > input::placeholder {
      color: #aaa;
    }

    &._missing-required {
      background-color: #ffe5e5;
      border-color: #ff6868;

      &::placeholder,
      > input::placeholder {
        color: #ff6868;
      }
    }
  }

  sup {
    font-size: 12px;
    vertical-align: super;
  }

  ._header {
    background: #fff;
    border-bottom: 1px solid #f8f8f8;
    padding-bottom: -1px;
    position: relative;
    z-index: 1;

    ._page-width {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      max-width: unset;

      ._links {
        font: 12px $regularFont;
        text-align: right;
        > a {
          text-transform: uppercase;
        }
      }

      img {
        cursor: pointer;
      }

      > * {
        @include flex(0 0 30%);
      }

      ._menu > img {
        padding: 10px 5px;
      }

      ._logo {
        text-align: center;

        > img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  ._sidebar {
    background: black;
    background-size: cover;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    left: 0;
    overflow-y: auto;
    padding: 20px 20px;
    position: fixed;
    top: 0;
    transition: all 0.35s;
    width: 265px;
    z-index: 4;

    &-shadow {
      background: rgba(0, 0, 0, 0.25);
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 4;
    }

    ._actions {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      border-bottom: 1px solid #212121;
      font: 16px $regularFont;
      margin-bottom: 32px;
      padding-bottom: 25px;

      button {
        background: none;
        border: 0;
        color: #fff;
        cursor: pointer;
        font: 30px $lightFont;
        line-height: 20px;
        margin: 0;
        padding: 0;
        transform: rotate(45deg);
      }

      a {
        color: #aaa;
        font-weight: bold;
      }
    }

    ._primary a {
      display: block;
      font: 20px $regularFont;
      font-weight: bold;
      margin: 0 0 20px -10px;
      padding: 10px;
    }

    ._social {
      border: 1px solid #212121;
      border-width: 1px 0;
      padding: 15px 0;

      @include align-items(center);
      @include display-flex();

      > * {
        margin-right: 20px;
      }
    }

    ._secondary {
      @include display-flex();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
      padding-top: 40px;

      > li {
        @include flex(0 0 48%);
        padding-bottom: 20px;

        a {
          color: #aaa;
          font: 14px $regularFont;
        }
      }
    }

    &._sidebar-0 {
      transform: translateX(-330px);
    }

    &._sidebar-1 {
      transform: translateX(0);
    }
  }

  ._modal {
    bottom: 0px;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 4;
    @include align-items(flex-start);
    @include display-flex();

    &._long-modal {
      position: absolute;
      top: 100px;

      ._modal-content {
        border-radius: 20px;
        height: auto;
      }
    }

    opacity: 0;
    transition: opacity 0.35s;
    &._modal-1 {
      opacity: 1;
    }

    @include for-phone {
      opacity: 1;
      transform: translateY(100%);
      transition: transform 0.35s;

      &._modal-1 {
        transform: translateY(0);
      }
    }

    ._hide-modal {
      background: #fff;
      cursor: pointer;
      font-size: 40px;
      height: 30px;
      left: 14px;
      position: absolute;
      top: 14px;
      transform: rotate(45deg);
      width: 30px;
      z-index: 10;

      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      @include non-phone {
        margin-bottom: 15px;
      }
    }

    &-shadow {
      background: rgba(255, 255, 255, 0.9);
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 4;

      &._black {
        background: rgba(0, 0, 0, 0.75);
      }

      &._none {
        background: none;
      }
    }

    ._modal-content {
      background: #fff;
      border-radius: 20px 20px 0 0;
      height: 100%;
      padding: 20px 10px;
      position: relative;

      @include for-phone {
        //position: fixed;
        //top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60vh;
      }
    }

    @include for-phone {
      @include align-items(flex-end);

      ._modal-inner {
        width: 100%;
      }
    }

    &._has-scrollable ._modal-content {
      > * {
        @include display-flex();
        @include flex-direction(column);

        ._scrollable {
          @include flex-grow(1);
          overflow-y: auto;

          &:empty {
            padding: 0;
          }
        }
      }
    }

    ._modal-inner > ._note {
      color: #fff;
      display: block;
      font-size: 12px;
      line-height: 18px;
      padding: 10px 20px;
      text-align: center;
    }

    @include non-phone {
      top: 0;
      @include justify-content(center);

      ._modal-inner {
        margin-top: 5vh;
        max-width: 770px;
        width: 70%;
      }

      ._modal-content {
        border-radius: 20px;
        box-sizing: border-box;
        margin: 0 auto;
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    &._order-options-modal ._modal-content {
      background: black;
      background-size: cover;
      color: #fff;

      ._hide-modal {
        background: none;
        color: #fff;

        @include non-phone {
          font-size: 58px;
          left: 28px;
          top: 28px;
        }

        @include for-phone {
          top: 20px;
        }
      }

      h3 {
        font: 24px $regularFont;
        font-weight: bold;
        line-height: 29px;
        margin: 0 auto 25px;
        max-width: 85%;
        text-align: center;

        @include non-phone {
          font-size: 36px;
          line-height: 44px;
        }
      }

      ._options {
        > * {
          background: #fff;
          border-radius: 8px;
          color: #000;
          margin-bottom: 10px;
          padding: 15px 20px;

          @include display-flex();

          > span {
            @include flex(0 0 62px);
            margin-right: 10px;
            width: 62px;

            @include align-items(center);
            @include display-flex();
            @include justify-content(center);
          }

          b {
            display: block;
            font: 20px $regularFont;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 5px;
          }

          p {
            font: 14px $regularFont;
            line-height: 18px;
          }
        }

        @include non-tablet {
          @include display-flex();
          @include flex-wrap(wrap);
          @include justify-content(space-between);

          > * {
            @include flex(0 0 47%);
            box-sizing: border-box;
            margin: 1.5%;
          }
        }
      }
    }
  }

  ._cta-block {
    ._page-width {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      font: 14px $regularFont;

      > * {
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;
      }

      ._store-icon {
        @include align-items(center);
        @include display-flex();

        img {
          margin-right: 10px;
        }
      }
    }

    a._button:hover {
      color: #fff;
    }

    &._is-on-order-page {
      @include for-phone {
        display: none;
      }

      @include non-phone {
        ._button {
          display: none;
        }
      }
    }
  }

  @include for-phone {
    ._cta-block {
      background: #fff;
      border-top: 1px solid #f8f8f8;
      left: 0;
      right: 0;
      z-index: 2;
      /*top: calc(100dvh - 88px);
      position: fixed;
      transform: translate3d(0, 0, 0);*/

      ._page-width {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }

  @media screen and (max-width: 480px) and (max-height: 300px) {
    ._cta-block {
      display: none;
    }
  }

  ._header-container {
    background: #fff;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;

    @include for-phone {
      z-index: 3;
    }
  }

  @include for-phone {
    ._header-shadow {
      height: 49px;
    }
  }

  @include non-phone {
    ._header-shadow {
      height: 68px;
    }

    ._header-container {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      @include _page-width();
      border-radius: 0 0 8px 8px;

      ._header {
        border: 0;
      }

      ._links {
        display: none;
      }

      ._logo img {
        height: 65px;
        max-width: unset;
      }

      ._page-width {
        padding: 0;
      }
    }
  }

  ._checkbox-cnt {
    @include align-items(center);
    @include display-flex();

    input[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      height: 27px;
      margin-right: 8px;
      width: 27px;

      &:checked {
        background-image: url(/images/checkbox-active.svg);
        background-size: cover;
        border-color: transparent !important;
      }

      &.rounded-full {
        border-radius: 100%;
      }
    }
  }

  [role="tablist"]._tabs,
  ul._tabs {
    @include display-flex();
    @include justify-content(center);
    border-bottom: 1px solid #e8e8e8;

    [role="tab"],
    > li {
      @include flex-grow(1);

      color: #7e7e7e;
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      padding-bottom: 20px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.25s;

      &:after {
        background: #000;
        border-radius: 4px;
        bottom: -5px;
        content: "";
        display: block;
        height: 8px;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transition: all 0.25s;
      }

      &._selected {
        color: #000;

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.intercom-app > iframe {
  bottom: 100px !important;
}

body._hide-cta ._cta-block {
  display: none;
}

._site ._modal._login-modal {
  text-align: left;
  z-index: 11;

  ._modal-inner {
    max-width: 550px;
  }
  ._modal-content {
    padding: 20px 65px;

    @include for-phone {
      padding: 20px;
    }
  }

  .loader {
    @extend ._large-loader;
  }

  ._login-head {
    text-align: center;

    > img {
      margin: 0 auto;
      width: 65px;
    }

    ul {
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      margin: 10px 0 30px;

      > li {
        @include flex(0 0 50%);
        color: #aaaaaa;
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
        line-height: 37px;
        padding-bottom: 20px;

        &._selected {
          color: #000;
          position: relative;

          &:after {
            background: #000;
            border-radius: 4px;
            bottom: -5px;
            content: "";
            display: block;
            height: 8px;
            left: 0;
            position: absolute;
            right: 0;
          }
        }
      }
    }

    h2 {
      border-bottom: 1px dashed #aaa;
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      margin: 0 auto 30px;
      max-width: 240px;
    }
  }

  ._row {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    margin-bottom: 10px;

    label {
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin-right: 10px;
    }
  }

  small._validation-error {
    color: red;
    display: block;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  ._button {
    display: block;
    margin: 10px auto;
    width: 100%;

    &._bare-button {
      margin-bottom: 0;
      padding-bottom: 0;

      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      img {
        margin-left: 10px;
        opacity: 0.15;
      }

      @include for-phone {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  ._actions {
    margin-top: 60px;
  }
}

._site._page {
  ._title ._page-width {
    padding-bottom: 24px;
    padding-top: 24px;

    h2 {
      font-size: 40px;
      line-height: 49px;
    }
  }

  ._content-container {
    @include display-flex();

    > * {
      box-sizing: border-box;
      padding: 40px 0;
    }

    ._side {
      background: #efefef;
      background-size: 100%;
      padding-left: _page-offset();
      padding-right: 20px;
      width: calc(max(calc(((100vw - 1260px) / 2) + 15px), 15px) + 315px);

      li {
        background: #fff;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 2px;
        padding: 20px 15px;
        transition: all 0.15s;

        @include display-flex();
        @include justify-content(space-between);

        > div {
          @include align-items(center);
          @include display-flex();

          img {
            margin-right: 10px;
          }
        }

        &._selected {
          background: #000;
          color: #fff;

          img {
            filter: invert(1);
          }
        }

        @include non-phone {
          > img {
            display: none;
          }
        }
      }

      ._button {
        margin-top: 30px;
        width: 100%;
      }
    }

    ._content {
      background: #f6f6f6;
      padding-left: 20px;
      padding-right: _page-offset();
      width: calc(max(calc(((100vw - 1260px) / 2) + 15px), 15px) + 915px);
    }
  }

  @include for-phone {
    background: #efefef;

    ._side {
      background: none;
    }

    ._content-parent {
      overflow: hidden;
      width: 100vw;

      ._content-container {
        transition: transform 0.35s;
        width: 200vw;

        > div {
          @include flex(0 0 100vw);
          padding: 20px;
        }
      }
    }

    ._input-row {
      @include flex-direction(column);

      > *:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    ._title ._page-width {
      padding-bottom: 0;
      padding-top: 40px;

      h2 {
        font-size: 24px;
        line-height: 29px;
      }
    }

    &._focus-1 {
      ._side li._selected {
        background: #fff;
        color: #000;

        img {
          filter: none;
        }
      }
      ._content {
        height: 10px;
      }
    }

    &._focus-2 {
      ._title {
        display: none;
      }

      ._content {
        background: none;

        h3 {
          font-size: 24px;
          line-height: 29px;
        }

        ._actions {
          margin-top: 20px;
          @include flex-direction(column);

          ._button {
            margin-top: 20px;
          }
        }
      }

      ._content-container {
        transform: translateX(-100vw);
      }
    }
  }
}

._mobile-page2-header {
  display: none;
}

@include non-phone {
  ._mobile-back-button {
    display: none;
  }
}

@include for-phone {
  ._mobile-back-button {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    background: #fff;
    border: 0;
    height: 50px;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 3;
    width: 50px;
  }

  ._mobile-pages-container {
    overflow: hidden;
    width: 100vw;

    ._mobile-pages {
      @include display-flex();
      transition: transform 0.35s;
      width: 200vw;

      > * {
        width: 100vw;
      }

      &._focus-2 {
        transform: translateX(-100vw);
      }

      &._focus-1 ._mobile-page-1 {
        height: 0;
        overflow: hidden;
      }
    }

    ._mobile-page-0 {
      background: #efefef;
      padding: 20px 0;

      h1 {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 20px;
        text-align: left;
      }

      [role="tablist"]._tabs,
      ._tabs {
        border: 0;
        @include flex-direction(column);

        [role="tab"],
        li {
          @include align-items(center);
          @include display-flex();
          @include justify-content(space-between);

          background: #fff;
          border-radius: 8px;
          color: #000;
          font-size: 16px;
          font-weight: normal;
          line-height: 19px;
          margin-bottom: 2px;
          padding: 20px;
          text-align: left;
          text-transform: none;

          &:after {
            background: url(/images/arrow-right.svg) no-repeat 50% 50%;
            bottom: unset;
            display: block;
            height: 20px;
            opacity: 1;
            position: relative;
            right: unset;
            width: 20px;
          }
        }
      }
    }

    ._mobile-page2-header {
      background: #000;
      color: #fff;
      display: inline-block;
      font-size: 24px;
      line-height: 30px;
      margin-top: 20px;
      padding: 12px 20px;

      b {
        display: block;
        font-size: 30px;
      }
    }
  }
}

._cartpage-header {
  @include display-flex();
  @include flex-direction(column);
  background: #fff;
  left: 0;
  padding-bottom: 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;

  ._logo img {
    height: 65px;
  }

  ._page-width {
    @include display-flex();
    @include justify-content(space-between);
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;

    &:first-child {
      padding-bottom: 30px;
    }

    > div {
      @include align-items(center);
      @include display-flex();

      &:first-child > * {
        margin-right: 30px;
      }
      &:last-child > * {
        margin-left: 20px;
      }
    }

    a {
      color: #777777;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
    }

    ._button {
      padding: 15px 40px;
    }

    h2 {
      font-size: 40px;
      line-height: 49px;
    }
  }

  ._checkout-button {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    span {
      background: url(/images/checkout-icon.svg) no-repeat 50% 50%;
      color: #000;
      font-size: 11px;
      height: 20px;
      line-height: 24px;
      margin-right: 10px;
      min-width: 22px;

      &._smaller {
        font-size: 10px;
      }
    }
  }
}

._horizontal-items {
  overflow: hidden;
  position: relative;

  ul {
    @include display-flex();
    overflow: hidden;
    margin-left: -20px;
    padding: 20px 0 20px 20px;
  }

  > *:first-child {
    transition: transform 0.25s;
  }

  ._horizontal-items-arrow {
    position: absolute;

    button {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      background: #fff;
      border: 0;
      border-radius: 50%;
      height: 50px;
      width: 50px;

      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }

      img {
        height: 18px;
      }
    }
  }

  ._horizontal-items-left-fade,
  ._horizontal-items-right-fade {
    background: rgba(239, 239, 239, 0.65);
    bottom: 0;
    position: absolute;
    top: 0;
  }

  ._horizontal-items-left-fade {
    left: 0;
  }

  ._horizontal-items-right-fade {
    right: 0;
  }
}

@include for-phone {
  ._cartpage-header {
    &,
    & > ._page-width:first-child {
      display: block;
    }

    > ._page-width {
      h2 {
        font-size: 24px;
        line-height: 29px;
      }
      &:first-child {
        border-bottom: 1px solid #efefef;
        margin-bottom: 20px;
        padding-bottom: 10px;

        > div:first-child {
          @include justify-content(space-between);

          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 44px;
          }
        }

        > div:last-child {
          bottom: 20px;
          left: 20px;
          position: fixed;
          right: 20px;

          > *:not(._button) {
            display: none;
          }

          ._button {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

._detailed-receipt {
  padding: 2rem;
  min-height: 400px;
  width: 100%;
  overflow-x: auto;

  & table {
    margin-top: 1rem;
    width: 100%;
  }

  & th {
    font-weight: 800;
    padding: 1rem;
    border-bottom: 1px solid #aaa;
  }

  & td {
    text-align: center;
    padding: 1rem;
  }

  & td:first-child {
    min-width: 200px;
  }

  tr:nth-child(even) {
    background-color: rgb(231, 231, 231);
  }

  & ._section {
    padding-bottom: 2rem;
    border-bottom: 1px dashed #aaa;

    & ._title {
      font-size: 1.5rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }
  }
}
