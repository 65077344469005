.coming-soon {
  padding-top: 40px;
  padding-bottom: 10px;
  text-align: center;
  img {
    margin: auto;
    width: 150px;
  }
  p {
    text-align: center;
    padding: 20px;
  }
  .big {
    padding-top: 40px;
    font-size: 60px;
  }
  .sub {
    margin: auto;
    max-width: 400px;
  }
}
