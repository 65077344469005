.gift-page {
  color: #333;

  > div {
    padding: 1em;
  }

  > div:first-child {
    background: #f7f7f7;
  }

  h3 {
    font-family: inherit;
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.15em;
  }

  ._head {
    text-align: center;

    img {
      border: 2px solid #fff;
      border-radius: 50%;
      margin: 1em 0;
      width: 90px;
    }
  }

  ._msg {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 5px #eee;
    color: #555;
    padding: 1.5em 1em;
  }

  ._date {
    color: #999;
    display: block;
    font-size: 0.7em;
    margin: 0.5em 0;
    text-align: right;
  }

  ._arrow {
    text-align: center;

    a {
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 3px 5px #eee;
      height: 40px;
      margin-bottom: -2.25em;
      width: 40px;
      display: inline-block;
    }
  }

  ._receive {
    padding-top: 2em;
  }

  ._details {
    padding: 1em 0;
  }

  .btn {
    margin: 0 auto;
    padding: 0.75em 1em;
  }

  .input-wrapper {
    margin: 0.5em 0;
  }
}
