$enable-global-styles: false;
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600");
@import "animation";
@import "reset";
@import "responsive";
@import "fonts";
@import "cart";
@import "hamburger";
@import "soon";
@import "contact";
@import "thankyou";
@import "catering";
@import "jobs";
@import "items";
@import "home";
@import "survey";
@import "gift";
@import "tracking";
@import "locations";
@import "menu";
@import "common";
@import "header";
@import "franchising";
@import "nutrition";
@import "faq";
@import "site";
@import "homepage"; //TODO: delete old "home" css
@import "order";
@import "account";
@import "giftcard";
@import "deliverytracker";
@import "pr";
