._account-form {
  h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 10px;
  }

  &-content {
    background: #fff;
    border-radius: 8px;
    margin-top: 20px;
    padding: 30px;
  }

  > ._section {
    border-top: 1px dashed #aaa;
    border-width: 1px 0;
    margin: 5px 0;
    padding: 10px 0 20px;
  }

  ._input {
    border-color: #aaa;
    border-radius: 30px;
    font-size: 16px;
    line-height: 19px;
    padding: 12px 24px;
  }

  label {
    display: block;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 10px;

    &._checkbox-cnt {
      font-size: 16px;
      font-weight: normal;

      @include justify-content(center);

      input {
        border-color: #aaa;
      }
    }
  }

  ._row {
    @include display-flex();

    @include for-phone {
      @include flex-direction(column);
    }
  }

  ._input-row {
    @include justify-content(space-between);
    > * {
      @include flex(0 0 calc(100% / 2 - 40px));
    }
  }

  ._input-box {
    background: #fff;
    box-sizing: border-box;
    width: 100%;
  }

  ._actions {
    margin-top: 10px;

    @include display-flex();
    @include justify-content(space-between);

    ._button {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      min-width: 160px;

      .loader {
        border-color: rgba(255, 255, 255, 0.5);
        border-top-color: rgba(255, 255, 255, 0.25);
        margin-left: 10px;
      }
    }
  }

  ._rows-container {
    max-height: 60vh;
    overflow: auto;

    &:empty {
      border-bottom: 0;
    }

    ._loader-container {
      padding: 100px 0;
    }
  }

  ._task-list-loader-wrapper {
    height: 375px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ._row._box {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 20px;

    @include non-phone {
      @include align-items(center);
      @include justify-content(space-between);
    }

    &:not(._history) > img {
      margin-left: 20px;
      opacity: 0;

      @include for-phone {
        display: none;
      }
    }

    &._selected {
      border-color: #000;

      > img {
        border-radius: 50%;
        opacity: 1;
      }
    }

    ._card-detail {
      @include flex-grow(1);
      font-weight: bold;

      @include non-phone {
        display: grid;
        grid-template-columns: 30% 30%;
      }

      @include for-phone {
        > *:not(:first-child) {
          margin-top: 10px;
        }
      }

      div {
        @include align-items(center);
        @include display-flex();

        img {
          height: 20px;

          @include non-phone {
            margin-left: 30px;
          }
        }
      }
    }

    ._address-detail {
      @include flex-grow(1);
      line-height: 22px;

      @include non-phone {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 10px;
      }

      @include for-phone {
        > *:not(:first-child) {
          margin-top: 20px;
        }
      }

      b {
        display: block;
      }
    }

    &._history {
      @include for-phone {
        > *:not(:first-child) {
          margin-top: 20px;
        }
      }

      @include non-phone {
        display: grid;
        grid-template-columns: 5% 30% 15% 15% 35%;
        grid-column-gap: 10px;
      }

      img {
        height: 18px;
        max-width: unset;

        @include non-phone {
          margin: 0 auto;
        }
      }

      small {
        font-size: 14px;
        line-height: 18px;
      }
    }

    ._dots {
      margin: 0 10px;

      span {
        background: #000;
        border-radius: 50%;
        height: 8px;
        margin-left: 4px;
        width: 8px;
      }
    }
  }

  ._customer-service {
    padding-top: 20px;

    h5 {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
    }

    a {
      font-weight: bold;
    }
  }
}

._add-card-container {
  padding: 0 30px;
  h2 {
    border-bottom: 1px dashed #aaa;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    text-align: center;
  }

  img {
    display: block;
    height: 120px;
    margin: 0 auto;
  }

  ._credit-card-input {
    border: 1px dashed #aaa;
    border-width: 1px 0;
    margin: 24px 0 12px;
    padding: 16px 0;
  }

  ._button {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
  }
}

._crumbl-card {
  background: #000 url(/images/card-bg.svg) no-repeat -43% -30%;
  border-radius: inherit;
  height: 185px;
  position: relative;
  width: 290px;
  @include flex-shrink(0);

  > div {
    bottom: 20px;
    color: #aaa;
    font-size: 11px;
    position: absolute;
    right: 20px;
    text-align: right;
    text-transform: uppercase;

    b {
      color: #fff;
      display: block;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }

  ._qr-code {
    display: none;

    @include for-phone {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}

._crumbl-card-page {
  ._section-title {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);

    background: url(/images/dotted.svg) repeat-x 0 100%;
    color: #aaaaaa;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    padding-bottom: 10px;

    h3 {
      color: #000;
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
    }

    ._info {
      @include align-items(center);
      @include display-flex();

      img {
        opacity: 0.35;
      }

      span {
        margin-left: 8px;
      }

      @include for-phone {
        span {
          display: none;
        }

        img {
          opacity: 1;
        }
      }
    }
  }

  ._crumbl-card-container {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);
    background: #fff;
    border-radius: 10px;

    @include for-phone {
      @include align-items(stretch);
      @include flex-direction(column);

      ._crumbl-card {
        width: 100%;
      }
    }
  }

  ._crumbs-container {
    @include align-items(center);
    @include display-flex();
    padding: 0 26px;

    @include for-phone {
      padding: 20px;
    }

    > img {
      height: 40px;
      margin-right: 12px;
      width: 40px;

      @include for-phone {
        height: 30px;
        margin-right: 10px;
        width: 30px;
      }
    }

    &,
    > div {
      @include flex-grow(1);
    }

    ._crumbs-progress {
      background: #000;
      border-radius: 10px;
      height: 10px;
      margin-bottom: 12px;
      position: relative;

      @include for-phone {
        margin-bottom: 10px;
      }

      > div {
        background: #fad0dc;
        border-radius: 10px;
        bottom: 0;
        left: -1px;
        position: absolute;
        top: 0;
      }
    }

    ._crumbs-labels {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      color: #aaa;
      font-family: $mediumFont;
      font-size: 14px;
      line-height: 19px;
      text-align: right;

      @include for-phone {
        font-size: 12px;
        line-height: 15px;
      }

      b {
        color: #000;
        font-size: 17px;
        font-weight: bold;
        margin-right: 20px;
        text-align: left;
        text-transform: uppercase;

        @include for-phone {
          font-size: 14px;
        }
      }
    }
  }
  ._actions {
    font-family: $mediumFont;
    font-size: 16px;
    line-height: 19px;
    margin: 20px 0 40px;

    &,
    > a {
      @include align-items(center);
      @include display-flex();
    }

    > a {
      margin-right: 32px;

      @include for-phone {
        @include align-items(center);
        @include flex(0 0 28%);
        @include flex-direction(column);
        @include justify-content(center);
        font-size: 12px;
        margin-right: 0;
        position: relative;

        &:before {
          background: #fff;
          border-radius: 22px;
          content: "";
          display: block;
          height: 44px;
          position: absolute;
          top: 0;
          width: 44px;
        }

        span span {
          display: none;
        }
      }
    }

    img {
      margin-right: 5px;
      max-height: 26px;
    }

    @include for-phone {
      @include flex-direction(row !important);
      @include justify-content(center);

      img {
        margin: 10px;
        position: relative;
      }
    }
  }

  ._row {
    @include align-items(center);
    @include display-flex();
    border-bottom: 1px solid #aaaaaa;
    padding: 16px;

    &:first-child {
      padding-top: 0;
    }

    > img {
      margin-right: 10px;
    }

    div {
      font-size: 12px;
      line-height: 15px;

      b {
        display: block;
        font-family: $semiBoldFont;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

._green-text {
  color: #06bc7e;
}

._red-text {
  color: #db4156;
}

._site ._modal {
  &.modal-md ._modal-inner {
    max-width: 506px;
  }

  &._account-modal {
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    ._modal-inner {
      margin-bottom: 20px;
      max-width: 580px;
    }

    ._modal-content {
      padding-left: 70px;
      padding-right: 70px;
    }

    ._hide-modal {
      background: none;
      color: #000;
      font-size: 60px;
      margin: 30px;
      position: absolute;
    }

    ._centered {
      display: block;
      margin: 30px auto;
      text-align: center;
    }

    h3 {
      font-size: 30px;
      font-weight: bold;
      line-height: 37px;
      margin: 4px 0 10px;
      text-align: center;
    }

    h4 {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 10px;
    }

    ._subtitle {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;

      @include for-phone {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }

    ._separator {
      background: #dedede;
      height: 1px;
      margin: 40px 0;
    }

    ._explanation {
      @include align-items(center);
      @include display-flex();

      > :first-child {
        margin-right: 16px;
      }
    }

    ._steps {
      li {
        @include align-items(flex-start);
        @include display-flex();
        margin-bottom: 40px;
      }

      ._icon {
        @include align-items(center);
        @include display-flex();
        @include flex-shrink(0);
        @include justify-content(center);

        border-radius: 44px;
        box-shadow: 0px 3px 8px #00000026;
        height: 88px;
        margin-right: 30px;
        width: 88px;

        img {
          margin: 0 -5px -5px 0;
          width: 40px;
        }
      }
    }

    ._faq {
      li {
        background: #efefef;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 40px;

        b {
          display: block;
          margin-bottom: 12px;
        }
      }
    }

    ._crumbl-card {
      border-radius: 10px;
      box-shadow: 0px 3px 8px #00000026;
      margin: 10px auto 30px;
      transform: rotate(-4deg);

      @include for-phone {
        margin-top: 20px;
      }
    }

    ._add-amount {
      @include align-items(flex-start);
      @include display-flex();
      @include justify-content(center);
      margin-bottom: 20px;

      button {
        background: url(/images/add-button.svg);
        border: 0;
        border-radius: 50%;
        box-shadow: 0px 3px 8px #00000026;
        cursor: pointer;
        height: 60px;
        margin: 0 0 0 20px;
        width: 60px;

        &:first-child {
          background: url(/images/subtract-button.svg);
          margin: 0 20px 0 0;
        }
      }

      label {
        span {
          color: #aaa;
          display: block;
          font-family: $mediumFont;
          font-size: 12px;
          margin: -4px 0 4px;
          text-transform: uppercase;
        }

        b {
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    ._amount-info {
      background: #efefef;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      padding: 20px;

      > div {
        @include display-flex();
        @include justify-content(space-between);

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    ._total-info {
      @include display-flex();
      @include justify-content(space-between);
      font-size: 30px;
      font-weight: bold;
      padding: 30px 20px;
      text-transform: uppercase;

      @include for-phone {
        font-size: 16px;
        line-height: 19px;
        padding: 30px 10px;
      }
    }

    ._cc-container {
      background: url(/images/dotted.svg) repeat-x 0 100%;
      padding-bottom: 20px;

      img {
        max-height: 30px;
      }

      label {
        @include display-flex();
        font-family: $mediumFont;
        font-size: 16px;
        line-height: 19px;

        span {
          @include flex-grow(1);
          background: url(/images/dotted.svg) repeat-x 0 50%;
          margin-left: 20px;
        }
      }

      > div {
        padding-top: 10px;
      }

      ._selected-cc {
        @include justify-content(space-between);

        &,
        div {
          @include align-items(center);
          @include display-flex();
        }

        b {
          margin: 0 20px 0 10px;
        }
      }

      ._circles {
        @include display-flex();
        > span {
          background: #000;
          border-radius: 50%;
          height: 8px;
          margin-left: 3px;
          width: 8px;
        }
      }
    }

    ._button {
      margin: 30px 0 20px;
      width: 100%;
    }

    @include for-phone {
      font-size: 16px;
      left: 0;
      line-height: 19px;
      right: 0;

      ._hide-modal {
        font-size: 40px;
        margin: 18px 16px;
      }

      ._modal-content {
        padding: 20px;
      }

      h3 {
        font-size: 24px;
        line-height: 29px;
        margin: 0 30px 0;
      }

      h4 {
        font-size: 18px;
        line-height: 22px;
      }

      ._explanation img {
        max-width: 120px;
      }

      ._faq li {
        padding: 20px;
      }
    }
  }

  &._scan-giftcard {
    ._instruction {
      font-size: 16px;
      margin: 20px auto;
      max-width: 300px;

      @include for-phone {
        font-size: 14px;
        max-width: 230px;
      }
    }

    ._icons {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      font-size: 12px;
      margin-top: 40px;

      span {
        margin: 0 20px;
      }
    }

    ._details {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      color: #05bc7e;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      margin: 30px 0 0;
      text-transform: uppercase;

      img {
        margin-right: 5px;
      }
    }
  }

  &._how-it-works {
    text-align: left;

    ._modal-inner {
      max-width: 692px;
    }

    h3 {
      margin-bottom: 40px;
    }
  }

  &._share-funds {
    ._button {
      margin-top: 10px;

      &._white-button {
        background: #fff;
        margin: 0;

        @include non-phone {
          box-shadow: 0px 3px 8px #00000026;
          color: #000;
        }

        @include for-phone {
          span {
            display: none;
          }
          background: url(/images/grey-add-icon.svg) no-repeat 100% 50%;

          &._edit {
            background-image: url(/images/grey-edit-icon.svg);
          }
        }
      }
    }

    ._inputs {
      background: #efefef;
      border-radius: 0 0 20px 20px;

      @include non-phone {
        margin: 20px -70px -20px;
        padding: 30px 70px;
      }

      @include for-phone {
        margin: 10px -20px -20px;
        padding: 10px;
      }

      > div {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        margin-bottom: 20px;

        @include for-phone {
          background: #fff;
          border-radius: 8px;
          margin-bottom: 10px;
          padding: 20px;
        }

        label {
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
        }

        ._input {
          border-radius: 40px;
          font-size: 16px;
          padding: 12px 20px;

          @include for-phone {
            background: url(/images/grey-add-icon.svg) no-repeat 100% 50%;
            border: 0;
            border-radius: 0;
            padding: 0;

            &._edit {
              background: url(/images/grey-edit-icon.svg) no-repeat 100% 50%;
            }
          }
        }

        > :last-child {
          box-sizing: border-box;

          @include non-phone {
            @include flex(0 0 300px);
          }

          @include for-phone {
            @include flex-grow(1);
            margin-left: 10px;
          }
        }
      }
    }

    @include for-phone {
      h3 {
        font-size: 20px;
        line-height: 24px;
      }

      ._subtitle {
        display: none;
      }
    }
  }

  &._add-funds {
    ._hide-modal {
      display: none;
    }

    @include non-phone {
      ._modal-content {
        padding: 20px 30px;

        ._pay-steps-container > div > * {
          box-sizing: border-box;
          padding: 0 40px;
        }
      }
    }

    @include for-phone {
      h3 {
        font-size: 20px;
        line-height: 24px;
      }
      ._subtitle {
        display: none;
      }
    }
  }

  &._add-message {
    ._hide-modal {
      transform: scale(1.5) translateY(-2px);
    }

    ._video-input {
      border-radius: 10px;
      display: block;
      height: 375px;
      margin: 0 auto;
    }

    ._tabs {
      @include display-flex();
      @include justify-content(center);
      margin-bottom: 20px;

      > * {
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
        padding: 16px;
        text-transform: uppercase;

        &._active {
          opacity: 1;
          position: relative;

          &:after {
            background: #000;
            border-radius: 2px;
            bottom: 0;
            content: "";
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
          }
        }
      }
    }

    small {
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin-top: 10px;
    }

    ._record-button {
      background: #f53333;
      border: 0;
      border-radius: 40px;
      height: 80px;
      margin: 6px auto 20px;
      position: relative;
      width: 80px;

      &:after {
        border: 3px solid #eee;
        border-radius: 50%;
        bottom: -8px;
        content: "";
        display: block;
        left: -8px;
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }

    ._message-container {
      background: #efefef;
      border-radius: 8px;
      position: relative;

      textarea {
        background: none;
        border: 0;
        box-sizing: border-box;
        height: 168px;
        padding: 20px;
        resize: none;
        width: 100%;
      }

      > span {
        bottom: 10px;
        font-size: 12px;
        position: absolute;
        right: 10px;
      }
    }
  }
}

@include for-phone {
  ._site._page._tab-card {
    background-image: none;
  }

  ._card-activity {
    margin-top: 20px;
    position: relative;

    &:before {
      background: #fff;
      bottom: -20px;
      content: "";
      display: block;
      left: -20px;
      position: absolute;
      right: -20px;
      top: -20px;
      z-index: 0;
    }

    > * {
      position: relative;
      z-index: 1;
    }
  }
}

._earn-crumbs-nav {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fad0dc;
  display: flex;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  > img:first-child {
    position: absolute;
    left: 30px;
    bottom: 0;
  }
}

._earn-crumbs-text {
  height: 100%;
  position: relative;
  left: 109px;
  display: flex;
  align-items: flex-end;

  > b {
    align-self: center;
    font-size: calc(16px + (21 - 16) * ((100vw - 300px) / ((1600 - 300))));
    position: relative;
    left: 10%;
  }
}

._right-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

._left-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
}

._earn-tasks-header-wrapper {
  display: flex;

  > h3 {
    margin: 0;
    margin-left: 20px;
  }

  > img {
    cursor: pointer;
  }
}

._crumb-tasks-list {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

._crumb-task-card {
  height: 60px;
  width: 100%;
  margin: 0 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  background: white;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
}

._crumb-task-card-img-wrapper {
  height: 100%;
  width: 84px;
}

._crumb-task-card-img--bottom {
  position: absolute;
  left: 2%;
  bottom: 0;
}

._crumb-task-card-img--centered {
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
}

._crumb-task-card-content-wrapper {
  height: 100%;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._completed {
  pointer-events: none;
}

._task-complete {
  text-decoration: line-through;
  color: gray;
}

._points-badge {
  height: 24px;
  width: 44px;
  border-radius: 100px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  > b {
    font-size: calc(12px + (16 - 12) * ((100vw - 300px) / ((1600 - 300))));
    color: white;
    text-decoration: none;
  }

  > img {
    display: block;
    padding-right: 5px;
  }
}

.points-complete {
  width: 125px;
  text-decoration: none;
}

._crumb-task-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

._crumb-task-main-modal {
  height: 600px;
  width: 414px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background: white;
}

._main-modal-content {
  width: 71%;
  height: 300px;
  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    display: block;
  }

  > b {
    font-size: 30px;
    margin-top: 15px;
    text-align: center;
    display: block;
  }

  > b.small {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    color: #777777;
    text-align: center;
    margin-top: 10px;
  }

  input {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 13px 22px;
    border-radius: 100px;
    border: 1px solid #aaaaaa;
    font-size: 16px;
    font-family: $regularFont;
    color: black;

    &::placeholder {
      color: #aaaaaa;
    }
  }
}

._crumb-task-error {
  color: red;
  font-size: 13px;
}

._main-modal-btn-wrapper {
  width: 71%;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
}

._task-modal-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: none;
  background-color: black;
  color: white;
  font-size: 14px;
  font-family: $extraBoldFont;
  font-weight: 700;
  outline: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
  &:first-child {
    margin-bottom: 15px;
  }

  > img {
    margin-right: 10px;
  }
}

._crumb-task-modal-close {
  position: absolute;
  top: 30px;
  left: 25px;

  &:hover {
    cursor: pointer;
  }
}

._crumb-task-modal-badge {
  height: 25px;
  width: 138px;
  border-radius: 100px;
  background: black;
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    font-size: 13px;
    color: white;
  }
}

._active-stores-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 24px;
  z-index: 15;
}

._active-stores-main-modal {
  height: 600px;
  width: 414px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background: white;
}

._stores-main-modal-header {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  > b {
    padding-top: 20px;
    font-size: 30px;
    text-align: center;
  }
}

._store-modal-list {
  height: 60%;
  width: 100%;
  background-color: #efefef;
  overflow: auto;
}

._store-list-item {
  background: #fff;
  margin: 10px 10px;
  padding: 15px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.active {
  border: 1px solid black;
}

._store-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > img {
    margin-right: 20px;
  }
}

._store-address-wrapper {
  display: flex;
}

._store-city {
  font-size: 21px;
}

._store-state {
  font-size: 21px;
  color: gray;
}

._store-address {
  font-size: 13px;
  margin-top: 5px;
}
