header {
  @if $enable-global-styles {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);
    padding: 10px 0;

    a {
      color: #000;
      font-size: 16px;
    }
  }

  .logo {
    background: url(/images/crumbl-logo.svg) no-repeat 0 50%;
    background-size: contain;
    height: 60px;
    width: 180px;

    @media (max-width: 980px) {
      height: 50px;
      width: 120px;
    }
  }

  .location-icon {
    background: url(/images/location-icon.svg) no-repeat 0 50%;
    background-size: contain;
    line-height: 16px;
    margin-left: 16px;
    padding-left: 16px;

    @media (max-width: 980px) {
      span {
        display: none;
      }
    }
  }
}
