@if $enable-global-styles {
  html {
    scroll-behavior: smooth;
  }

  body {
    background: #fff;
    font-family: "Montserrat", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
      "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    padding: 0;

    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
  }

  a {
    text-decoration: none;
    color: #ffa0b6;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  img {
    max-width: 100%;
  }

  b {
    font-weight: bold;
  }

  i[class^="i-"] {
    display: block;
  }

  .hidden {
    visibility: hidden;
  }
}

.content {
  padding: 1rem;
}

/*---global */
.i-cart {
  height: 20px;
  width: 24px;
}
.i-menu {
  background: #ffa0b6;
  height: 20px;
  width: 24px;
}
.i-cc,
.i-map {
  width: 25px;
}
.i-lock {
  height: 20px;
  width: 20px;
  float: left;
  padding: 10px;
}

.address-btn,
.btn {
  background: #000;
  border: 0;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 1em;
  font-weight: 500;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 35px;

  &:hover,
  &:active {
    background: #333;
  }
  &:active {
    box-shadow: inset 0 0 4px #e5a1b1;
  }

  &.checkout-btn {
    @include display-flex();
    @include justify-content(flex-end);
    padding: 1.25em;
    position: relative;

    > span {
      bottom: 0;
      left: 0;
      line-height: 1.75em;
      padding: inherit;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
    }

    small {
      background: #666;
      border-radius: 20px;
      margin: -0.5em;
      padding: 0.75em 1em;
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &.loading {
    opacity: 0.5;
    position: relative;

    &:after {
      content: "";
      display: block;
      opacity: 0.5;
      position: absolute;
      top: 0.75em;
      right: 1em;
    }
  }

  &.black {
    background: #000;
  }
}

.address-btn.delete {
  margin-top: 15px;
  background: #ccc;
}

.privacy {
  h1 {
    line-height: 1.5em;
  }
  p {
    padding: 0.4em 0;
  }
}

/*---elements */

.hero {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  height: 500px;
  padding: 0 1em;
  text-align: center;
}

/*---page specific: home */

.home .hero {
  background-size: cover;
  h1 {
    color: #fff;
    font-size: 4em;
    margin-bottom: 1em;
  }
  .btn {
    border-radius: 5px;
    font-size: 0.9em;
    letter-spacing: 0.15em;
    padding: 1.5em 2em;
    width: 205px;
    margin-right: 10px;
    display: inline-block;

    &:hover:active {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 600px) {
    .btn {
      margin-bottom: 10px;
      display: block;
    }
  }
}

@media screen and (max-width: 600px) {
  .home .hero h1 {
    font-size: 3em;
  }
}

body.noscroll {
  overflow: hidden;
}

.page-block {
  @include align-items(center);
  @include display-flex();
  @include flex-direction(column);

  @media screen and (min-width: 601px) {
    @include flex-direction(row);

    .page-block-image,
    .page-block-text {
      @include flex(0 0 50%);
      box-sizing: border-box;
      width: 50%;
    }
  }
  @media screen and (max-width: 601px) {
    background: #faf9f6;
    text-align: center;
  }

  .page-block-text {
    background: #faf9f6;
    color: #444;
    padding: 2em 2em 4em;

    h2 {
      font-size: 2.5rem;
      line-height: 1.125em;

      &:before {
        background: #ffb3c4;
        content: "";
        display: block;
        height: 5px;
        margin: 1em 0 0.5em;
        width: 80px;

        @media screen and (max-width: 601px) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    p {
      font-size: 1.25em;
      line-height: 1.5em;
      margin: 1em 0;
    }
    .btn {
      border-radius: 0;
      display: inline-block;
      letter-spacing: 0.1em;
      padding: 1.25em;
    }

    @media screen and (max-width: 601px) {
      text-align: center;
      @include flex-order(1);
    }
  }
  .page-block-image {
    text-align: center;

    @media screen and (max-width: 601px) {
      @include flex-order(0);
      background: #faf9f6;
      padding-top: 2em;
    }

    i {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      background: #fce1e7;
      border-radius: 100%;
      height: 320px;
      margin: 0 auto;
      text-align: center;
      width: 320px;

      img {
        width: 70%;
      }

      @media screen and (max-width: 601px) {
        height: 190px;
        width: 190px;
      }
    }
  }
}

.delivery-locations {
  font-weight: bold;

  a {
    text-decoration: underline;
  }
}

.location-map {
  background: #666;
  background-position: 10% 40%;
  background-size: cover;
  z-index: 1;
  position: relative;
  height: 320px;

  .google-map {
    width: 100%;
    height: 320px;
    position: absolute;
    z-index: -1;
  }
  .google-map-dim {
    width: 100%;
    height: 320px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }

  h2 {
    font-size: 1.7em;
    line-height: 1.15em;
    padding-bottom: 0.5em;
  }

  .location-box-container {
    max-width: 960px;
    margin: auto;
  }

  .location-box {
    background: #fff;
    line-height: 1.5em;
    padding: 2em;
    text-align: center;
    margin: 0 2em;
    position: relative;
    top: 2em;

    @media screen and (min-width: 601px) {
      max-width: 300px;
    }
  }
}

.middle-page {
  margin: auto;
  max-width: 980px;
}

.middle-cnt {
  margin: 0 auto;
  max-width: 940px;
  padding: 0 20px;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.page-width {
  max-width: 1200px;
  margin: auto;
  width: 100%;

  @media (max-width: 1280px) {
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  opacity: 0;
  overflow-y: hide;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 7;

  width: 100%;
  height: 9999px;

  transition: 0.2s;
  transition-property: opacity;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}

.modal-content {
  border-radius: 0.5em;
  background-color: #fefefe;
  margin: 5% 1rem;
  border: 1px solid #888;
  overflow: hidden;
  max-width: 800px;
  position: relative;

  @media screen and (min-width: 601px) {
    margin: 5% auto;
    width: 400px;
  }

  transition: 0.2s;
  transition-property: opacity, transform;
  transform: scale(0.9);
  opacity: 0;

  &.active {
    transform: scale(1);
    opacity: 1;
  }
}

.product-image-popup .modal-content {
  @media screen and (min-width: 601px) {
    text-align: center;
    width: 90%;
  }
}

.modal-close-button {
  color: #ffb3c5;
  font-size: 2em;
  font-weight: bold;
  left: 0;
  padding: 16px 0 0 25px;
  position: absolute;
  top: 0;
}
img.modal-close-button {
  margin-top: 10px;
  width: 25px;
}
.modal-close-button:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.checkout-modal-cnt {
  .modal-close-button {
    color: #222;
  }
}

.hero .btn {
  border-radius: 5px;
}

.btn.loading:after,
.loader {
  border: 4px solid #fff; /* Light grey */
  border-top: 4px solid #ffb3c5;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  margin: 0 5px;
}

.loader.products {
  margin: 20px auto 10px auto;
}

.loading-products {
  text-align: center;
  font-size: 0.9em;
  color: #999;
  margin-bottom: 10px;
}

.buy-btn .loader {
  float: right;
}

.loader.order-modal {
  margin: 30px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid #ffb3c5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkbox.selected .inner {
  background: #ffb3c5;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.pink {
  color: #ffb3c5;
}

.clickable {
  cursor: pointer;
}

.buy-btn {
  display: flex;
  align-items: center;
  padding: 5px;
}

.disable-me {
  background: rgba(115, 115, 115, 0.5);
}

.holder {
  width: 15px;
  height: 15px;
  border: 4px solid rgba(0, 0, 0, 0); /* Light grey */
  margin: 5px;
  background: rgba(0, 0, 0, 0);
}

.buy-btn p {
  flex: 1;
}

.order-errors {
  color: #f21e51;
  font-weight: 800;
  font-size: 0.7em;
  line-height: 1.25em;
  padding: 10px;
  text-align: center;
}
.error-msg {
  font-size: 2em;
  font-weight: bold;
  opacity: 0.25;
  padding: 5em 0;
  text-align: center;
}

.error-page {
  text-align: center;
  img {
    width: 100%;
    max-width: 200px;
  }
  p {
    font-size: 1.8em;
    padding: 25px;
  }
}

.two-item-row {
  display: flex;
  .half:first-child {
    margin-right: 5px;
  }
  .half:last-child {
    margin-left: 5px;
  }
  .half {
    flex: 1;
  }
}

.delivery-location,
.delivery-time {
  color: #777;
  font-size: 0.9em;
  font-weight: 800;
  padding: 1em 0 0 0;
  .much-later {
    color: #000;
  }
}

.delivery-time-location {
  padding: 0.5em 0;
}

.banner {
  background: #ffb6ce;
  box-sizing: border-box;
  color: #fff;
  padding: 0.2em;
  transition: 0.5s all;
  position: relative;
  z-index: 4;

  .page-width {
    display: flex;
    align-items: center;
  }

  .closeBanner {
    color: inherit;
    font-weight: bold;
    opacity: 0.8;
    padding: 0.3em;
    font-size: 1.8em;
  }

  &.hide-banner {
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.catering-info {
  padding: 0 0.6em;

  .email,
  .phone,
  .visit {
    padding-left: 0.2em;
    padding-bottom: 0.6em;
  }

  a {
    color: #787878;
  }
}

.logo-cnt {
  margin: auto;
}
.mobile-row {
  .logo-cnt {
    padding-right: 40px;
  }
}

.catering-dropdown {
  margin: 0.6em;
  margin-bottom: 0;
  padding: 0.3em;
  border: 1px solid black;
  border-radius: 5px;
  background: white;
  font-size: 18px;
  //moz-appearance: none;
  //-webkit-appearance: none;
  //appearance: none;
}

.videoBanner {
  flex: auto;
  justify-content: center;
  background: #ffb3c5;

  ._vid {
    border-radius: 0;
    z-index: 1;
  }

  video {
    display: block;
  }
}

#video {
  text-align: center;
}

.inner-page-hero {
  background: pink;
  // height: 400px;
}

.store-item-buttons {
  @include align-items(center);
  @include display-flex();
  @include justify-content(flex-start);
  padding-left: 2em;
}

.ship-modal {
  ul {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    li:not(:last-child) {
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

#custom_map path {
  stroke-width: 1; /* control the countries borders width */
  stroke: #666; /* choose a color for the border */
}

.white.btn {
  border-radius: 25px;
  line-height: 50px;
  // margin: 0 1em;
  padding: 0 0.25em;
  position: relative;
  text-transform: none;
  background: #fff;
  color: #000;
  font-size: 1em;
}

@import "store";

.page-header {
  background: #000;
  color: #fff;
  font-family: inherit;
  font-size: 3em;
  margin: 0;
  padding: 2em 0 1.5em;
  text-align: center;
  // text-transform: uppercase;
}

@media screen and (min-width: 781px) {
  ._fixedHeader {
    padding-top: 82px;
    header {
      max-height: 82px;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }
  }
}

._desktop-view,
._mobile-view {
  display: none;
}
@media screen and (max-width: 500px) {
  ._mobile-view {
    display: block;
  }
}
@media screen and (min-width: 501px) {
  ._desktop-view {
    display: block;
  }
}

.form-control {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 6px 12px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9acffa;
    outline: none;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
  }
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: #868e96;
    opacity: 1;
  }
  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  &.is-invalid {
    border-color: #f44336;

    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
    }
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: calc(2.0625rem + 2px);
  }
  &:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url(/images/angle-down.svg) no-repeat 98% 50%;
  background-size: 0.75em;
  padding-right: 2.5em;
  width: auto;
}

._pay-steps-container {
  overflow: hidden;
  width: 100%;

  > div {
    @include align-items(flex-start);
    @include display-flex();

    transition: transform 0.35s;
    width: 300%;

    > * {
      height: 10px;
      position: relative;
      width: 33.33%;
    }

    &._step-1 {
      > :nth-child(1) {
        height: auto;
      }
    }

    &._step-2 {
      transform: translate(-33.33%, 0);

      > :nth-child(2) {
        height: auto;
      }
    }
    &._step-3 {
      transform: translate(-66.66%, 0);
      > :nth-child(3) {
        height: auto;
      }
    }

    button._nav {
      background: none;
      border: 0;
      cursor: pointer;
      left: 0;
      padding: 0;
      position: absolute;
      top: 12px;

      @include non-phone {
        img {
          height: 22px;
        }
      }

      @include for-phone {
        top: 6px;
        img {
          height: 12px;
        }
      }
    }

    ._title {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      background: url(/images/dotted.svg) repeat-x 0 100%;
      margin-bottom: 20px;

      img {
        cursor: pointer;
      }

      &:after {
        content: "";
        display: block;
      }

      @include for-phone {
        padding-bottom: 16px;
      }
    }

    ._button {
      margin-top: 20px !important;
    }
  }
}

._site ._static-page-hero {
  ._page-width {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);
    padding: 0;

    > div {
      @include flex(0 0 410px);
    }

    @media screen and (max-width: 1259px) {
      @include flex-direction(column);
      padding: 62px 0 0;

      > div {
        @include flex-basis(auto);
        max-width: 410px;
        padding: 0 20px 14px;
        text-align: center;

        @include for-phone {
          box-sizing: border-box;
          text-align: left;

          h1,
          h2 {
            font-size: 45px;
            line-height: 45px;
            margin-bottom: 26px;
          }

          p {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 33px;
          }
        }
      }
    }
  }

  h1,
  h2 {
    font: 90px $semiBoldFont;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

.gray-bg {
  background-color: #efefef;
}

.select-field {
  background-image: url(/images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) 50%;
  moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@layer components {
  .screen-container {
    @apply max-w-screen-xl mx-auto px-5;
  }
  select,
  a,
  input[type="text"],
  textarea,
  .focusable,
  button {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400;
  }
  [aria-hidden="true"] {
    @apply focus:ring-0;
  }
  .btn:not(.white),
  ._button {
    @apply focus-visible:outline-none focus-visible:ring-button focus-visible:ring-blue-400;
  }
  .button {
    @apply p-2.5 text-sm block text-center bg-black text-white rounded-full uppercase font-bold w-full focus-visible:ring-button;

    &:disabled {
      @apply opacity-20;
    }
  }
  .cancel-button {
    @apply focus-visible:outline-none appearance-none w-full uppercase p-5 text-sm font-bold text-gray-400;
  }

  .toggle-checkbox:checked {
    @apply right-0 border-black;
    right: 0;
  }
  .toggle-checkbox:checked + .toggle-label {
    @apply bg-black;
  }
}

input._custom-slider {
  -webkit-appearance: none;
  height: 10px;
  border-radius: 100px;
  accent-color: black;
  border: 1px solid #aaa;
  background: linear-gradient(to right, black 0% black 50%, #e6e6e6 50% #e6e6e6 100%);

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 45px;
    height: 45px;
    background: white;
    cursor: pointer;
    border-radius: 999px;
    border: 1px solid black;
    box-shadow: 0 3px 8px #00000033;
  }

  &::-moz-range-progress {
    background-color: black;
  }

  &::-moz-range-track {
    background-color: white;
  }

  &::-moz-range-thumb {
    width: 45px;
    height: 45px;
    background: white;
    cursor: pointer;
    border-radius: 999px;
    border: 1px solid black;
    box-shadow: 0 3px 8px #00000033;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-stroke {
  -webkit-text-stroke: 2.5px #000;
  -webkit-text-fill-color: white;
}

.pink-shadow {
  text-shadow: rgb(249, 189, 208) -3px 3px;
}

//careers
.lever-team-title {
  @apply font-bold text-[24px] leading-[30px] 2xl:text-[28px] 2xl:leading-[32px] pt-4;
}
.lever-job {
  @apply border border-solid border-black/20 rounded-xl p-5 my-4;
}
.lever-job-title {
  @apply block font-bold text-[18px] leading-[20px] 2xl:text-[20px] 2xl:leading-[22px];
}
.lever-job-tag {
  @apply font-normal text-[13px] leading-[15px] 2xl:text-[14px] 2xl:leading-[16px] text-black/60;
}
