._middle-centered {
  @include align-items(center);
  @include display-flex();
  @include justify-content(center);
}

._giftcard-page {
  ._giftcards-container {
    background: #efefef;
    padding: 40px 0;

    &._loading {
      @extend ._middle-centered;
      min-height: 50vh;
    }
  }
  ._giftcards-horizontal {
    overflow: hidden;

    ._page-width {
      padding-bottom: 0;
      padding-top: 0;
    }

    h3 {
      font: 20px $semiBoldFont;
      line-height: 24px;
      text-transform: uppercase;
    }

    ._horizontal-items {
      margin-bottom: 20px;
    }

    ._giftcard-preview {
      @include flex(0 0 270px);

      background: #fff 50% 50%;
      background-size: cover;
      border-radius: 10px;
      box-shadow: 0px 3px 8px #00000026;
      cursor: pointer;
      height: 168px;
      margin-right: 20px;
      width: 270px;
    }

    &._featured ._giftcard-preview {
      height: 230px;
      width: 370px;
    }
    @include for-phone {
      &._featured ._giftcard-preview {
        height: 168px;
        width: 270px;
      }
    }
  }
}

._site ._modal._send-giftcard {
  ._modal-inner {
    max-width: 580px;
    width: 90%;
  }
  ._modal-content {
    padding: 0;
  }

  ._giftcard-info {
    padding: 30px 0;
    text-align: center;

    h3 {
      font-size: 30px;
      font-weight: bold;
      line-height: 37px;
      margin-bottom: 10px;

      @include for-phone {
        font-size: 22px;
        line-height: 27px;
      }
    }
    p {
      font: 18px $mediumFont;
      line-height: 22px;

      @include for-phone {
        font-size: 14px;
        line-height: 18px;
      }
    }

    ._giftcard-image-container {
      box-shadow: 0px 3px 8px #00000026;
      border-radius: 10px;
      margin: 30px auto;
      overflow: hidden;
      width: 300px;
      transform: matrix(1, -0.09, 0.09, 1, 0, 0);

      img {
        display: block;
      }
    }

    ._giftcard-amount {
      @extend ._middle-centered;

      img {
        border-radius: 50%;
        box-shadow: 0px 3px 8px #00000026;
        cursor: pointer;
        transition: filter 0.15s;

        &:active {
          filter: invert(1);
        }
      }

      span {
        color: #aaaaaa;
        display: block;
        font: 12px $mediumFont;
        margin-bottom: 3px;
        text-transform: uppercase;
      }

      > div {
        margin: 0 20px;
      }

      input {
        background: none;
        border: 0;
        font: inherit;
        font-size: 30px;
        font-weight: bold;
        max-width: 100px;
        text-align: center;
      }
    }
  }

  ._giftcard-details {
    background: #efefef;
    border-radius: 0 0 20px 20px;

    > div {
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 418px;
      padding: 20px;
    }

    label {
      display: block;
      margin-bottom: 20px;

      b {
        font-size: 20px;
        font-weight: bold;
        display: block;
        line-height: 24px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
    }

    ._input {
      background: #fff;
      border: 1px solid #aaa;
      border-radius: 30px;
      box-sizing: border-box;
      padding: 12px 24px;
      width: 100%;
      font: 16px $mediumFont;

      &._placeholder {
        color: #ccc;
      }
    }

    div._input {
      cursor: pointer;

      ._sign-in {
        text-transform: uppercase;
      }
    }

    ._giftcard-recipients {
      > div {
        @include display-flex();
        padding: 12px 0;

        &._recipient {
          background: #000;
          border-radius: 8px;
          color: #fff;
          font: 15px $mediumFont;
          margin-bottom: 5px;
          padding: 12px;

          b {
            margin-top: 0;
          }

          > div {
            @include align-items(center);
            @include display-flex();
            @include justify-content(space-between);

            padding-left: 24px;
            width: 100%;

            img {
              cursor: pointer;
            }
          }
        }

        > b {
          font-size: 16px;
          line-height: 19px;
          margin: 12px 18px 0 0;
        }

        ._input {
          &._highlight {
            border-color: #000;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      button {
        @include align-items(center);
        @include display-flex();
        border: 0;
        cursor: pointer;
        font: 14px $semiBoldFont;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        img {
          border: 1px solid #000;
          border-radius: 50%;
          height: 8px;
          margin-right: 10px;
          padding: 3px;
        }
      }
    }

    ._giftcard-message {
      padding: 20px;

      @include non-phone {
        border-top: 1px dashed #aaa;
        margin: 0 -20px;
      }

      @include for-phone {
        background: #fff;
        border-radius: 8px;
        margin-bottom: 20px;
      }

      ._button {
        @extend ._middle-centered;
        background: #fff;
        color: #000;
        width: 100%;

        img {
          height: 12px;
          margin-right: 10px;
        }
      }
    }

    ._mobile-only ._message-preview > div {
      margin-bottom: 0;
    }
  }

  ._message-preview {
    font-size: 16px;
    line-height: 20px;

    > div {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      margin-bottom: 10px;
      text-transform: uppercase;

      img {
        cursor: pointer;
      }
    }
  }

  ._button {
    width: 100%;
  }
}

._site ._modal._send-giftcard-from,
._site ._modal-shadow._send-giftcard-from-shadow,
._site ._modal._send-giftcard-message,
._site ._modal-shadow._send-giftcard-message-shadow,
._site ._modal._send-giftcard-when,
._site ._modal-shadow._send-giftcard-when-shadow {
  z-index: 5;
}
._site ._modal._send-giftcard-when {
  ._modal-inner {
    max-width: 450px;
    width: 90%;

    @include for-phone {
      width: 100%;
    }
  }
  ._modal-content {
    background: #efefef;
    padding: 0;
  }

  ._hide-modal {
    display: none;
  }

  ._selection {
    @include display-flex();
    transition: transform 0.25s;
    width: 200%;

    &-parent {
      overflow: hidden;
      width: 100%;
    }

    > * {
      @include flex(0 0 50%);
      width: 50%;
    }

    &._active-1 {
      transform: translateX(-50%);
    }

    &-title {
      background: #fff;
      border-radius: 20px 20px 0 0;
      padding: 30px 20px 20px;
      position: relative;

      h3 {
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        text-align: center;

        @include for-phone {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }

    &-body {
      border-radius: 0 0 20px 20px;
      padding: 20px 40px;

      @include for-phone {
        padding: 20px;
      }
    }
  }

  ._calendar-months {
    @include display-flex();
    @include justify-content(space-between);

    > img {
      cursor: pointer;
      height: 14px;
      margin: 12px;
    }

    > div {
      font-size: 20px;
      font-weight: bold;
      @include flex-grow(1);
      @extend ._middle-centered;

      img {
        margin-right: 10px;
      }
    }
  }

  ._calendar-dates {
    margin: 20px 0;

    li > * {
      @include align-items(center);
      @include display-flex();
      background: #fff;
      border-radius: 10px;
      cursor: pointer;
      font-weight: bold;
      margin-bottom: 10px;
      overflow: hidden;
      padding: 20px;
      transition: filter 0.25s;

      span {
        height: 26px;
        transform: translateX(-30px);
        transition: transform 0.25s;
        width: 0;
      }

      &._selected {
        background: #000;
        color: #fff;

        span {
          @extend ._middle-centered;

          background: #fff;
          border-radius: 50%;
          margin-right: 10px;
          transform: translateX(-0);
          width: 26px;
        }
      }
    }
  }

  ._button {
    margin-top: 10px;
    width: 100%;
  }

  ._time-selection {
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    margin-top: 30px;

    &:last-child {
      margin-bottom: 80px;
    }

    &,
    &-title {
      @extend ._middle-centered;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;

      img {
        margin-right: 10px;
      }
    }

    button {
      @extend ._middle-centered;
      background: #fff;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      height: 50px;
      width: 50px;
    }

    > b {
      margin: 0 40px;
    }
  }
}

._site ._modal._modal._send-giftcard-from {
  ._modal-inner {
    max-width: 580px;
    width: 90%;

    @include for-phone {
      width: 100%;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 20px;
    text-align: center;

    @include for-phone {
      font-size: 22px;
      line-height: 27px;
    }
  }

  ._button {
    width: 100%;
  }

  ._hide-modal {
    @include non-phone {
      top: 24px;
    }
  }

  ._tabs {
    border-bottom: 0;
    margin-bottom: 20px;
    transform: scale(0.9);

    li {
      @include flex-grow(0);
      padding-left: 10px;
      padding-right: 10px;
      text-transform: unset;
    }
  }

  ._input {
    border-radius: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
  }

  ._image-container {
    @extend ._middle-centered;
    background: no-repeat 50% 50%;
    background-size: cover;
    border: 1px solid #aaa;
    border-radius: 50%;
    height: 125px;
    margin: 0 auto 20px;
    position: relative;
    width: 125px;

    &._hasImage {
      border: 0;

      ._icon {
        border-color: #fff;
        filter: invert(1);
      }
    }

    &._isLoading .loader {
      border-color: rgba(255, 255, 255, 0.85);
      border-top-color: rgba(0, 0, 0, 0.85);
      border-width: 10px;
      height: 100px;
      margin: 0;
      width: 100px;
    }

    ._icon {
      background: #fff url(/images/add-button.svg) no-repeat 50% 50%;
      background-size: 33px;
      border: 1px solid #aaa;
      border-radius: 50%;
      height: 34px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      width: 34px;
    }

    input {
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      color: #aaaaaa;
      font-family: $regularFont;
      line-height: 17px;
      text-align: center;

      small {
        display: block;
        font-size: 12px;
      }
    }
  }
}

._site ._modal._send-giftcard-message {
  ._modal-inner {
    max-width: 580px;

    @include non-phone {
      width: 90%;
    }
  }
  ._modal-content {
    padding: 20px;

    @include non-phone {
      padding: 30px 80px;
    }
  }

  ._hide-modal {
    padding: 8px;
    transform: none;

    img {
      width: 11px;
    }
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
    margin: -10px 0 10px;
    text-align: center;

    @include for-phone {
      font-size: 20px;
      line-height: 24px;
      margin-top: 5px;
    }
  }

  ._button {
    margin-top: 20px;
    width: 100%;

    @include for-phone {
      margin-top: 10px;
    }
  }

  ._tabs {
    border: 0;
    margin-bottom: 20px;
    transform: scale(0.75);
  }

  ._text-container {
    position: relative;

    textarea {
      background: #efefef;
      border: 0;
      border-radius: 8px;
      box-sizing: border-box;
      height: 160px;
      padding: 20px;
      resize: none;
      width: 100%;
    }

    span {
      bottom: 10px;
      font-size: 12px;
      position: absolute;
      right: 10px;
    }
  }
}

.video-recorder {
  div[class^="video-recorder__Wrapper"] {
    display: block;
    background: none;
    margin-bottom: 0;
    overflow: hidden;
  }
  div[class^="video-recorder__CameraView"] {
    height: 350px;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  div[class^="record-button__Instructions"] {
    display: none;
  }
  div[class^="render-actions__ActionsWrapper"] {
    min-height: 100px;
    padding-bottom: 0;
    position: relative;

    button[data-qa^="start-replaying"] {
      background: #eee;
      border-radius: 30px;
      color: #aaa;
      font: inherit;
      font-weight: bold;
      margin-bottom: -20px;
      text-transform: uppercase;
      width: 100%;
    }
    button[data-qa^="stop-recording"],
    button[data-qa^="start-recording"] {
      background: #f53233;
    }
  }
  div[class^="timer__Text"] {
    background: #000;
    font-family: inherit;
    font-size: 15px;
    padding: 8px;
    text-shadow: none;
    top: 0;
    right: 0;
  }
  div[class^="timer__RecIcon"] {
    display: none;
  }
  div[class^="record-button__ButtonBorder"] {
    border-color: #f1f1f1;
  }
  div[class^="stop-button__Border"] {
    background-color: #f1f1f1;
  }
}

._site ._modal._checkout-modal {
  ._modal-inner {
    max-width: 550px;

    @include non-phone {
      width: 90%;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 5px;
    text-align: center;

    @include for-phone {
      font-size: 22px;
      line-height: 27px;
    }
  }

  ._button {
    width: 100%;
  }
}

._site ._modal._checkout-modal {
  &._checkout-modal-0 {
    ._modal-content {
      background: #efefef;

      @include non-phone {
        padding: 30px 60px;
      }
    }
    ._hide-modal {
      background: inherit;
      font-size: 50px;
      margin-top: 6px;

      @include non-phone {
        margin: 20px 0 0 10px;
      }
    }
  }
  &:not(._checkout-modal-0) {
    h2 {
      border-bottom: 1px dashed #aaa;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    ._hide-modal {
      top: 22px;
      transform: none;

      img {
        height: 16px;
      }
    }
  }

  ._checkout-steps-container {
    overflow: hidden;
    width: 100%;

    > div {
      @include display-flex();
      transition: transform 0.25s;
    }
  }

  ._checkout-review {
    ._title {
      margin-bottom: 20px;
      text-align: center;
    }

    ._actions {
      @include justify-content(space-between);

      &,
      button {
        @include align-items(center);
        @include display-flex();
      }

      button {
        background: none;
        border: 0;
        cursor: pointer;
        font: 12px $regularFont;
        font-weight: bold;
        opacity: 0.5;
        text-transform: uppercase;

        img {
          margin-right: 5px;
          max-height: 11px;
        }
      }
    }

    ._subtotal {
      @include display-flex();
      @include justify-content(space-between);
      padding: 20px;
    }
  }

  ._place-order {
    ._row {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
    }

    ._subtotals {
      background: #efefef;
      border-radius: 8px;
      margin: 20px 0;
      padding: 12px;

      ._row:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    ._button {
      margin-top: 20px;
    }

    ._payment-methods {
      margin-top: 80px;

      > div {
        @include align-items(center);
        @include display-flex();
      }

      ._title {
        margin-bottom: 10px;
        b {
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
        }

        span {
          @include flex-grow(1);
          border-top: 1px dashed #aaa;
        }
      }

      ._crumbl-cash,
      ._credit-card {
        @include justify-content(space-between);
        padding: 12px 20px;

        > div {
          @include align-items(center);
          @include display-flex();
        }
      }

      ._crumbl-cash {
        font-weight: bold;

        b {
          font-size: 12px;
          text-transform: uppercase;
        }

        img {
          margin-right: 16px;
        }

        .form-switch {
          margin-left: 16px;
        }
      }
    }

    ._credit-card-input {
      margin: 10px 20px;
    }
  }

  ._error-message {
    color: #f44336;
    display: block;
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
    text-align: center;
  }

  ._add-card {
    text-align: center;
  }

  ._credit-card-input {
    border: 1px dashed #aaa;
    border-width: 1px 0;
    margin: 10px 0 20px;
    padding: 10px 0;
  }
}

._site ._order-confirmation-modal ._order-confirmation-inner ._giftcard-receipt {
  @include for-phone {
    padding-top: 20px;
  }
  ._section-title {
    text-align: left;
    h2 {
      @include justify-content(flex-start);
    }
  }

  ._section._centered {
    b {
      display: block;
      margin-bottom: 8px;
    }
  }

  ._giftcard-order-summary {
    color: #aaa;
    font-size: 18px;
    b {
      color: #000;
      font-size: 24px;
    }
  }

  ._giftcard-item:not(:first-child) {
    border-radius: 0;
    border-top: 1px dashed #aaa;
  }
}

@include for-phone {
  ._site ._modal._send-giftcard ._modal-inner {
    width: 100%;

    ._giftcard-info {
      padding-top: 15px;

      h3 {
        font-size: 24px;
        line-height: 29px;
      }

      p {
        display: none;
      }

      ._giftcard-image-container {
        width: 276px;
      }
    }

    ._giftcard-details {
      > div {
        padding: 10px;

        label {
          @include display-flex();
          background: #fff;
          border-radius: 10px;
          margin-bottom: 5px;
          padding: 20px;

          b {
            font-size: 16px;
            line-height: 19px;
            margin: 2px 10px 0 0;
          }

          ._input {
            border: 0;
            padding: 0;
          }

          &._mobile-giftcard-recipients {
            > div {
              @include display-flex();
              min-height: 20px;

              &,
              > div {
                @include flex-grow(1);
              }

              > div {
                @include display-flex();
                @include flex-wrap(wrap);
              }

              span {
                background: #efefef;
                border-radius: 4px;
                margin: 0 5px 5px 0;
                padding: 5px;

                &._showAll {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
._received-gift {
  padding: 60px 0;
  text-align: center;
  background-color: black;

  ._status-text {
    color: #aaaaaa;
    margin: 0.6rem;
  }

  ._page-width {
    margin: 0 auto;
    max-width: 500px;
    background-color: white;
    border-radius: 1rem;
    padding: 2rem;
  }

  ._header {
    margin-bottom: 40px;
    padding: 10px;
  }

  b {
    display: block;
    font-size: 20px;
    line-height: 24px;
  }

  ._gift_with_logo {
    position: relative;

    ._company-logo-gift {
      width: 68px;
      height: 68px;
      border-radius: 100%;
      position: absolute;
      top: 28px;
      left: 108px;
    }
  }

  ._dotted-separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid efefef;
  }

  ._giftcard-preview {
    display: block;
    margin: 20px auto 40px;
  }

  ._company-logo {
    background: #efefef;
    margin: 10px auto;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    padding: 10px 10px;
    display: block;
  }

  ._app-info {
    background: #efefef;
    border-radius: 8px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
    padding: 10px 20px;
  }

  ._buttons {
    margin-top: 20px;
  }

  ._button {
    @extend ._middle-centered;
    box-sizing: border-box;
    margin-top: 10px;
    width: 100%;

    img {
      filter: invert(1);
      margin-right: 10px;
    }

    &:hover {
      color: #fff;
    }
  }
}

._giftcard-item {
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > *:not(:last-child) {
    border-bottom: 1px dashed #aaa;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  ._preview {
    text-align: center;
    @include align-items(flex-start);
    @include display-flex();

    b {
      display: block;
      margin-bottom: 10px;
    }

    img {
      background: #000;
      box-shadow: 0px 3px 8px #00000026;
      border-radius: 4px;
      margin-right: 20px;
      transform: matrix(1, -0.09, 0.09, 1, 0, 0);
      width: 110px;
    }
  }

  ._info {
    > label {
      @include align-items(center);
      @include display-flex();
      margin-bottom: 20px;
    }

    ._recipients {
      b {
        @include align-self(flex-start);
        @include flex-shrink(0);
        margin-top: 5px;
      }
      div {
        @include display-flex();
        @include flex-wrap(wrap);
      }
      span {
        background: #efefef;
        border-radius: 4px;
        margin: 0 5px 5px 0;
        padding: 5px;

        &._showAll {
          cursor: pointer;
        }
      }
    }

    b {
      margin-right: 5px;
      text-transform: uppercase;
    }
  }

  ._video-message {
    @include display-flex();

    b {
      display: block;
      margin-bottom: 5px;
    }

    ._video-container {
      background: #efefef;
      border-radius: 4px;
      height: 60px;
      margin-right: 10px;
      width: 60px;
    }

    ._icon {
      @include align-items(center);
      @include display-flex();

      img {
        height: 22px;
        margin-right: 5px;
        width: 22px;
      }
    }
  }

  ._text-message {
    b {
      display: block;
      margin-bottom: 10px;
    }
    p {
      line-height: 20px;
    }
  }
}

._site ._modal._send-giftcard-add-recipients {
  ._modal-content {
    padding: 0;
  }

  h2 {
    border-bottom: 1px dashed #aaa;
    padding: 20px;
    text-align: center;
  }

  ._list {
    @include display-flex();
    padding: 20px;

    b {
      padding-top: 8px;
    }

    > div {
      @include flex-grow(1);
      @include display-flex();
      @include flex-wrap(wrap);
    }

    span {
      background: #000;
      border-radius: 30px;
      color: #fff;
      margin: 0 0 5px 5px;
      padding: 8px 12px;
    }
  }

  ._inputs {
    background: #efefef;
    padding: 20px;

    > * {
      border-radius: 30px;
      box-sizing: border-box;
      margin-bottom: 10px;
      width: 100%;
    }
    ._input {
      padding: 12px 24px;
    }

    ._button:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

._add-animation._giftcard {
  ._product {
    padding: 3px;
  }

  img {
    border-radius: inherit;
  }
}
