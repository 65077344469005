.tracking {
  border: 1px solid #eee;
  border-radius: 0.5em;
  margin: 1em;
  overflow: hidden;

  .map {
    background: #fafafa !important;
    height: 250px;
    width: 100%;
  }

  .store {
    border-top: 1px solid #eee;
    box-shadow: 0 0 8px #eee;
    padding: 1em;

    @include justify-content(space-between);
    @include align-items(center);
    @include display-flex();

    > div:first-child {
      @include align-items(center);
      @include display-flex();
    }

    .image {
      border: 1px solid #eee;
      border-radius: 40px;
      box-shadow: 0 0 6px #dadada;
      margin-right: 1em;

      > div {
        border: 1px solid #eee;
        border-radius: 40px;
        box-shadow: inset 0 0 6px #dadada;
        margin: 4px;
        padding: 4px;

        > div {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
          height: 60px;
          width: 60px;
        }
      }
    }

    .details {
      color: #999;
      line-height: 1.25em;

      b,
      small {
        display: block;
      }

      b {
        color: #222;
        font-size: 1.15em;
        font-weight: bold;
      }

      small {
        color: #ffb6ce;
      }
    }
  }

  .task-detail {
    @include align-items(center);
    @include display-flex();
    line-height: 1.15em;
    padding: 0 1em 1em;

    img {
      margin-right: 1em;
      width: 20px;
    }

    small {
      color: #999;
      display: block;
      font-size: 0.8em;
    }
  }
}

.tracking-page {
  @include align-items(center);
  @include display-flex();
  @include justify-content(center);

  min-height: 100vh;
  text-align: center;

  .tracking-thanks {
    color: #333;
    padding: 0 1em;

    h2 {
      color: inherit;
      font-family: inherit;
      font-size: 1.35em;
      font-weight: bold;
      line-height: 1.25em;
      margin-bottom: 0.25em;
    }
  }

  .loader {
    border-color: #f9f9f9;
    border-top-color: #ffb3c5;
    border-width: 40px;
    display: block;
    height: 120px;
    margin: 2em auto;
    width: 120px;
  }
}
