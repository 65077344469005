._site {
  touch-action: manipulation;

  ._modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }
}

._order-page {
  @include non-phone {
    ._site ._header-container ._page-width {
      min-height: 67px;
    }
  }
}

._site ._order-page {
  ._sticky-container {
    --offset-top: var(--offset-top);

    @include non-phone {
      ._sticky {
        left: 0;
        position: fixed;
        right: 0;
        top: var(--offset-top);
        z-index: 1;
      }

      ._sticky-shadow {
        height: var(--sticky-height);
        width: 1px;
      }
    }

    @include for-phone {
      ._order-title {
        left: 0;
        position: fixed;
        right: 0;
        top: var(--offset-top);
        z-index: 2;
      }

      ._sticky-shadow {
        height: var(--offset-top);
        width: 1px;
      }
    }

    ._sticky-background-helper {
      background: #fff;
      height: var(--offset-top);
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }
  }

  ._order-title ._page-width {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);
    padding: 0 20px;

    ._show-faq-icon {
      border: 1px solid #aaa;
      border-radius: 50%;
      color: #aaa;
      cursor: pointer;
      font-size: 11px;
      font-weight: bold;
      height: 12px;
      line-height: 12px;
      text-align: center;
      width: 12px;
    }

    ._faq-desktop,
    ._faq-mobile {
      display: none;
    }

    @include for-phone {
      a {
        @include flex-grow(1);
      }
      ._faq-mobile {
        display: block;
      }
    }

    @include non-phone {
      ._faq-desktop {
        display: block;

        ._show-faq-icon {
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          width: 20px;
        }
      }
    }

    img {
      margin-right: 10px;

      &:last-child {
        margin-left: 10px;
      }
    }

    a {
      cursor: pointer;
      font-size: 28px;
      font-weight: bold;
      margin-right: 10px;

      @include align-items(center);
      @include display-flex();

      @include for-phone {
        font-size: 24px;
      }

      @media screen and (max-width: 350px) {
        font-size: 0.9em;
      }
    }
  }

  ._product-selection,
  ._search-results {
    a {
      background: #fff no-repeat;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 15px 20px;
    }
  }

  ._order-location {
    height: 100%;

    &-modal ._modal-content {
      padding: 0;
    }

    h2 {
      font-size: 30px;
      font-weight: bold;
      line-height: 37px;
      margin: 0 auto;
      max-width: 260px;
      padding: 30px 0;
      text-align: center;
    }

    ._search-box-container {
      background: #000;
      padding: 10px 20px;

      input {
        background: #fff url(/images/search-icon.svg) no-repeat 20px 50%;
        border: none;
        border-radius: 30px;
        box-sizing: border-box;
        font: 16px $mediumFont;
        padding: 12px 20px 12px 46px;
        width: 100%;
      }
    }

    ._scrollable {
      background: #efefef;
      border-radius: 0 0 20px 20px;
      padding: 20px;

      @include for-phone {
        border-radius: 0;
      }
    }

    ._search-results li {
      @include align-items(flex-start);
      @include display-flex();

      ._icon {
        margin: 5px 20px 0 0;
        text-align: center;
        width: 25px;

        small {
          display: block;
          font-size: 10px;
          font-weight: bold;
          line-height: 13px;
          margin-top: 5px;
        }
      }

      ._info {
        @include flex-grow(1);
        font-size: 18px;
        line-height: 22px;

        b {
          display: block;
          font-weight: bold;
        }

        ._actions {
          margin-top: 10px;
          @include display-flex();
          @include justify-content(space-between);

          small {
            color: #595959;
            font-size: 12px;
            font-weight: bold;
            line-height: 15px;
          }
        }
      }
    }

    ._save-address {
      background: #efefef;
      margin-bottom: 24px;

      textarea,
      input:not([type="checkbox"]) {
        border: 1px solid #aaa;
        border-radius: 30px;
        box-sizing: border-box;
        font: 16px $mediumFont;
        margin-bottom: 10px;
        padding: 12px 20px;
        width: 100%;

        &::placeholder {
          color: #999;
          font-weight: bold;
        }

        &._missing-required {
          background-color: #ffe5e5;
          border-color: #ff6868;
        }
      }

      textarea {
        border-radius: 16px;
        height: 100px;
        resize: none;
      }

      label:not(._checkbox-cnt) {
        @include align-items(center);
        @include display-flex();
        font: 16px $mediumFont;
        font-weight: bold;
        padding: 10px 0;

        small {
          font-size: 12px;
          font-weight: normal;
          opacity: 0.35;
          margin-left: 5px;
        }
      }

      ._button {
        font-size: 14px;
        width: 100%;
      }

      ._checkbox-cnt {
        @include justify-content(center);
        margin-bottom: 10px;
      }
    }

    &._ship-instead-message {
      p {
        line-height: 21px;
        padding: 0 20px;
        text-align: center;
      }

      ._buttons {
        padding: 20px;

        ._button {
          width: 100%;
        }

        @include non-phone {
          @include display-flex();
          @include justify-content(center);
          ._button {
            width: auto;

            &:not(._bare-button) {
              width: 50%;
            }
          }
        }
      }
    }
  }

  ._product-selection {
    @media screen and (min-width: 900px) {
      @include display-flex();
      @include flex-wrap(wrap);

      margin-right: -3.33%;

      > * {
        @include flex(0 0 30%);
        margin-right: 3.33%;
      }
    }

    a {
      @include display-flex();
      padding: 0;

      ._image {
        @include align-items(center);
        @include display-flex();
        @include flex(0 0 110px);
        border-radius: 8px 0 0 8px;
        margin-right: 20px;
        overflow: hidden;
        position: relative;

        ._sold-out {
          background: rgba(255, 255, 255, 0.85);
          bottom: 0;
          font-size: 18px;
          font-weight: bold;
          left: 0;
          padding: 10px;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;

          @include align-items(center);
          @include display-flex();
          @include justify-content(center);
        }
      }

      ._details {
        @include display-flex();
        @include flex-grow(1);
        @include justify-content(space-between);
        padding: 15px;
        position: relative;

        ._discounted {
          font-size: 18px;
          line-height: 22px;

          @include for-phone {
            text-align: right;
          }

          b {
            text-decoration: line-through;
          }
        }

        &._highlighted ._check {
          position: absolute;
          right: 10px;

          @include non-phone {
            top: 10px;
          }

          @include for-phone {
            bottom: 10px;
          }
        }

        @include for-phone {
          @include flex-grow(1);
        }

        @include non-phone {
          @include flex-direction(column);
        }

        b {
          font-size: 20px;
          font-weight: bold;
          display: block;
          line-height: 24px;
        }

        sup {
          font-size: 12px;
          vertical-align: super;
        }

        small {
          color: #595959;
          display: block;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }

  ._order-info {
    border-radius: 23px;
    overflow: hidden;
    @include display-flex();

    > * {
      height: 45px;
      line-height: 45px;
    }

    > b {
      background: #efefef;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: bold;
      padding-right: 10px;
      text-align: right;
      @include flex(0 0 85px);
    }
    > span {
      background: #fff;
      font-size: 16px;
      padding-left: 15px;
      padding-right: 15px;
      @include flex-grow(1);
      @include _ellipsis;
    }

    @include for-phone {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &._selection-order-page {
    ._order-title {
      background: #fff;
    }
    ._order-selection {
      background: #f6f6f6;
      padding: 25px;
      min-height: 35vh;

      @include for-phone {
        padding: 10px 0;
        min-height: 70vh;
      }
    }

    h4 {
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 20px;
    }

    ._order-header {
      background: #000;

      ._page-width {
        padding-bottom: 20px;
        padding-top: 20px;

        @include non-phone {
          @include display-flex();
          @include justify-content(space-between);

          > * {
            @include flex(0 0 49%);
          }
        }
      }
    }
  }

  ._order-location,
  &._selection-order-page {
    ._loader-container {
      padding: 20px 0;

      .loader {
        @extend ._large-loader;
      }
    }
  }

  &._selection-order-page ._loader-container {
    padding: 20vh 0;
  }
}

._site ._order-details-modal {
  ._modal-content {
    @include display-flex();
    @include flex-direction(column);
    padding: 20px;

    /*
    @include non-phone {
      max-height: 80vh; //"height" fixes scroll issues for desktop

      > div {
        max-height: calc(80vh - 40px);
      }
    }*/

    > div {
      @include display-flex();
      @include flex-direction(column);
      @include flex-grow(1);
      @include justify-content(space-between);
    }

    ._title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 20px;
      text-align: center;
    }

    ._button {
      box-sizing: border-box;
      display: block;
      font-size: 14px;
      font-weight: bold;
      // margin: 20px auto 0;
      margin-left: auto; //for centered modal buttons
      margin-right: auto;
      min-width: 50%;
      text-transform: uppercase;

      @include for-phone {
        width: 100%;
      }
    }

    ._add-pickup-details-modal {
      ._input {
        box-sizing: border-box;
        border-radius: 30px;
        border-color: #efefef;
        margin-bottom: 10px;
        padding: 12px 20px;
        width: 100%;
      }

      button {
        width: 100%;
      }
    }

    ._add-pickup-details-modal,
    ._add-note-modal {
      @include non-phone {
        padding: 0 20px;
      }
    }

    ._add-note-modal {
      p {
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
      }

      ._text-box {
        background: #efefef;
        border-radius: 15px;
        margin-bottom: 20px;
        position: relative;

        textarea {
          background: 0;
          border: 0;
          font: inherit;
          height: 150px;
          line-height: 24px;
          padding: 16px;
          resize: none;
          width: 100%;
        }

        span {
          font-size: 12px;
          bottom: 10px;
          position: absolute;
          right: 10px;
        }
      }

      ._button {
        width: 100%;
      }
    }

    ._pay-modal {
      overflow: hidden;
      width: 100%;

      > div {
        @include display-flex();
        @include flex-grow(1);
        transition: transform 0.35s;
        height: 100%;
        width: 300%;

        &._page-2-active {
          transform: translateX(-33.33%);
        }

        &._page-3-active {
          transform: translateX(-66.66%);
        }

        > * {
          @include flex(0 0 33.33%);
          @include display-flex();
          @include flex-direction(column);
          @include justify-content(space-between);

          ._scrollable {
            @include flex-grow(1);
            overflow: auto;
            margin-bottom: 20px;
          }

          @include non-phone {
            box-sizing: border-box;
            padding: 0 20px;
            width: 33.33%;
          }
        }
      }

      ._credit-card-input {
        border: 1px dashed #aaa;
        border-width: 1px 0;
        padding: 10px 0;
      }

      form {
        ._button._loading {
          @include align-items(center);
          @include display-flex();
          @include justify-content(center);

          span {
            border-color: rgba(255, 255, 255, 0.75);
            border-top-color: rgba(255, 179, 197, 0.75);
            margin-left: 10px;
          }
        }

        small {
          display: block;
          font: 13px $semiBoldFont;
          padding: 3px 0;
        }
      }
    }
  }

  ._packaging-options-container {
    overflow: hidden;
    width: 100%;

    > div {
      @include display-flex();
      @include flex-grow(1);
      transition: transform 0.35s;
      height: 100%;
      width: 200%;

      &._page-1-active {
        transform: translateX(-50%);
      }

      > * {
        @include flex(0 0 50%);
        @include display-flex();
        @include flex-direction(column);
        position: relative;

        ._next-button {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  ._credit-card-input {
    border: 1px dashed #aaa;
    border-width: 1px 0;
    padding: 10px 0;
  }

  form {
    ._button._loading {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      span {
        border-color: rgba(255, 255, 255, 0.75);
        border-top-color: rgba(255, 179, 197, 0.75);
        margin-left: 10px;
      }
    }

    small {
      display: block;
      font: 13px $semiBoldFont;
      padding: 3px 0;
    }
  }

  ._select-when {
    @include display-flex();
    @include flex-direction(column);
    height: 100%;

    > h2 {
      @include non-phone {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 30px;
        margin-top: -4px;
        text-align: center;
      }

      @include for-phone {
        display: none;
      }
    }

    > ul {
      @include display-flex();

      > li {
        @include flex(0 0 46%);
      }

      &:not(:last-child) {
        @include flex-grow(1);
        @include justify-content(space-between);
      }
    }

    ._time-selection,
    ._date-selection {
      ._title {
        border-bottom: 1px dashed #aaa;
      }
    }

    ._date-selection ._dates {
      @include display-flex();
      @include flex-wrap(wrap);
      @include justify-content(center);
      overflow: auto;
      padding-top: 20px;

      @include non-phone {
        margin-bottom: 20px;
        overflow: auto;
      }

      a {
        border: 1px solid #000;
        border-radius: 50%;
        box-sizing: border-box;
        height: 80px;
        margin: 0 20px 20px 0;
        text-align: center;
        width: 80px;

        &:not(._invalid) {
          cursor: pointer;
        }

        @include align-items(center);
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(center);

        b {
          display: block;
          font-size: 24px;
        }

        small {
          display: block;
          font-size: 11px;
          margin-top: 3px;
          text-transform: uppercase;
        }

        &._invalid {
          opacity: 0.25;
        }

        &._selected {
          background: #000;
          color: #fff;
        }
      }
    }

    > ._button {
      display: none;
    }

    @include for-phone {
      transform: translateX(0);
      transition: all 0.25s;
      width: 200%;

      &._focus-time {
        transform: translateX(-50%);
      }

      > ul > li {
        @include flex(0 0 50%);
        width: 50%;
      }

      &-container {
        overflow: hidden;
      }
    }

    @include non-phone {
      ._buttons {
        display: block;

        > li:first-child {
          display: none;
        }
      }
    }
  }

  ._time-selection {
    ._select-ASAP {
      ._checkbox-cnt {
        font: 18px $semiBoldFont;
        line-height: 22px;
        margin: 0 auto;
        @include justify-content(center);
      }

      small {
        display: block;
        font-size: 14px;
        margin-top: 5px;
        text-align: center;
      }
    }

    &-times {
      padding: 20px 5px;
      overflow: auto;

      @include display-flex();
      @include flex-wrap(wrap);
      @include justify-content(space-between);

      @include for-phone {
        max-height: 52vh;
      }

      a {
        border: 1px solid #000;
        border-radius: 30px;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 20px;
        padding: 8px;
        text-align: center;
        @include flex(0 0 48%);

        &._selected {
          background: #000;
          color: #fff;
        }
      }
    }
  }

  &._order-review-modal {
    ._title {
      border-bottom: 1px dashed #aaa;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-right: 26px;
      text-align: center;
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      &:after {
        content: "";
        display: block;
      }

      img {
        cursor: pointer;
        padding: 10px;
      }

      ._subtitle {
        display: block;
        font-size: 14px;
        line-height: 18px;
      }
    }

    ._grey-block {
      background: #efefef;
      border-radius: 8px;
      padding: 16px 12px;

      ._row,
      &._subtotal {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
      }

      ._row {
        line-height: 16px;
        span {
          @include align-items(center);
          @include display-flex();

          img {
            margin-left: 8px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      &._creditcard-row {
        padding: 12px;

        &,
        > span {
          @include align-items(center);
          @include display-flex();
        }

        > img {
          height: 12px;
        }

        > span img {
          margin: 0;
          width: 45px;
        }
      }
    }

    ._error-message {
      color: #f44336;
      display: block;
      font-size: 12px;
      line-height: 1.4;
      padding-top: 10px;
      text-align: center;
    }

    ._line {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
    }

    ._noncard-method {
      background: #000;
      border-radius: 8px;
      color: #fff;
      margin-top: 4px;
      padding: 12px;

      img {
        cursor: pointer;
        margin-left: 8px;
      }
    }

    ._total {
      margin-top: 20px;
      text-transform: uppercase;
    }

    ._payment {
      font-weight: bold;
      margin-top: 40px;

      > span {
        background: url(/images/dotted.svg) repeat-x 0 50%;
        height: 1px;
        margin-left: 10px;
        @include flex-grow(1);
      }
    }

    ._crumbl-cash-toggle {
      font-size: 14px;
      font-weight: bold;
      padding: 12px 20px 20px;

      b {
        margin-right: 10px;
      }

      label {
        font-size: 12px;
        text-transform: uppercase;
      }

      img {
        margin-right: 10px;
      }
    }

    ._title-container {
      border-bottom: 1px dashed #aaa;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      text-align: center;

      p {
        font-size: 14px;
        font-weight: normal;
        line-height: 18px;
        margin-top: 3px;
      }

      ._skip {
        cursor: pointer;
        font-size: 12px;
        font-weight: normal;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  ._packing-options {
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    > * {
      @include flex(0 0 48%);
      margin-bottom: 30px;

      @include non-phone {
        @include flex(0 0 33.33%);
        width: 33.33%;
      }
    }

    &._hasSelected > *:not(._selected) {
      opacity: 0.35;
    }

    @include non-phone {
      padding: 0 20px;
    }

    ._image-container {
      border-radius: 50%;
      box-shadow: 0px 3px 8px #00000026;
      height: unquote("min(148px, 35vw)");
      margin: 0 auto 5px;
      width: unquote("min(148px, 35vw)");

      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      img {
        max-width: 60%;
      }
    }

    b {
      display: block;
      margin-bottom: 4px;
    }
  }

  ._stickers {
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(center);
    margin-bottom: 20px;
    text-align: center;

    ._sticker {
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      font-family: $mediumFont;
      font-size: 13px;
      height: unquote("min(148px, 30vw)");
      line-height: 15px;
      margin: 20px;
      padding: 5px;
      position: relative;
      width: unquote("min(148px, 30vw)");

      @include non-phone {
        height: 120px;
        width: 120px;
      }

      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      &:after,
      &:before {
        border-radius: 50%;
        content: "";
        display: block;
        position: absolute;
      }

      span:not(._checkmark) {
        position: relative;
        text-align: center;
      }

      &:before {
        background: #000;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
      }

      &:after {
        border: 2px solid #000;
        bottom: -5px;
        left: -5px;
        right: -5px;
        top: -5px;
      }
    }

    &._hasSelected ._sticker:not(._selected) {
      opacity: 0.35;
    }

    ._has-checkmark ._checkmark {
      bottom: 16px;
      filter: invert(0);
    }
  }

  ._giftwrap-note {
    border-top: 1px dashed #aaa;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 20px;
    padding-top: 20px;

    > p {
      max-width: 180px;
    }

    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    img {
      margin-right: 10px;
      max-width: 70px;
    }
  }

  ._giftwrap-buttons {
    @include display-flex();
    @include justify-content(center);

    ._bare-button {
      margin: 0 20px 0 0;
      min-width: 0;
      padding: 0 10px;
    }

    ._button:not(._bare-button) {
      margin: 0;
      min-width: 160px;
    }
  }

  ._add-pickup-details-modal {
    ._input-detail {
      @include align-items(center);
      @include display-flex();
      margin-bottom: 10px;

      ._input {
        margin: 0 0 0 10px;
      }
    }
  }

  ._modal-content ._shipping-rates {
    @include justify-content(flex-start);
    ._title {
      @include align-items(flex-start);

      p {
        font: 16px $regularFont;
        line-height: 18px;
        margin-top: 2px;
      }
    }

    ._scrollable {
      @include flex-grow(0);
    }

    ._note {
      @include flex-grow(1);
      line-height: 21px;
      padding: 20px 40px;
      text-align: center;
    }

    ._numbers {
      border: 1px dashed #aaa;
      border-width: 1px 0;
      padding: 10px 0;

      > div {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);

        &:not(:first-child) {
          padding-top: 10px;
        }
      }
    }
  }

  ._shipping-info {
    background: #000;
    border-radius: 20px;
    color: #fff;
    padding: 20px;

    span,
    b {
      display: block;
    }

    span {
      font-size: 13px;
      margin-bottom: 4px;
    }

    b {
      font-size: 18px;
    }

    p:nth-child(1) {
      margin-bottom: 16px;
    }

    small {
      opacity: 0.5;
      font-size: 13px;
    }
  }

  ._address-info {
    padding: 20px;

    b {
      display: block;
      font-size: 18px;
      margin-bottom: 16px;
    }

    p {
      line-height: 1.35em;
    }
  }
}

._order-calendar {
  padding: 0 20px;

  ._calendar-header {
    > div {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      padding-bottom: 20px;

      b {
        font: 18px $semiBoldFont;
        line-height: 26px;
      }

      img {
        cursor: pointer;
        padding: 10px;
      }
    }

    ul {
      border-top: 0;
      li {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }

  ul {
    border-top: 2px solid #aaa;
    @include display-flex();
    @include justify-content(space-between);

    > * {
      @include flex(0 0 14%);
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      line-height: 26px;
      padding: 6px 0;
      text-align: center;

      &._invalid {
        opacity: 0.25;
      }

      &._hide {
        opacity: 0;
      }

      &._selected {
        background: #000;
        border-radius: 50%;
        color: #fff;
      }
    }
  }
}

._product-selection-modal ._modal-content {
  ._products-container {
    @include flex-grow(1);
    overflow-y: auto;
    overflow-x: none;
    flex-grow: 2;

    @include non-phone {
      overflow: none;
    }
  }

  ._products {
    @include display-flex();
    @include flex-wrap(wrap);
    > * {
      @include flex(0 0 48%);
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
        max-width: 140px;
      }

      b {
        display: block;
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        margin: 4px 20px 8px;
      }

      small {
        color: #777;
        display: block;
        font-size: 12px;
      }

      ._image-container {
        position: relative;

        > span {
          background: rgba(255, 255, 255, 0.75);
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          padding: 0 30px;
          @include align-items(center);
          @include display-flex();
          @include justify-content(center);
        }
      }
    }

    @include non-phone {
      padding: 0 !important;

      > * {
        @include flex(0 0 33.33%);

        img {
          max-height: 100px;
        }
      }
    }
  }

  @include non-phone {
    ._actions {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      margin: 20px 0 0;

      @media screen and (max-width: 820px) {
        @include flex-direction(column);
      }

      @media screen and (min-width: 821px) {
        ._button {
          margin: 0 0 0 40px;
          min-width: unset;
          width: unset;
        }
      }
    }
  }

  @include for-phone {
    ._actions {
      ._box {
        background: #fff;
        overflow: hidden;
        width: 80%;
      }

      ._button {
        margin-top: 0;
        width: 80%;
      }
    }
  }

  ._actions {
    @include for-phone {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;

      ._button {
        margin: 0 auto 20px auto;
        transition: all 0.25s;
      }
    }

    &._no-button {
      ._button {
        opacity: 0;
      }

      @include for-phone {
        bottom: -56px;
      }
    }

    ._white-curtain {
      @include for-phone {
        &._for12 {
          top: 80px;
        }

        position: absolute;
        left: 0;
        right: 0;
        top: 40px;
        height: 300px;
        background-color: white;
        z-index: -1;
      }
      @include non-phone {
        display: none;
      }
    }

    @include non-phone {
      ._box-container {
        transition: transform 0.25s;
      }
      ._button {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: 150px;
      }

      @media screen and (min-width: 821px) {
        &._no-button ._box-container {
          transform: translateX(95px); //half of 150px button width + 40px margin
        }
      }
    }
  }

  ._box-container {
    ._box {
      border: 2px solid #fad0dc;
      position: relative;
      margin: 0 auto;

      ._counter {
        background: #fad0dc;
        bottom: -1px;
        box-sizing: border-box;
        display: block;
        font-size: 14px;
        left: 0;
        min-height: 20px;
        padding: 4px;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    &._boxOf1 ._box {
      height: 112px;
      width: 112px;
    }

    &._boxOf2 ._box {
      height: 112px;
      width: 224px;

      > ul {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }

    &._boxOf3 ._box {
      height: 112px;
      width: 336px;

      > ul {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 33% 33% 33%;
      }
    }

    &._boxOf6 ._box {
      height: 112px;
      width: 290px;

      > ul {
        box-sizing: border-box;
        display: grid;
        height: 100%;
        overflow: hidden;
        padding-bottom: 50px;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 50% 50%;
      }
    }

    &._boxOf4,
    &._boxOf8,
    &._boxOf12 {
      ._box > ul {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        height: 112px;
        overflow: hidden;
        width: 364px;

        @include for-phone {
          width: 100%;
        }
      }
    }

    &._boxOf8 ._box > ul {
      grid-template-rows: 50% 50%;
      height: 124px;
      padding-bottom: 40px;
    }

    &._boxOf12 ._box > ul {
      grid-template-rows: 33% 33% 33%;
      height: 186px;
      padding-bottom: 40px;
    }

    @include for-phone {
      &._boxOf4 ._box > ul {
        height: 80px;
      }
      &._boxOf6,
      &._boxOf12 {
        ._box > ul {
          height: 160px;
        }
      }

      &._boxOf6 ._box > ul {
        padding-bottom: 100px;
      }
    }

    p {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      font-size: 12px;
      line-height: 15px;
      padding: 10px 0;
      text-align: center;

      img {
        margin-right: 5px;
      }
    }
  }

  ._ice-cream-flavors {
    height: 100%;
    ._title {
      border-bottom: 1px dashed #aaa;
      padding-bottom: 20px;
      margin-top: 5px;
    }

    ._products {
      z-index: 0;
      padding-bottom: 120px;

      > * {
        padding: 0;

        img {
          min-height: 100px;
          max-height: 200px;
        }
      }
    }

    ._box-container {
      ._box {
        border: 0;
        background: none;
        overflow: visible;

        ._counter {
          background: none;
        }
      }

      &._boxOf1 ._box {
        z-index: 1;
        &._emptyCarton {
          background: url(/images/emptycarton.svg) no-repeat 50% 50%;

          ._counter {
            bottom: unset;
            color: #fff;
            top: 40%;
          }
        }
        &:not(._emptyCarton) ._counter {
          display: none;
        }

        img {
          width: 155px;
        }
      }

      &._boxOf4 ._box {
        background: url(/images/crumblcream4-back.svg) no-repeat;
        background-size: 364px 97px;
        background-position: 0 100%;
        margin-top: 10px;
        position: relative;
        width: 364px;

        > div {
          box-sizing: border-box;
          height: 97px;
          overflow: visible;
          padding: 0 2px;

          img {
            position: relative;
            z-index: 1;

            @include for-phone {
              margin-top: 10px;
            }
          }
        }

        ._counter {
          bottom: 39px;
          box-sizing: border-box;
          left: unset;
          right: 0;
          width: 25%;
        }

        &:after {
          background: url(/images/crumblcream4-fore.svg) no-repeat;
          background-position: 0 100%;
          background-size: 360px;
          bottom: 2px;
          content: "";
          display: block;
          height: 49px;
          left: 2px;
          position: absolute;
          right: 2px;
          top: unset;
          z-index: 1;
        }

        @include for-phone {
          background-size: 100%;
          min-width: unset;
          width: 300px;
          z-index: 1;

          &:after {
            background-size: 100%;
          }

          > div {
            width: 298px;
          }

          ._counter {
            bottom: 32px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

._order-page-container {
  background-color: #f6f6f6;
  position: relative;

  ._checkout-btn-container {
    position: fixed;

    @include non-phone {
      @include align-items(center);
      @include display-flex();
      top: 20px;
      margin-right: _page-offset();
      right: 48px;

      > span {
        @include align-items(center);
        @include display-flex();
        margin-right: 20px;

        img {
          margin-right: 10px;
        }

        span {
          max-width: 300px;
          @include _ellipsis;
        }
      }
    }

    @include for-phone {
      bottom: 20px;
      left: 20px;
      right: 20px;

      > span {
        display: none;
      }
    }
  }

  ._checkout-btn {
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    &[disabled] {
      opacity: 0.15;
    }

    &._catering-checkout-disabled {
      box-shadow: 0px 3px 8px #222;
      filter: invert(1);
      opacity: 0.95;
      color: #555;
    }

    @include for-phone {
      &:not(._catering-checkout-disabled)[disabled] {
        display: none;
      }

      width: 100%;
    }

    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
  }
}

._checkout-modal {
  ._button {
    margin-top: 10px;
  }

  ._separator {
    border-bottom: 1px dashed #aaa;
    height: 1px;
    width: 100%;
  }

  ._important {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);
    border: 1px dashed #aaa;
    border-width: 1px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 16px 0;
  }

  &._checkout-review {
    height: 100%;
    @include non-phone {
      padding: 0 20px;
    }

    ._button {
      margin-top: 10px;
    }

    ._details {
      overflow: auto;
      margin-top: -20px;
      padding: 20px 0;

      li {
        margin-bottom: 20px;

        ._product-details {
          @include display-flex();
          @include justify-content(space-between);

          b._discounted {
            text-decoration: line-through;
          }

          &._discount-details {
            line-height: 19px;
          }
        }

        ._edit {
          @include display-flex();
          margin: 10px 0;

          button {
            margin: 25px 0 0 8px;
          }
        }

        b {
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
        }

        p {
          font-size: 12px;
          line-height: 18px;
        }

        ._catering-image {
          @include align-self(center);
          @include display-flex();
          @include flex-wrap(wrap);
          max-width: 250px;

          > img {
            width: 45px;
          }
        }

        ._single-image {
          max-width: 112px;
        }

        ._box {
          border: 2px solid #fad0dc;
          border-bottom-width: 20px;
          margin-top: 5px;
        }

        ._boxOf1 {
          height: 65px;
          width: 65px;
        }

        ._boxOf4,
        ._boxOf8,
        ._boxOf12 {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: 25% 25% 25% 25%;
          height: 65px;
          overflow: hidden;
          width: 200px;
        }

        ._boxOf8 {
          grid-template-rows: 50% 50%;
          height: 65px;
          padding-bottom: 10px;
        }

        ._boxOf3 {
          display: grid;
          grid-template-columns: 33% 33% 33%;
          overflow: hidden;
          height: 65px;
          width: 200px;
          padding-bottom: 10px;
        }

        ._boxOf6 {
          grid-template-rows: 50% 50%;
          grid-template-columns: 33% 33% 33%;
          height: 100px;
          padding-bottom: 10px;
        }

        ._boxOf12 {
          grid-template-rows: 33% 33% 33%;
          height: 120px;
          padding-bottom: 10px;
        }

        button {
          background: none;
          border: 1px solid #efefef;
          border-radius: 50%;
          cursor: pointer;
          height: 45px;
          margin-top: 20px;
          width: 45px;

          @include align-items(center);
          @include justify-content(center);
          @include display-flex();

          img {
            opacity: 0.3;
          }

          &:hover {
            background: #000;
            border-color: #000;

            img {
              filter: invert(1);
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &._checkout-sign-in {
    height: 100%;
    @include non-phone {
      padding: 0 20px;
    }
  }

  &._checkout-catering-modal {
    ._images {
      @include align-items(center);
      @include display-flex();
      @include flex-wrap(wrap);
      max-width: 60%;

      img {
        width: 45px;
      }
    }
    ._details li ._edit {
      @include align-items(center);
      button {
        margin-top: 0;
      }
    }
  }

  &._checkout-payment {
    ._details {
      padding: 16px 0;

      ._highlighted-details {
        background: #efefef;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 15px 20px 5px 20px;

        ._row span {
          @include align-items(center);
          @include display-flex();
          font-weight: bold;

          img {
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }

      ._row {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);

        padding-bottom: 10px;

        &._indented {
          padding-left: 10px;
        }
      }

      button {
        background: #fff;
        border: 1px solid #aaaaaa;
        border-radius: 25px;
        box-sizing: border-box;
        font: 14px $regularFont;
        font-weight: bold;
        line-height: 18px;
        margin: 5px 0 15px;
        padding: 16px 8px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }

  &._payment-method {
    ._cards {
      margin: 0 -40px;
      overflow: auto;
      padding: 20px 40px;

      > li {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        border: 1px solid #aaaaaa;
        border-radius: 8px;
        margin-bottom: 5px;
        padding: 20px;

        ._name {
          @include align-items(center);
          @include display-flex();
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 4px;

          img {
            margin-right: 10px;
            max-height: 24px;
          }
        }

        p {
          color: #595959;
          font: 14px $regularFont;
          line-height: 19px;
        }
      }
    }
  }

  &._card-form {
    ._checkbox-cnt {
      margin-top: 20px;
      @include justify-content(center);
    }

    ._secure-cards,
    ._secure-note {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
    }

    ._secure-cards {
      padding: 30px 0 20px;
      img {
        height: 25px;
        margin: 0 3px;
      }
    }

    ._secure-note {
      color: #aaaaaa;
      font-size: 12px;

      img {
        filter: invert(1);
        height: 15px;
        opacity: 0.25;
        margin-top: -2px;
        margin-right: 5px;
      }
    }

    ._form-container {
      border-bottom: 1px dashed #aaa;
      padding: 20px 0;
      ._form {
        border: 1px solid #eee;
        border-radius: 8px;
        height: 170px;
      }
    }
  }
}

._site ._order-confirmation-modal {
  @include align-items(flex-start);
  position: absolute;
  top: 10vh;
  @include for-phone {
    top: 0;
  }
  transform: translateY(0);

  &-shadow {
    background: rgba(0, 0, 0, 0.75);
  }

  .receipt-border {
    background: url(/images/white-triangle.svg) repeat-x 0 0;
    height: 10px;
  }

  @include for-phone {
    ._modal-inner {
      margin: 0 8px;
      margin-top: -5px;
    }

    ._modal-content {
      top: 20px;
    }
  }

  ._modal-content {
    background: none;
    border-radius: 0;
    padding: 0;
  }

  // ._hide-modal {
  // display: none;
  // }

  ._order-confirmation-inner {
    background: #fff;
    border-radius: 0;
    position: relative;

    ._highlighted {
      background: #efefef;
      border-radius: 8px;
      margin-bottom: 2px;
      padding: 15px 12px;
    }

    ._card-info {
      line-height: 18px;
      text-align: center;

      b {
        text-transform: capitalize;
      }
    }

    ._date-time-store-info {
      text-align: center;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    ._loyalty {
      font-weight: bold;
      padding: 20px 0;
      text-align: center;
      text-transform: uppercase;

      img {
        display: block;
        margin: 0 auto 10px;
      }
    }

    ._total {
      margin-top: 18px;
    }

    .loader {
      @extend ._large-loader;
    }

    @include non-phone {
      padding: 20px;
    }

    @include for-phone {
      padding: 10px 20px;
    }

    h2 {
      @include align-items(flex-start);
      @include display-flex();
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;

      img {
        margin: 3px 8px 0 0;
        max-height: 28px;
      }

      @include non-phone {
        @include justify-content(flex-start);
      }
    }

    p {
      font: 16px $regularFont;
      line-height: 19px;

      &._row {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        font-weight: bold;

        &:not(:last-child) {
          padding-bottom: 10px;
        }

        span {
          @include align-items(center);
          @include display-flex();

          img {
            margin-left: 8px;
          }
        }
      }

      &._bold {
        font-weight: bold;
      }
    }

    ._centered {
      text-align: center;
    }

    ._order-map {
      background: #111;
      height: 180px;
      margin: 20px 0 10px;
    }

    ._button {
      box-sizing: border-box;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      width: 100%;
    }

    ._section {
      padding: 20px 16px;

      &:not(:last-child) {
        border-bottom: 1px dashed #aaa;
      }

      h4 {
        font: 20px $regularFont;
        font-weight: bold;
        line-height: 24px;
      }

      &-title p {
        margin-top: 10px;
      }
    }

    ._timeline {
      @include display-flex();
      margin-top: 14px;

      > * {
        @include flex(0 0 25%);
        text-align: center;

        ._icon {
          background: #ebebeb;
          border-radius: 50%;
          height: 54px;
          margin: 0 auto 10px;
          width: 54px;

          @include align-items(center);
          @include display-flex();
          @include justify-content(center);
        }

        b {
          color: #ebebeb;
          font-size: 16px;
          font-weight: bold;
          display: block;
        }

        small {
          color: #aaaaaa;
          font-size: 12px;
          line-height: 15px;
        }

        &._done {
          ._icon {
            background: #000;

            img {
              filter: invert(1);
            }
          }

          b {
            color: #000;
          }
        }
      }
    }

    ._two-column {
      @include display-flex();
      @include justify-content(space-between);

      > * {
        @include flex(0 0 48%);
        line-height: 19px;
      }

      h4 {
        margin-bottom: 10px;
      }

      small {
        color: #777;
        display: block;
        font-size: 12px;
        margin-top: 10px;
      }
    }

    ._details {
      li {
        margin: 20px 0;
        @include display-flex();
        @include justify-content(space-between);

        p {
          font-size: 12px;
          line-height: 18px;
        }

        img {
          display: block;
          margin-top: 10px;
          max-height: 100px;
        }

        ._box {
          border: 2px solid #fad0dc;
          border-bottom-width: 20px;
          height: 50px;
          margin: 4px 0 8px;

          img {
            max-height: 100%;
          }
        }

        ._product-details {
          @include align-items(flex-start);
          @include display-flex();
          @include flex-direction(column);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ._crumbs-section {
      @include align-items(center);
      @include display-flex();
      @include flex-direction(column);
      @include justify-content(center);

      > b {
        margin-bottom: 20px;
      }

      ._total {
        background: #000;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        padding: 6px 24px;
        position: relative;

        img {
          bottom: -7px;
          filter: invert(1);
          height: 7px;
          left: 47%;
          position: absolute;
          transform: rotate(180deg);
        }
      }

      ._countdown {
        @include display-flex();
        @include justify-content(center);
        padding: 10px 20px;

        > li {
          padding: 0 15px;

          span {
            border-radius: 50%;
            height: 78px;
            margin: 0 auto 10px;
            width: 78px;

            &._box,
            &._single {
              background: #fad0dc;
            }

            &._party {
              background: #000;
            }
          }

          > * {
            display: block;
            text-align: center;
          }

          b {
            font-size: 14px;
            line-height: 15px;
          }

          small {
            color: #aaaaaa;
            font-size: 12px;
          }
        }
      }

      ._tally {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);
        text-transform: uppercase;

        b {
          font-size: 16px;
          line-height: 19px;
          margin-right: 15px;
        }

        h5 {
          @include align-items(center);
          @include display-flex();
          font-size: 40px;
          font-weight: bold;
          line-height: 49px;

          small {
            font-size: 12px;
            line-height: 15px;
            margin-left: 5px;
            @include flex-basis(20%);
          }
        }
      }
    }

    ._cancel-order {
      text-align: center;

      button {
        margin: 10px auto;

        @include non-phone {
          width: 100%;
        }

        @include for-phone {
          width: auto;
        }
      }

      b {
        display: block;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  @media print {
    .map-container {
      display: none;
    }
    ._order-confirmation-inner {
      padding: auto;

      ._details {
        li {
          margin: 0px;

          ._product-details {
            padding: 4px;

            img {
              display: none;
            }
          }
        }
      }

      ._socials-cta {
        display: none;
      }

      ._two-column {
        padding: 0;
      }

      ._section {
        padding: 10px;

        #delivery-tracker {
          display: none;
        }

        ._section-title {
          h2 {
            img {
              display: none;
            }
          }
        }
      }
    }

    ._order-contact {
      display: none;
    }

    .pb-10 {
      display: none;
    }
  }

  ._catering-confirmation {
    @include non-phone {
      h2 {
        @include justify-content(flex-start);
      }
      ._section-title {
        text-align: left;
      }
    }

    ._centered {
      font-size: 18px;
      line-height: 22px;

      b {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
      }
    }

    ._order-summary {
      @include align-items(center);
      @include display-flex();
      color: #aaa;
      font-size: 18px;

      h3 {
        color: #000;
        font-size: 24px;
        font-weight: bold;
        margin-right: 6px;
      }
    }

    ._section {
      b {
        display: block;
        margin-bottom: 5px;
      }
      h4 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }
  }

  ._order-contact {
    color: #fff;
    padding: 40px 0;
    text-align: center;

    @include for-phone {
      padding-left: 15px;
      padding-right: 15px;
    }

    p a,
    b {
      display: block;
      font-size: 18px;
      line-height: 22px;
    }

    ._button {
      background: #fff;
      box-sizing: border-box;
      color: #000;
      display: block;
      font-weight: bold;
      margin: 10px 0 20px;
      text-transform: uppercase;
      width: 100%;

      @include non-phone {
        margin: 10px 70px 20px;
        width: calc(100% - 140px);
      }
    }

    p a {
      font-weight: bold;
      margin-top: 5px;
    }
  }
}

//custom modal widths
@include non-phone {
  ._site ._modal {
    &._size450,
    &._order-location-modal {
      ._modal-inner {
        max-width: 450px;
      }
    }

    &._size550,
    &._order-confirmation-modal,
    &._order-review-modal {
      ._modal-inner {
        max-width: 550px;
      }
    }

    &._size700,
    &._product-selection-modal {
      ._modal-inner {
        max-width: 700px;
      }
    }
  }
}

._order-location-modal ._modal-content {
  ._hide-modal {
    font-size: 50px;
    left: 20px;
    top: 32px;
  }
}

._site ._order-details-modal ._giftwrap-options-cnt {
  @include display-flex();
  @include flex-direction(column);
  @include justify-content(space-between);
  height: 100%;

  ._top {
    @include justify-content(center);
    @include display-flex();
    @include align-items(center);
    margin: 0 auto;
    max-width: 80%;

    > div {
      margin-left: 10px;

      h3._title {
        margin-top: -10px;
        padding-bottom: 5px;
        text-align: left;
      }
    }
  }

  ul {
    @include display-flex();
    @include flex-wrap(wrap);
    @include justify-content(flex-start);

    @include non-phone {
      @include justify-content(center);

      > * {
        margin: 0 5px;
      }
    }

    > * {
      margin-bottom: 10px;
      text-align: center;

      @include for-phone {
        @include flex(0 0 50%);
      }

      @include non-phone {
        @include flex(0 0 25%);
      }
    }

    &._hasPreselected {
      ._sticker {
        opacity: 0.2;

        &:hover {
          opacity: 1;
        }
      }
      ._selected ._sticker {
        opacity: 1;
      }
    }

    ._sticker {
      background: #000;
      border-radius: 50%;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      height: 120px;
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      margin: 10px auto 20px;
      padding: 0 10px;
      position: relative;
      text-align: center;
      width: 120px;

      @include align-items(center);
      @include display-flex();
      @include justify-content(center);

      &:before {
        border: 2px solid #000;
        border-radius: 50%;
        bottom: -5px;
        content: "";
        display: block;
        left: -5px;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
}

._pickup-instruction {
  margin-top: 16px;

  li {
    @include align-items(flex-start);
    @include display-flex();

    &:not(:last-child) {
      background: url(/images/pickup-instructions-bg.svg) repeat-y 18px 0;
      padding-bottom: 20px;
    }

    p {
      line-height: 19px;
      padding-right: 32px;
    }

    > span {
      @include flex-shrink(0);
      background: #000;
      border-radius: 50%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 38px;
      margin-right: 10px;
      text-align: center;
      width: 38px;
    }

    h5 {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    ._button {
      margin-top: 20px;
    }
  }

  &-input {
    padding: 20px;

    > * {
      box-sizing: border-box;
      width: 100%;
    }

    h2 {
      border-bottom: 1px dashed #aaa;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    input {
      border: 1px solid #efefef;
      border-radius: 30px;
      font: 16px $regularFont;
      margin-bottom: 20px;
      padding: 13px 20px;
    }

    ._button {
      font: 14px $regularFont;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

._pickup-status {
  ul {
    @include display-flex();
    @include justify-content(center);
    margin: 20px 0;

    li {
      @include flex(0 0 30%);
      position: relative;
      text-align: center;

      &:not(._selected) {
        opacity: 0.15;
      }

      &:not(:last-child):after,
      &:not(:first-child):before {
        background: #000;
        display: block;
        content: "";
        height: 20px;
        position: absolute;
        top: 25%;
        width: 50%;
      }

      &:not(:last-child):after {
        right: 0;
      }

      &:not(:first-child):before {
        left: 0;
      }

      span {
        background: #000;
        border-radius: 50%;
        height: 54px;
        margin: 0 auto 10px;
        width: 54px;

        @include align-items(center);
        @include display-flex();
        @include justify-content(center);

        img {
          z-index: 2;
        }
      }

      b {
        display: block;
        font-size: 16px;
        font-weight: bold;
      }

      small {
        font-size: 12px;
      }
    }
  }
}

._modifiers-cnt {
  // overflow-y: auto;
  // overflow-x: hidden;
  > div {
    height: 100%;

    > div {
      height: 100%;

      @include display-flex();
      @include flex-direction(column);

      ._products {
        @include flex-shrink(1);
      }
    }
  }
}

._creditcard-row {
  @include display-flex();
  @include justify-content(space-between);
  padding-right: 20px;

  > img {
    height: 24px;
  }

  ._circles {
    @include display-flex();
    > span {
      background: #000;
      border-radius: 50%;
      height: 8px;
      margin-left: 3px;
      width: 8px;
    }
  }

  > span {
    @include align-items(center);
    @include display-flex();

    > * {
      margin-left: 10px;
    }
  }
}

._payment-methods {
  &-shadow {
    opacity: 0.01;
  }

  ._creditcard-row {
    @include align-items(center);
    border: 1px solid #eee;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 10px 20px;

    &._selected {
      border-color: #aaa;
    }

    img {
      height: 32px;
    }

    > div {
      padding: 10px 0;
    }
  }

  ._add-card {
    img {
      display: block;
      height: 200px;
      margin: 0 auto 20px;
    }

    ._credit-card-input {
      border: 1px dashed #aaa;
      border-width: 1px 0;
      padding: 10px;
    }

    small {
      display: block;
      font: 13px $semiBoldFont;
      padding: 3px 0;
    }
  }
}

._site ._order-status {
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  font-size: 16px;
  line-height: 19px;
  opacity: 0;
  transition: transform 0.35s;
  z-index: 1;

  @include non-phone {
    transform: translateY(25vh);
  }

  @include for-phone {
    transform: translateY(-25vh);
  }

  ._page-width {
    @include justify-content(space-between);
    background: #000;
    color: #fff;
    padding: 20px;

    &,
    > div {
      @include align-items(center);
      @include display-flex();
    }
  }

  &._show {
    opacity: 1;
    transform: translateY(0);
  }

  b {
    display: block;
    font-size: 20px;
    line-height: 22px;
  }

  ._icon-container {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    background: url(/images/receipt-tag.svg);
    height: 61px;
    margin-right: 20px;
    width: 49px;
  }

  @include non-phone {
    &._show {
      border-radius: 8px;
      bottom: 10px;
      left: 10px;
      right: unset;

      > div {
        border-radius: 8px;
        padding-bottom: 0;

        > div {
          @include align-items(flex-start);
          margin-right: 70px;
        }

        > img {
          margin-bottom: 20px;
        }
      }
    }
  }
}

._site ._modal._info-modal {
  ._hide-modal {
    display: none;
  }

  ._modal-content {
    @include align-items(center);
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    height: auto;

    h3 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
    }

    @include for-phone {
      padding: 35px 32px 30px;

      h3 {
        font-size: 24px;
        line-height: 29px;
      }

      img {
        margin: 20px auto 40px;
      }

      ._button {
        margin-top: 60px;
      }
    }

    @include non-phone {
      padding: 45px 78px 30px;

      h3 {
        font-size: 30px;
        line-height: 37px;
      }

      img {
        margin: 8px auto 24px;
      }

      ._button {
        margin-top: 30px;
      }
    }
  }

  @include for-phone {
    ._modal-inner {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      height: 100vh;
    }
    ._modal-content {
      border-radius: 20px;
      bottom: unset;
      top: unset;
    }
  }

  &._closure-modal {
    ._modal-content {
      padding: 35px 30px 30px;

      ._message {
        line-height: 1.4;
        margin: 0 auto;
        text-align: center;
      }

      ._sign {
        background: url(/images/dark-closed-sign.svg) no-repeat;
        background-size: cover;
        box-sizing: border-box;
        height: 145px;
        padding-top: 68px;
        margin: 0 auto 30px;
        width: 219px;

        img {
          filter: invert(1);
          height: 26px;
          margin: 0 auto;
        }
      }

      p,
      b {
        display: block;
        margin-bottom: 16px;
      }

      button {
        box-sizing: border-box;
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  &._invalid-address-modal {
    ._modal-content {
      padding: 20px;

      img {
        opacity: 0.5;
      }

      p {
        margin-bottom: 40px;

        @include for-phone {
          max-width: 70%;
        }
      }

      button {
        box-sizing: border-box;
        margin-top: 20px;
        width: 100%;

        @include align-items(center);
        @include display-flex();
        @include justify-content(center);

        .loader {
          opacity: 0.75;
          margin-left: 10px;
        }
      }
    }
  }
}

._add-animation {
  opacity: 1;
  position: relative;
  transition: opacity 0.35s;
  z-index: 3;

  &._hidden {
    display: none;
  }

  &._show1 ._product-container {
    transform: scale(2.5) translateY(-100%);
  }

  &._hiding {
    //transition-delay: 0.15s;

    &,
    ._shadow {
      opacity: 0;
    }
  }

  ._shadow {
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.35s;
  }

  ._product-container,
  ._product {
    @include display-flex();
    @include justify-content(center);
  }

  ._product-container {
    left: 20px;
    bottom: 88px;
    position: fixed;
    right: 20px;
    transition: transform 0.35s;
    z-index: 4;
  }

  ._product {
    @include align-items(flex-end);
    @include display-flex();
    @include justify-content(center);
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
    width: 120px;

    &:after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
      bottom: -9px;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }
  }

  @include non-phone {
    &._show1 {
      ._product-container {
        transform: none;

        ._product {
          transform: scale(5) translateX(-50%) translateY(50%);
          transform-origin: 100% 0;
        }
      }
    }

    ._product-container {
      bottom: 0;
      box-sizing: border-box;
      left: 0;
      top: 0;
      padding-top: 88px;
      right: 0;

      > ._page-width {
        box-sizing: border-box;
        @include align-items(flex-start);
        @include display-flex();
        @include justify-content(flex-end);
        width: 100%;
      }

      ._product {
        transition: transform 0.35s;

        &:after {
          bottom: unset;
          top: -9px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

//mapbox
.location-selector,
._order-location {
  .mapboxgl-ctrl-geocoder {
    @include display-flex();
    @include flex-direction(column);
    background: #000;

    .mapboxgl-ctrl-geocoder--icon {
      display: none;
    }

    .mapboxgl-ctrl-geocoder--input {
      border: 0;
      border-radius: 30px;
      box-sizing: border-box;
      font: 16px $mediumFont;
      margin: 10px 20px;
      padding: 12px 20px;
      width: calc(100% - 40px);
    }

    .suggestions-wrapper {
      @include flex-grow(1);
      overflow: auto;
    }

    .mapboxgl-ctrl-geocoder--pin-right {
      display: none;
    }
  }
}

._price-breakdown {
  ._modal-content {
    margin: 0 10px !important;
  }

  ._separator {
    margin: 5px 0 20px;
  }

  ._exit {
    cursor: pointer;
    font-weight: normal;
    font-size: 30px;
    line-height: 20px;
    transform: rotate(45deg);
  }
}

//catering
._site ._catering-order {
  &._order-details-modal {
    left: 0;
    right: 0;

    ._modal-content {
      overflow: hidden;
      padding: 0;
    }
  }

  ._size-selector-container,
  ._when-selector {
    ._head {
      background: #fff;
      font-size: 14px;
      line-height: 18px;
      position: relative;
      text-align: center;

      h2 {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }

  ._when-selector {
    background: #efefef;
    overflow: hidden;
    width: 100%;

    ._date-selector-parent {
      @include display-flex();
      @include flex-direction(column);
      height: 100%;
      max-height: inherit;

      > * {
        padding: 20px;
      }

      ._date-selector-container {
        @include flex-grow(1);
        box-sizing: border-box;
        overflow: auto;
        padding: 20px;
      }
    }

    ._button {
      width: 100%;
    }

    ._step-1 {
      @include display-flex();
      @include flex-direction(column);

      ._scrollable {
        @include flex-grow(1);
      }
    }

    ._step-2 ._button {
      width: calc(100% - 40px);
    }

    > div {
      @include display-flex();
      transition: transform 0.35s;
      width: 200%;

      &._step-2-active {
        transform: translateX(-50%);
      }

      > *:not(._button) {
        @include flex(0 0 50%);

        > *:not(._scrollable) {
          box-sizing: border-box;
          padding: 20px;
        }

        ._selection {
          background: #efefef;
        }
      }
    }

    ._month-selector {
      @include justify-content(space-between);
      margin-bottom: 20px;

      > img {
        cursor: pointer;
        padding: 10px 20px;
      }

      ._empty {
        width: 50px;
      }

      &,
      ._month-title {
        @include align-items(center);
        @include display-flex();
      }

      ._month-title {
        @include flex-grow(1);
        @include justify-content(center);
        font-size: 20px;
        font-weight: bold;
        line-height: 27px;

        img {
          margin-right: 10px;
        }
      }
    }

    ._date-selector li > a {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      background: #fff;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 10px;
      overflow: hidden;
      padding: 20px 30px;
      transition: background 0.25s;

      &:not(._disable) {
        cursor: pointer;
      }

      ._grey-text {
        opacity: 0.3;
      }

      &._selected ._grey-text {
        opacity: 0.5;
      }

      .checkmark {
        @include justify-content(center);
        background: #fff;
        border-radius: 50%;
        height: 30px;
        transform: translateX(-30px);
        transition: transform 0.25s;
        width: 0;
      }

      &._selected {
        background: #000;
        color: #fff;

        .checkmark {
          @include flex(0 0 30px);
          transform: translateX(0);
          margin-right: 10px;
          width: 30px;
        }
      }

      small {
        display: block;
        font-size: 12px;
        font-weight: normal;
        line-height: 15px;
      }

      &._disable {
        > * {
          opacity: 0.3;
        }

        span {
          text-decoration: line-through;
        }
      }
    }

    ._time-selector {
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 40px;

      &,
      &-label,
      a {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);
      }

      &-label {
        font-size: 20px;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 26px;
        padding-right: 22px;

        img {
          margin-right: 10px;
        }
      }

      a {
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
        height: 50px;
        width: 50px;

        img {
          width: 10px;
        }
      }

      b {
        margin: 0 40px;
      }
    }
  }

  ._size-selector {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    font-size: 16px;
    padding: 20px 0 50%;
    text-align: center;

    @include non-phone {
      padding: 20px 20px 40px;
    }

    b {
      display: block;
      font-weight: bold;
      margin-top: 10px;
    }

    &-container ._head {
      border-bottom: 1px dashed #aaa;
      margin: 0 20px;
      padding: 20px 0;
    }
  }
}

._catering-menu {
  @include non-phone {
    padding: 28px 0;
  }

  &-container {
    background: #f8f8f8;
    position: relative;
  }

  @include non-phone {
    margin: 0 auto;
    max-width: 780px;
  }

  ._label {
    font-size: 14px;
    line-height: 18px;
    margin: 0 20px;
    text-align: center;

    @include for-phone {
      &:first-child {
        margin: 0;

        h2 {
          background: #000;
          color: #fff;
          display: block;
          padding: 0 0 20px;
        }
      }
    }

    @include non-phone {
      font-size: 22px;
      line-height: 27px;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 8px;

      @include non-phone {
        font-size: 30px;
        line-height: 37px;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      margin-bottom: 30px;

      @include non-phone {
        font-size: 40px;
        line-height: 49px;
      }
    }
  }

  ._notice {
    @include align-items(flex-start);
    @include display-flex();
    @include justify-content(space-between);
    background: #efefef;
    border-radius: 10px;
    cursor: pointer;
    font-family: $mediumFont;
    font-size: 14px;
    line-height: 18px;
    margin: 10px 20px 0;
    padding: 10px 16px;

    @include non-phone {
      border-radius: 50px;
      box-sizing: border-box;
      margin: 30px auto 0;
      max-width: 475px;
      padding: 10px 20px;
    }

    img:first-child {
      margin: 2px 10px 0 0;

      @include non-phone {
        margin-right: 40px;
      }
    }

    img:last-child {
      align-self: center;
      margin-left: 10px;
    }
  }

  ._list {
    @include display-flex();
    @include flex-wrap(wrap);

    ._cookie-image {
      cursor: pointer;
    }

    > * {
      @include non-phone {
        @include flex(0 0 30%);
        margin: 20px 1.665%;

        ._cookie-image {
          height: 180px;
        }
      }
      @include for-phone {
        @include flex(0 0 50%);
      }
    }

    &._disabled {
      opacity: 0.5;
    }

    li {
      @include display-flex();
      @include flex-direction(column);
      @include justify-content(space-between);
      box-sizing: border-box;
      font-size: 14px;
      line-height: 18px;
      padding: 20px 10px;
      text-align: center;

      @include non-phone {
        font-size: 20px;
        line-height: 24px;
      }

      b {
        display: block;
        margin-bottom: 5px;
      }

      small {
        color: #595959;
        display: block;
        font-size: 12px;

        @include non-phone {
          color: #aaa;
          font-size: 16px;
          line-height: 19px;
        }
      }

      ._buttons {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        font-size: 30px;
        font-weight: bold;
        padding: 8px 4px 0;

        img {
          background: #fff;
          border: 1px solid #eee;
          border-radius: 50%;
          width: 45px;

          &:not(._disabled):hover {
            cursor: pointer;
            filter: invert(1);
          }

          &._disabled {
            border-color: #ccc;
            opacity: 0.25;
          }
        }
      }
    }
  }

  &._add-ons {
    padding-bottom: 46px;
    padding-top: 0;
    width: 100%;

    ._packaging-image {
      background: #fff no-repeat 50% 50%;
      background-size: 50%;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0px 3px 8px #00000026;
      height: 160px;
      margin: 0 auto 10px;
      width: 160px;

      @include for-phone {
        height: 140px;
        width: 140px;
      }
    }

    ._sticker {
      background: #000;
      border-radius: 30px;
      color: #fff;
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
      padding: 4px 8px;
    }

    ._selected ._packaging-image {
      border-color: #000;
    }

    ._list > li {
      cursor: pointer;
      padding: 20px 0 0;

      &._soldOut {
        cursor: initial;
        opacity: 0.35;
      }

      @include non-phone {
        margin-top: 0;
        padding: 0;
      }

      small {
        color: #000;
      }
    }
  }

  ._calorie-notice {
    color: #595959;
    font-size: 10px;
    line-height: 15px;
    padding: 28px;
    text-align: center;

    @include for-phone {
      font-size: 12px;
      font-style: italic;
    }
  }
}

._review-catering-order > * {
  border-bottom: 1px dashed #aaa;
  font-size: 16px;
  line-height: 22px;
  padding: 20px 0;

  &._row {
    @include display-flex();
    @include justify-content(space-between);

    > * {
      @include flex(0 0 48%);
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h4 {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
  }

  b {
    display: block;
  }

  &._summary {
    @include align-items(center);
    @include display-flex();
    color: #aaaaaa;
    font-family: $mediumFont;
    font-size: 18px;

    h4 {
      color: #000;
      margin-right: 10px;
    }
  }
}

._has-checkmark {
  position: relative;

  ._checkmark {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    background: #fff;
    border-radius: 50%;
    bottom: 30px;
    filter: invert(1);
    height: 30px;
    left: calc(50% - 15px);
    position: absolute;
    width: 30px;
  }
}

._catering-faq {
  @include for-phone {
    &._modal {
      left: 0;
      right: 0;
    }
  }

  ._head {
    margin-bottom: 10px;
    position: relative;
    text-align: center;

    ._close-icon {
      cursor: pointer;
      left: 0;
      padding: 10px;
      position: absolute;
      top: -3px;

      @include non-phone {
        left: -10px;
        transform: scale(1.2);
      }
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 10px;
    }
  }

  ul {
    @include for-phone {
      margin: 0 10px;
    }

    li {
      background: #efefef;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      margin-top: 10px;
      padding: 20px;

      div {
        @include display-flex();

        > *:first-child {
          @include flex(0 0 18px);
          margin-right: 10px;
          text-align: center;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

._site ._modal._capture-order-modal {
  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
  }

  ._large-loader {
    margin: 20px auto;
  }

  ._hide-modal {
    margin-top: 6px;
  }
}

._site ._soft-topping-modal {
  &,
  &-shadow {
    z-index: 9;
  }

  h3 {
    font-size: 19px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
  }

  ._buttons {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);
    margin-top: 20px;

    ._button:first-child {
      border: 1px solid #fff;
      filter: invert(1);
      margin-right: 20px;
    }
  }
}

@include non-phone {
  body._order-page ._site ._header-container {
    max-width: unset;
    padding: unquote(
      $string: (
        "10px max(calc((100vw - 1230px) / 2), 15px)"
      )
    );
  }
}

._socials-cta {
  @include align-items(center);
  @include display-flex();
  @include flex-direction(column);
  border-bottom: 1px dashed #aaa;
  padding: 20px;
  text-align: center;

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin: 10px 0 5px;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    padding: 0 20px;
  }

  ._links {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    > * {
      margin: 0 15px;
    }
  }
}

._order-crumbs {
  border-bottom: 1px dashed #aaa;
  padding: 20px 0;

  h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
  ul {
    margin-top: 20px;

    li {
      @include justify-content(space-between);
      background-color: #fad0dc;
      border-radius: 8px;
      padding: 0 20px 0 15px;

      &._completed {
        background-color: #efefef;
        opacity: 0.5;

        > div {
          text-decoration: line-through;
        }
      }

      &,
      > div {
        @include align-items(center);
        @include display-flex();
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      ._icon-container {
        width: 62px;
      }

      ._points {
        background-color: #000;
        border-radius: 30px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        padding: 3px 10px;
      }
    }
  }
}

._questions-container {
  overflow: hidden;
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
    max-height: 150px;
  }

  > div {
    @include display-flex();
    transition: transform 0.35s;

    > div {
      overflow: hidden;
    }
  }

  ._crumb-info {
    &-container {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      img {
        cursor: pointer;
        margin: 0 10px;
      }
    }

    background: #000;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    padding: 3px 12px;
    text-align: right;
  }

  ._question {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 40px;
    padding: 30px 10px;
    text-align: center;
  }

  ._emoji-feedback {
    margin: 10vh 0 15vh;
    text-align: center;
    > div {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      font-size: 60px;
      margin-bottom: 20px;

      > div {
        margin: 0 5px;
      }
    }

    p {
      font-size: 16px;
      line-height: 19px;
    }
  }

  ._yes-no-feedback {
    margin: 80px 0 20px;
    &,
    > div {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
    }

    div {
      background-color: #000;
      border-radius: 40px;
      height: 80px;
      margin: 10px;
      width: 80px;
    }
  }

  ._photo-feedback {
    img {
      border-radius: 10px;
      max-height: 375px;
      margin: 0 auto 35px;
      width: 375px;
    }
    ._camera-container {
      border-radius: 10px;
      overflow: hidden;
      text-align: center;

      video {
        max-height: 300px;
        max-width: 100%;
      }

      &._hasError {
        background: #ffebee;
        border: 1px solid #ef9a9a;
        margin-bottom: 20px;

        span {
          display: block;
          padding: 50px 0;
        }
      }
    }

    .loader {
      border-color: #f1f1f1;
      border-top-color: #aaa;
      margin: 0 auto;
    }

    ._skip {
      font-size: 14px;
      font-weight: bold;
      margin-top: 30px;
      text-align: center;
      text-transform: uppercase;

      b {
        color: #aaaaaa;
        display: block;
        margin-bottom: 15px;
      }

      span {
        cursor: pointer;
      }
    }

    ._button {
      width: 100%;
    }
  }

  ._buttons {
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);

    button {
      border: 1px solid #000;
      @include flex(0 0 48%);
    }

    ._white-button {
      background: #fff;
      border-color: #aaa;
      color: #000;
    }
  }

  ._text-feedback {
    button {
      margin-top: 20px;
      width: 100%;
    }

    ._text-box {
      margin-top: -50px;
      position: relative;

      span {
        bottom: 10px;
        font-size: 12px;
        position: absolute;
        right: 10px;
      }
    }

    textarea {
      background: #efefef;
      border: 0;
      border-radius: 8px;
      box-sizing: border-box;
      height: 126px;
      line-height: 1.4;
      padding: 8px;
      resize: none;
      width: 100%;
    }
  }

  ._thankyou {
    ._question {
      margin: 140px 0;
    }

    ._button {
      width: 100%;
    }
  }

  ._expired {
    img {
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 18px;
      margin-top: 15px;
    }
    ._button {
      width: 100%;
    }
  }
}

._site ._modal._order-shipping-location-modal ._modal-content {
  @extend .gray-bg;

  .mapboxgl-ctrl-geocoder {
    > *:not(input, svg, .suggestions-wrapper) {
      display: none !important;
    }

    > svg {
      height: 20px;
      left: 20px;
      opacity: 0.15;
      position: absolute;
      top: 12px;
      width: 20px;
    }

    > input {
      border: none;
      border-radius: 26px;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      padding: 12px 24px 12px 48px;
      width: 100%;
    }
  }
}

.address-selector {
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    > *:not(input) {
      display: none;
    }

    input[type="text"] {
      @apply border border-solid border-[#E6E6E6] pl-8 pr-4 py-2 rounded-lg w-full;
    }
  }
}

._tips-container {
  padding: 0 80px;

  &._disabled {
    opacity: 0.35;
  }

  ._buttons {
    @include align-items(center);
    @include display-flex();
    @include justify-content(center);

    > * {
      background: #fff;
      border: 1px solid #aaaaaa;
      border-radius: 50%;
      cursor: pointer;
      font: 18px $regularFont;
      font-weight: bold;
      margin: 0 10px;

      @include for-phone {
        height: 55px;
        width: 55px;
      }

      @include non-phone {
        height: 65px;
        width: 65px;
      }

      @include align-items(center);
      @include display-flex();
      @include flex-shrink(0);
      @include justify-content(center);

      &._custom {
        font-size: 12px;
        text-align: center;
      }

      &._selected {
        background: #000;
        border-color: #000;
        color: #fff;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0 20px;
    text-align: center;
  }

  @include for-phone {
    padding: 0;
  }
}

@mixin line-clamp($lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  @include line-clamp(2);
}

.line-clamp-3 {
  @include line-clamp(3);
}
