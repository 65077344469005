@mixin page-title {
  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 3em;
    font-weight: normal;
    line-height: 1em;
    margin: 80px auto 20px auto;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;

    @media screen and (max-width: 401px) {
      font-size: 2.25em;
      line-height: 1.15em;
    }
  }
}

.page-title {
  @include page-title;
}

.items-cnt {
  line-height: 1.25em;
  padding: 0 20px 40px;

  p {
    text-align: center;
    font-size: 0.9em;
    color: rgb(150, 150, 150);
    margin: 0em 4em;
  }

  @include page-title;

  .items {
    @media screen and (min-width: 401px) {
      @include display-flex();
      @include flex-wrap(wrap);
    }

    @media screen and (min-width: 812px) {
      margin: 0 auto;
      width: 812px;
    }
    @media screen and (max-width: 811px) {
      @include justify-content(center);
    }

    > div {
      margin: 1em 0 2em;

      .btn {
        width: 160px;
        margin-left: 2em;
      }

      @media screen and (max-width: 400px) {
        margin-left: auto;
        margin-right: auto;

        .btn {
          margin-left: 0em;
        }
      }
    }
  }
}
.item {
  @include display-flex();
  @include flex-direction(column);
  //@include justify-content(flex-end);
  // background: #f1f1f1 50% 50%;
  // background-size: cover;
  // border-radius: 1em;
  // border: 1px solid #f0f0f0;
  position: relative;
  overflow: hidden;
  margin: 0 2em 1em 2em;
  @media screen and (max-width: 400px) {
    margin: 0 0 1em;
  }

  &,
  .item-image {
    width: 340px;
    //height: 380px;
    @media screen and (max-width: 400px) {
      width: 100%;
      //height: 380px;
    }
  }

  > div:first-child {
    min-height: 343px;
  }

  .item-image {
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 1;
  }
  // .blurred-item {
  //   top: 300px;
  //   background-position: 0 -300px;
  //   -webkit-filter: blur(5px);
  //   -moz-filter: blur(5px);
  //   -o-filter: blur(5px);
  //   -ms-filter: blur(5px);
  //   filter: blur(5px);
  //   z-index: 2;
  //   @media screen and (max-width: 400px) {
  //     top: 300px;
  //   }
  // }
  .bottom-bar {
    @include align-items(center);
    @include display-flex();
    box-sizing: border-box;
    line-height: 1.5em;
    margin-top: 0.5em;
    width: 100%;
    //height: 80px;
    //z-index: 3;
    font-size: 1.2em;
    text-align: left;
  }

  .item-details {
    > b {
      display: block;
      font-size: 1.3em;
      font-weight: normal;
      margin-left: 12px;
      text-transform: lowercase;
    }
    small {
      font-size: 0.9em;
      line-height: 1.15em;
    }
  }
}

.catering {
  text-align: center;
  padding-bottom: 0em;
  .btn {
    margin-bottom: 2em;
  }
  label {
    text-align: left;
  }
  .pink-bar {
    @media screen and (max-width: 400px) {
      margin: 0.5em auto;
    }
  }
}
