._jobs-page {
  video {
    max-width: 100vw;
  }

  ._apply {
    background: #f6f6f6;
    padding: 80px 0 115px;

    ._box b,
    h4 {
      font-weight: bold;
      text-align: center;
    }

    h4 {
      font-size: 48px;
      line-height: 115px;
    }

    h5 {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
    }

    ._box {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 8px #00000026;
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 592px;
      padding: 40px 60px;

      b {
        display: block;
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 20px;
      }
    }

    ._box > div:not(:first-child) {
      margin-top: 40px;
    }

    ._input,
    ._button {
      width: 100%;
    }

    ._button {
      position: relative;
      span {
        border-top-color: #999;
        position: absolute;
        right: 12px;
        top: 12px;
      }

      &[disabled] {
        opacity: 0.5;
      }

      &._submitted {
        opacity: 1;
      }
    }

    ._input {
      border-color: #aaaaaa;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 15px;
      padding: 12px 24px;
    }

    input:-internal-autofill-selected {
      box-shadow: 0 0 0 1000px #fff inset;
    }

    select,
    [type="text"] {
      border-radius: 30px;
    }

    textarea {
      border-radius: 15px;
      height: 220px;
      resize: none;
    }

    select {
      background: url(/images/arrow-down.svg) no-repeat calc(100% - 24px) 50%;
      moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    ._checkbox-cnt {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;

      input {
        @include flex-shrink(0);
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    ._positions {
      @include display-flex();
      @include flex-grow(1);
      @include flex-wrap(wrap);
      margin-right: -20px;

      > * {
        @include flex-grow(1);
        border: 1px solid #000;
        border-radius: 8px;
        margin: 0 20px 20px 0;
        padding: 20px;
      }
    }

    @include for-phone {
      padding: 44px 20px 58px;

      h4 {
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 33px;
      }

      ._box {
        padding: 30px 20px;

        b {
          font-size: 20px;
          line-height: 24px;
        }
      }

      ._positions {
        @include flex-direction(column);

        > * {
          margin-bottom: 10px;
        }
      }

      ._checkbox-cnt {
        padding: 8px 10px;
      }
    }
  }

  h3 {
    font-size: 60px;
    font-weight: bold;
    line-height: 115px;
    margin-bottom: 30px;
    text-align: center;

    @include for-phone {
      font-size: 24px;
      line-height: 1.4;
    }
  }

  ._submitted {
    display: block;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  ._images {
    padding: 100px 0;
  }

  @include for-phone {
    padding: 40px 0 80px;
  }

  @include for-tablet {
    ._page-width {
      padding: 0 14px;
    }
  }
}
