._nutrition-page {
  h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 58px;
    margin: 36px auto;
    text-align: center;
  }

  ._loader-container {
    padding: 100px 0;
  }

  ._row {
    @include display-flex();

    @include for-phone {
      @include flex-direction(column);
    }
  }

  ._nutrition-note {
    color: #aaa;
    display: block;
    font-size: 12px;
    line-height: 15px;

    @include non-phone {
      margin-left: calc(50vw - 27.5px);
      padding: 40px 55px 40px 0;
    }

    @include for-phone {
      padding: 30px;
    }
  }

  ._nutrition-info {
    @include non-phone {
      box-sizing: border-box;
      padding-top: 40px;
      width: calc(100vw - 55px);
      max-width: 900px;
      margin: auto;

      > * {
        @include flex(0 0 50%);
      }

      ._image-container img {
        width: calc(100% + 55px);
        transform: translateX(-55px);
      }
    }

    @include for-phone {
      padding: 30px 30px 0;
    }

    ._info {
      border-bottom: 1px solid #e8e8e8;
      @include for-phone {
        padding-bottom: 10px;
      }

      @include non-phone {
        margin-top: 20px;
      }
    }

    .serving {
      color: #777;
      font-weight: normal;
      text-transform: uppercase;

      @include non-phone {
        font-size: 20px;
        line-height: 24px;
        @include align-items(center);
      }

      @include for-phone {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 5px;
        @include align-items(flex-start);

        ._sticker {
          display: none;
        }
      }
    }

    ._sticker {
      background: #000;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
      padding: 3px 6px;
    }

    h2 {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 36px;

      @include for-phone {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 20px;
    }

    p {
      font: 14px $regularFont;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  ._nutrition-label {
    max-width: 676px;
  }

  @include for-phone {
    ._nutrition-info ._info > ._image-container {
      display: block;
      margin: 10px auto;
    }
  }

  @include non-phone {
    ._nutrition-info > ._image-container {
      display: block;
    }
  }

  ._coming-soon {
    font-size: 60px;
    font-weight: bold;
    opacity: 0.15;
    margin: 200px auto;
    text-align: center;
  }
}

._nutrition-facts {
  border: 1px solid #000;
  margin-bottom: 20px;
  padding: 10px;

  @include non-phone {
    @include flex-shrink(0);
    box-sizing: border-box;
    margin-right: 32px;
    width: 250px;
  }

  ._numbers {
    margin-bottom: 8px;

    > div {
      border-bottom: 1px solid #000;
      @include display-flex();
      @include justify-content(space-between);
      padding: 5px 0;
    }

    ._big {
      border-bottom-width: 6px;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    ._border {
      border-bottom-width: 10px;
    }

    ._indented {
      padding-left: 20px;
    }
  }

  ._note {
    font-size: 0.8em;
    line-height: 1.25em;
  }
}
