@mixin for-x2 {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (max-width: 820px) {
    @content;
  }
}

@mixin non-tablet {
  @media screen and (min-width: 820px) {
    @content;
  }
}

@mixin for-phone {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin non-phone {
  @media screen and (min-width: 641px) {
    @content;
  }
}

._desktop-only,
._mobile-only {
  display: none;
}

@media screen and (min-width: 482px) {
  ._desktop-only {
    display: block;
  }
}

@media screen and (max-width: 481px) {
  ._mobile-only {
    display: block;
  }
}
