.faq-page {
  .middle-cnt {
    padding-top: 40px;

    > div {
      padding-bottom: 40px;
    }
  }

  h3 {
    font-size: 2em;
    display: block;
    line-height: 1.25em;
  }

  p {
    display: block;
    line-height: 1.5em;
    padding-top: 10px;
  }

  ul {
    padding-top: 10px;
    li {
      display: block;
      line-height: 1.5em;
      padding: 3px 0;

      b {
        font-size: 1.35em;
      }
    }
  }
}

._site ._faq-section {
  h3 {
    font-size: 60px;
    font-weight: bold;
    line-height: 115px;
    margin-bottom: 30px;
    text-align: center;

    @include for-phone {
      font-size: 24px;
      line-height: 1.4;
    }
  }

  ._page-width div {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 20px;
  }

  ._row {
    @include display-flex();

    span {
      @include flex(0 0 40px);
    }

    > * {
      @include flex-grow(1);
    }
  }

  b {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 10px;
  }

  @include for-phone {
    padding: 30px 0 64px;

    ._page-width {
      padding: 0 20px;
    }

    b {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
