.credit-card-options,
.delivery-options {
  background: #f9f9f9;
  //border-bottom: 1px solid #e0e0e0;

  @media screen and (max-width: 700px) {
    border-top: 1px solid #e0e0e0;
  }
  border-width: 1px 0;
  padding: 1em;
  > * {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.credit-card-options {
  background: #fff;

  .icon-row {
    overflow: hidden;
  }
}

.modal {
  .delivery-options {
    padding: 0;
  }
}

.delivery-pickup {
  a {
    background: #fff;
    border: 1px solid #dfdede;
    border-radius: 3px;
    color: #aaa;
    display: block;
    padding: 1em 0;
    position: relative;
    text-align: center;
    &.selected {
      background: #eee;
      color: #111;
    }
  }
  i {
    display: block;
    font-size: 1.5em;
    margin: 30px auto 0.5em;
  }
  .fa-check-circle {
    background: #fff;
    border-radius: 100%;
    display: none;
    color: #f2a5b7;
    font-size: 1em;
    position: absolute;
    right: -5px;
    top: -5px;
  }
  a.selected .fa-check-circle {
    display: block;
  }

  p {
    line-height: 1.25em;
    margin: 0 auto;
    text-align: center;

    @media screen and (min-width: 500px) {
      max-width: 80%;
    }
  }
}

.fa-truck {
  width: 45px;
  margin: auto;
}

.icon-row {
  @include display-flex();
  @include justify-content(space-between);

  align-items: center;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  pointer: cursor;
  margin-bottom: 1em;
  padding: 0;

  input,
  .fake-input {
    background: #fff;
    border: 0;
    box-shadow: none;
    margin: 0;
    outline: none;
    padding: 1em;
    font-size: 1em;
    width: 100%;
    color: #000;
    text-align: left;

    -webkit-appearance: none;

    &.placeholder {
      color: #999;
    }
  }

  > *:first-child {
    background: #fff;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    width: calc(100% - 50px);
  }

  > *:last-child {
    @include flex-grow(1);
    text-align: center;
  }

  .apartment {
    border-radius: 5px;
    border-right: none;
  }

  &:active {
    box-shadow: inset 0 0 1em #ddd;
  }

  &.delivery-place input {
    text-align: left;
  }

  &.icon-row-err {
    border-color: #e61e4d;
    border-width: 2px;
  }
}

.credit-card-icon {
  height: 52px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #ddd;
  margin-left: -1px;
}

.remember-me-row {
  align-items: center;
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 12px;
  color: #666;
  font-size: 0.9em;
}

.remember-me-row .switch {
  float: right;
  position: relative;
  top: -18px;
}

.switch {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 20px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 20px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #ffb3c5;
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 22px;
  }
}

.form-switch {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  i {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    background-color: #eee;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 42px;
      height: 22px;
      background-color: #f1f1f1;
      border-radius: 11px;
      transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
      transition: all 0.25s linear;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 11px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
      transform: translate3d(2px, 2px, 0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:active {
    i::after {
      width: 28px;
      transform: translate3d(2px, 2px, 0);
    }

    input {
      &:checked + i::after {
        transform: translate3d(16px, 2px, 0);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked + i {
      background-color: #000;

      &::before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }

      &::after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }
}

.show-errors {
  .icon-row {
    &.invalid {
      border-color: #ffb3c5;
      // background: #ffe8ee;

      > *:first-child {
        border-right-color: #ffb3c5;
      }
    }
  }

  .invalid {
    .fake-input,
    .input-row,
    input {
      background: #ffe8ee;
    }
  }
}

input.large-input {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1em;
  margin-bottom: 1em;
  padding: 1em;
  width: 100%;
}

.address-input-container,
.card-button-container {
  align-items: center;
  display: flex;
  border: 0;
  border-radius: 5px 0 0 5px;
  border-right: 1px solid #ddd;
  background: #fff;
  font-size: 1em;
  input {
    border-radius: 5px 0 0 5px;
    padding: 0.8em;
    border: none;
    height: 27px;
  }
  input:read-only {
    color: #666;
  }
}
.card-button-container.invalid {
  background: #ffe8ee;
}

#sq-apple-pay {
  background: none;
  border: none;
  display: none;
}

#sq-apple-pay.show {
  display: block;
}

.card-button-container img {
  max-width: 50px;
  max-height: 25px;
  height: auto;
  width: auto;
}

img.card-image {
  max-width: 35px;
}

.card-list,
.address-list {
  min-height: 200px;

  li > a {
    @include align-items(center);
    @include display-flex();
    color: #111;
    padding: 0.5em 0.5em 0;

    &:hover {
      background-color: #f9f9f9;
    }

    > i {
      margin: -0.75em 1em 0;
    }

    > span {
      @include flex-grow(1);
      line-height: 1.5em;
      margin-left: 0.5em;
    }

    small {
      border-bottom: 1px solid #f2f2f2;
      color: #888;
      display: block;
      font-size: 0.85em;
      padding-bottom: 1em;
    }
  }
}

.powered-by-google {
  padding: 15px 5px;
  text-align: right;
}

.shop-menu {
  padding: 0 1em;
}

.shop-menu-item {
  border-top: 1px solid #f0f0f0;
  &:first-child {
    border-top: 0;
  }
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}

.shop-menu-details {
  align-items: flex-start;
  color: #777;
  display: flex;
  font-size: 1em;
  line-height: 1.5em;
  text-transform: lowercase;
}

.shop-menu-item img {
  height: auto;
  margin-right: 1em;
  width: 100px;
  border-radius: 15px;
}

.shop-menu-details b {
  color: #222;
  font-size: 1.3em;
  line-height: 1.25em;
  font-weight: 700;
  text-transform: lowercase;
}

.shop-menu-item .sold-out img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.shop-menu-item .sold-out b {
  font-weight: normal;
}

.shop-menu-buttons {
  align-items: center;
  border: 1px solid #e0dfdf;
  display: flex;
  font-size: 1.5em;
  margin: 0 0 1em 1em;
  padding: 0.5em 0;
  > * {
    padding: 0 0.5em;
  }

  ._num {
    $numHeight: 22px;
    border: 1px solid #e0dfdf;
    border-width: 0 1px;
    height: $numHeight;
    overflow: hidden;

    > div {
      transform: translate3d(0, -33%, 0);

      > span {
        display: block;
        height: $numHeight;
        line-height: $numHeight;
        min-width: 25px;
        text-align: center;
      }
    }

    &._dec div {
      transition: transform 0.2s;
      transform: translate3d(0, -66%, 0);
    }

    &._inc div {
      transition: transform 0.2s;
      transform: translate3d(0, 0%, 0);
    }
  }

  i {
    color: #f2a5b7;
    font-size: 1em;
  }
  a {
    font-weight: bold;

    &:active {
      text-shadow: 0 0 2px #ccc;
    }
  }

  &:active {
    box-shadow: inset 0 0 3px #eee;
  }
}

/* -- products -- */
@media screen and (max-width: 600px) {
  .shop-menu-item img {
    width: 60px;
    border-radius: 5px;
  }
  .shop-menu-details div {
    font-size: 0.9em;
  }
  .shop-menu-details b {
    font-size: 1.2em;
  }
  .shop-menu-buttons {
    font-size: 1.35em;
    margin-left: 0.5em;
    padding: 0.5em 0;
    > * {
      padding: 0 0.5em;
    }
  }
}

.place-order {
  background: #f9f9f9;
  color: #aaa;
  padding: 1em 1em 2em;
  text-align: center;
  border-top: 1px solid #e0dfdf;
  p {
    margin: 1em 1em 2em;
    line-height: 1.25em;
    padding: 0;
    color: #4a4a4a;
  }
  .special-note {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1em;
    padding: 1em;
    position: relative;

    > span {
      bottom: 0.5em;
      color: #aaa;
      font-size: 0.8em;
      position: absolute;
      right: 1em;

      &._alert {
        color: #e57373;
      }
    }
  }
  .order-gift {
    padding-bottom: 1em;
  }
  textarea {
    background: none;
    border: 0;
    -webkit-appearance: none;
    display: block;
    font-size: 1em;
    height: 120px;
    margin: 0;
    padding: 0;
    resize: none;
    width: 100%;
  }
  textarea::placeholder {
    color: #aaa;
  }
  label {
    @include align-items(center);
    @include display-flex();
    font-size: 0.9em;
    margin: 1em 0;
  }
}

@media screen and (max-width: 600px) {
  .place-order .btn i {
    display: none;
  }
}

.delivery-modal {
  padding: 20px;
  text-align: center;
  padding: 3px 20px 20px 20px;
  color: #999;

  button {
    font-size: 0.7em;
  }
}

.generic-modal,
.delivery-modal,
.tip-modal,
.card-modal,
.address-modal {
  padding: 20px;
  .subheader {
    color: #000;
    font-size: 1.45em;
    font-weight: bold;
    text-align: center;
    padding: 3px 0 5px 20px;
  }

  .subtext {
    text-align: center;
    font-size: 0.7em;
    padding-bottom: 15px;
  }

  .delivery-options {
    background: #fff;
    border: none;
  }

  .small-subheader {
    color: #999;
    font-size: 0.8em;
    line-height: 1.25em;
    text-align: center;
    padding-bottom: 2em;
  }
}

.generic-modal {
  .btn {
    width: 100%;
  }
}

.time-picker,
.date-picker {
  display: flex;
  justify-content: center;
  max-width: 300px;
  margin: auto;
  padding-bottom: 15px;
  color: #999;

  button {
    width: 50px;
    height: 50px;
    margin: 5px;
    background: none;
    border: 1px solid black;
    border-radius: 8px;
    cursor: pointer;

    &:not(.selected-day):active {
      box-shadow: inset 0 0 5px #ccc;
    }
  }

  .day {
    text-transform: uppercase;
    font-size: 0.7em;
    position: relative;
  }

  .day-number {
    font-size: 1.7em;
  }

  button.selected-day {
    background: black;
  }

  button.sold-out {
    background: #e4e4e4;
    color: #9d9d9d;
    border: 1px solid #7d7d7d;
  }

  .selected-day {
    color: white;
  }
}
.time-picker {
  flex-flow: wrap;
  button {
    width: 140px;
    height: 40px;
  }
}

.today-picker {
  max-width: 290px;
  justify-content: center;
  margin: 20px auto;
  text-transform: uppercase;
  display: flex;
  border: 1px solid black;
  border-radius: 8px;

  button {
    width: 145px;
    height: 40px;
    background: none;
    color: black;
    border: none;
    text-transform: uppercase;
  }

  button:last-child {
    border-left: 1px solid black;
  }

  button.picker-selected {
    color: white;
    background: black;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  button.picker-selected:last-child {
    border-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.saved-card-list,
.saved-address-list {
  li.selected {
    background: rgba(255, 179, 197, 0.35);
    border: 1px solid rgba(255, 179, 197, 0.85);
  }
  li {
    display: flex;
    align-items: center;

    input {
      padding: 10px;
      background: #0f0;
      appearance: none;
      width: 20px;
      height: 20px;
    }

    input:checked {
      background: #f00;
    }

    img {
      padding: 10px;
      cursor: pointer;
      width: 20px;
    }

    .checkbox {
      margin: 20px;
    }

    .location-description {
      .name {
        padding: 2px;
      }
      .description {
        padding: 2px;
      }
      flex: 1;
      padding: 5px;
      .description {
        color: #666;
      }
    }
  }
}

/* credit card */
.credit-card-options {
  background: #f5f5f5;
  h4 {
    font-family: inherit;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .btn {
    position: relative;
    width: 100%;

    i {
      left: 1em;
      position: absolute;
    }
  }

  .cc-logos {
    text-align: center;
    width: 200px;
    margin: 0 auto 0.75em;
  }

  #sq-ccbox {
    iframe {
      background: #fff;
      min-height: 50px;
    }
    .cc-grouped {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 1em;
      overflow: hidden;

      .icon-row iframe,
      .icon-row {
        border: 0;
        border-radius: 0;
        margin: 0;
      }

      #sq-card-number {
        border-right: 1px solid #ddd;
      }
    }
    .cc-cvv-date {
      @include display-flex();

      border-top: 1px solid #ddd;
      iframe {
        width: 48%;
      }

      > *:last-child {
        border-left: 1px solid #ddd;
      }
    }
  }

  iframe.sq-input--focus {
    /*outline: 5px auto rgb(59, 153, 252);*/
  }

  iframe.sq-input--error {
    /*outline: 1px solid rgb(255, 97, 97);*/
  }
}

.cc-errors {
  background: #f5f5f5;
  color: #555;
  font-size: 0.8em;
  line-height: 1.25em;
  padding: 1em 0;
  text-align: center;
}

.subtotal {
  margin: 1em 0;

  &-row {
    color: #222;
    line-height: 1.25em;
    padding: 6px 0;
    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);

    span {
      @include flex-order(1);

      &:last-child {
        text-align: right;
        @include flex(0 0 15%);
        @include flex-order(3);
      }
    }
  }
}

.select-tip {
  @include align-items(center);
  @include display-flex();
  @include flex-wrap(wrap);
  @include justify-content(center);

  > * {
    border: 1px solid #ccc;
    border-radius: 30px;
    box-sizing: border-box;
    color: #222;
    font-size: 1.25em;
    height: 60px;
    line-height: 60px;
    margin: 0 5px;
    text-align: center;
    width: 60px;

    &:hover:not(.selected) {
      background: #ccc;
      color: #444;
    }

    &.selected {
      background: #222;
      border-color: #222;
      color: #fff;
    }
  }

  .custom-tip,
  .tipbox {
    @include flex(0 0 75%);
    margin: 20px 0;
  }

  .tipbox {
    padding: 5px;
  }

  .tipbox:hover {
    background: #fff;
  }
}

.tip-modal .btn {
  position: relative;
  width: 100%;

  i {
    left: 1em;
    position: absolute;
  }
}

.zip-form-container {
  height: 52px;
  iframe {
    border-radius: 5px;
  }
}

.email-form-container {
  input {
    width: calc(100% - 50px);
    border-radius: 5px;
    height: 20px;
  }
}

.credit-card-form-container {
  border-bottom-left-radius: 0px !important;
  height: 52px;
}

.sms-updates {
  color: #777;
  font-size: 0.8em;
  padding: 0 0 2em;

  @include align-items(center);
  @include display-flex();

  label {
    margin-left: 0.5em;
  }
}

.stickers {
  padding-bottom: 2em;

  .sticker {
    border: 1px solid #222;
    border-radius: 50%;
    height: 150px;
    margin: 0 auto;
    padding: 2px;
    width: 150px;

    > div {
      background: #ccc;
      border-radius: inherit;
      color: #fff;
      height: 100%;
      width: 100%;
      @include align-items(center);
      @include display-flex();
      @include flex-direction(column);
      @include justify-content(center);

      span {
        font-size: 0.9em;
      }
    }
  }

  .sticker.selected {
    > div {
      background: #222;
    }
  }
}

.gift-options {
  border-top: 1px dashed #ddd;
  padding: 2em 0;

  > div {
    @include display-flex();
    @include justify-content(center);

    label {
      line-height: 1.25em;
      margin: 0;

      input {
        margin-right: 0.5em;
      }

      span {
        color: #999;
        display: block;
        font-weight: bold;
        font-size: 0.8em;
      }
    }
  }

  .form-switch {
    margin-rightt: 0.25em;
  }
}
