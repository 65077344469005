.tv-button {
  background: white;
  border: 1px solid black;
  border-radius: 10px;
  flex: 1;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 2vh;
  padding: 2vh;
  align-items: center;
  color: #000;

  img {
    height: 6vh;
  }
}

.menu-page {
  display: flex;
  font-family: "Montserrat", sans-serif;

  &._full {
    bottom: 0;
    height: 100vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }

  ._list {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    justify-content: space-between;

    background: #000;
    color: #fff;
    z-index: 1;

    ._logo {
      background: url("https://crumbl.video/c97e4216-a8cb-478d-b802-7f17646a77ca_Baker-Wink-Store-Menu.gif") no-repeat
        50% 65%;
      background-size: contain;
      height: 15vh;
      margin: 3vh 0 3vh 0;
    }

    ._list-inner {
      margin: 0 auto;
      width: 82%;
    }

    ul {
      margin-bottom: 5vh;

      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3vh;

        &._soldOut b {
          opacity: 0.45;
        }

        b {
          font-size: 4vh;
          font-weight: bold;
        }

        small {
          display: block;
          font-size: 2vh;
          font-weight: normal;
          line-height: 3vh;
          opacity: 0.5;
          padding-top: 1.1vh;
        }
      }
    }
  }

  ._disclaimer {
    display: block;
    font-size: 1.6vh;
    font-style: italic;
    color: #777777;
    line-height: 2.3vh;
  }

  ._calories {
    color: #777777;
    font-weight: normal;
    font-size: 3vh;
  }

  ._featured {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    background: #fff;
    position: relative;

    > div {
      margin: 0 auto;
      transition: opacity 0.25s;
      width: 60%;

      > * {
        display: block;
      }

      ._productImage {
        align-self: flex-start;
        height: 68vh;
        margin: -0.8vh 0;
      }

      b {
        font-size: 5vh;
        font-weight: bold;
        line-height: 6vh;
        padding-bottom: 1vh;
        align-self: flex-start;
      }
      p {
        align-self: flex-start;
        font-weight: 300;
        font-size: 3.35vh;
        line-height: 4.5vh;
      }

      &._product-container {
        background: #fff;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      &._water {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        ._productImage {
          height: unset;
          margin-bottom: 15vh;
          transform: scale(1.2) translateX(-2vw);
        }
        ._features {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          width: 50vw;

          > * {
            max-width: 10vw;
          }
        }
      }

      &._sour-patch {
        align-items: center;
        display: flex;
        justify-content: center;

        > div {
          margin: 0 auto;
          width: 80%;
        }

        h2,
        h3 {
          text-align: center;
          text-transform: uppercase;
        }

        h2 {
          font-size: 8vh;
          font-weight: bold;
          margin: 2vh 0;
        }

        h3 {
          align-items: center;
          display: flex;
          font-size: 3.5vh;
          justify-content: center;
        }

        ._cookie-container {
          position: relative;

          img {
            display: block;
            height: 55vh;
            margin: 3vh auto 0;
          }

          &:after,
          &:before {
            background: url("/images/Make-A-Wish-07.png") 50% 50% no-repeat;
            background-size: contain;
            content: "";
            display: block;
            height: 15vh;
            position: absolute;
            width: 30vh;
          }

          &:before {
            top: 0;
            left: 0;
          }

          &:after {
            bottom: 0;
            right: 0;
          }
        }

        ._make-a-wish-logo {
          height: 7vh;
          margin: -2.5vh 0 0 2vh;
        }

        sub {
          font-size: 3vh;
          font-weight: normal;
        }

        ._new {
          background: url("/images/Make-A-Wish-06.png") 0 50% no-repeat;
          background-size: 100%;
          margin: -3.5vh -2.5vh;
          padding: 3.5vh;
        }
      }

      &._dad {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        ._productImage {
          height: 58vh;
          margin: 3vh auto 0;
        }

        ._features {
          display: flex;
          font-weight: bold;
          font-size: 4vh;
          justify-content: space-between;
          line-height: 4.75vh;
          margin: 0 10vh;

          small {
            display: block;
            font-size: 3vh;
            font-weight: normal;
            line-height: 3.75vh;
          }
        }

        h2 {
          font-size: 8vh;
          font-weight: bold;
          line-height: 10vh;
          text-align: center;
          text-transform: uppercase;

          i {
            font-weight: normal;
            font-style: italic;
            text-transform: lowercase;
          }
        }
      }
    }
  }
}

._rotate-left,
._rotate-right {
  position: absolute;
  top: -100vw;
  height: 100vw;
  width: 100vh;
  overflow: auto;
}
._rotate-left {
  transform: rotate(-90deg);
  transform-origin: bottom right;
  right: 0;
}

._rotate-right {
  transform: rotate(90deg);
  transform-origin: bottom left;
  left: 0;
}

.stats-page {
  ._head {
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    height: 76px;
    justify-content: space-between;
    overflow: hidden;
    padding: 8px;

    h1 {
      font-weight: normal;
      font-size: 2.25em;
      padding-left: 0.25em;
      text-transform: uppercase;
    }

    img {
      height: 90px;
      margin: -15px;
    }

    > * {
      align-items: center;
      display: flex;
    }

    ._pagination {
      a {
        background: #fff;
        border-radius: 50%;
        display: block;
        height: 12px;
        margin-right: 10px;
        opacity: 0.5;
        width: 12px;
      }

      ._active a {
        opacity: 1;
      }
    }
  }

  ._stats {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    min-height: calc(100vh - 76px);
  }

  ._stat {
    background: #fff;
    border: 2px solid #000;
    border-radius: 1vh;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      grid-column: span 2;
      grid-row: span 3;
    }

    h3 {
      font-size: 2.5vh;
      font-weight: bold;
      justify-self: flex-start;
    }

    ._info {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
      align-items: flex-start;

      b {
        font-size: 6vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: flex-end;
      }

      ._rank {
        align-self: flex-end;
        border-radius: 2vh;
        font-size: 1.5vh;
        font-weight: bold;
        margin-bottom: 0.5vh;
        padding: 0.5vh 2vh;
        align-self: flex-end;

        &._red {
          background: #fce2e1;
          color: #af1a2e;
        }

        &._green {
          background: #e2fce9;
          color: #087e56;
        }
      }
    }
  }
}

._pie-container {
  margin: 10px auto;
  width: 100%;

  ._labels {
    display: flex;
    justify-content: space-between;

    & > * {
      color: #aaa;
      font-weight: bold;
      text-align: center;
      padding-top: 12px;
      width: 20%;
    }
  }

  ._pie {
    display: block;
    overflow: hidden;
    padding-top: 50%;
    position: relative;
    width: 100%;

    ._outer {
      background-origin: border-box;
      background-image: conic-gradient(#087e56 calc(3.6deg * (var(--pie-percent) / 2)), #f1f1f1 0);
      border-radius: 50%;
      bottom: -100%;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(-90deg);
      width: 100%;
    }

    ._inner {
      background: #fff;
      border-radius: 50%;
      font-weight: bold;
      height: 120%;
      left: 20%;
      position: absolute;
      top: 40%;
      width: 60%;
    }

    ._value {
      bottom: 0;
      color: #087e56;
      font-size: 3em;
      font-weight: bold;
      left: 0;
      padding-bottom: 10px;
      position: absolute;
      right: 0;
      text-align: center;
    }
  }
}

._tv-menu {
  padding: 3.7vh;

  overflow-y: hidden;
  overflow-x: hidden;

  ._header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.7vh;

    ._logo {
      height: 11.8vh;
    }

    ._giftcards {
      align-items: center;
      display: flex;
      margin-right: 11.1vh;
      overflow: hidden;

      img {
        height: 12.78vh;
        margin: -1.86vh 2.78vh -1.86vh 0;
      }

      span:not(._flag) {
        color: #aaa;
        font-size: 2.2vh;
        font-weight: 400;
        line-height: 2.7vh;
        text-transform: uppercase;
      }

      h3 {
        font-weight: 700;
        font-size: 3.7vh;
        line-height: 4.5vh;
      }
    }
  }

  ._boxes {
    display: flex;
    flex-wrap: wrap;
    margin-right: -3.33vw;
    justify-content: space-between;

    ._menu-box,
    ._menu-item {
      margin-right: 3.33vw;
    }

    ._menu-box {
      margin-bottom: 1.86vh;

      ul {
        padding: 1.86vh;
      }
    }

    ._menu-item {
      box-sizing: border-box;
      padding: 0 1.86vh;
    }

    ._menu-title {
      background: #000;
      color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 0.8vh 1vw;

      b {
        font-size: 4.4vh;
        font-weight: bold;
        text-transform: uppercase;
      }

      small {
        color: #aaaaaa;
        font-size: 1.86vh;
        line-height: 2.2vh;
        text-align: right;

        span {
          display: block;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }

    ._items-1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ._menu-box {
        flex-basis: 100%;
      }

      ._menu-item {
        margin-bottom: 3.75vh;
      }
    }

    ._menu-prices {
      font-size: 3.33vh;

      &._smaller {
        font-size: 2.8vh;
      }

      b {
        font-weight: bold;
      }

      li {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 1.86vh;
        }

        small {
          color: #777;
          font-size: 2.2vh;
          font-weight: 400;

          &.subtitle {
            display: block;
            font-size: 1.8vh;
            line-height: 2.5vh;
            margin-right: -1vw;
            margin-top: 0.5vh;
          }
        }
      }
    }

    ._menu-item {
      align-items: center;
      display: flex;
      margin-bottom: 1.4vh;

      img {
        height: 8.7vh;
        margin-right: 1.4vh;
      }

      b {
        display: block;
        font-size: 3.3vh;
        line-height: 4.1vh;

        &._striked {
          text-decoration: line-through;
        }
      }

      small {
        color: #777777;
        font-size: 1.6vh;
        text-transform: uppercase;
      }
    }

    > div:first-child {
      flex-basis: 33.33%;

      ._menu-box:not(:last-child) {
        margin-bottom: 6.9vh;
      }
    }

    > div:last-child {
      flex-basis: 66.66%;

      > div {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;

        > div {
          flex-basis: calc(50% - 3.33vw);
        }
      }
    }
  }

  ._flag {
    background: #000;
    color: #fff;
    display: inline-block;
    font-size: 1.6vh;
    font-weight: bold;
    margin-right: 0.5vw;
    padding: 0.25vh 0.3vw;
    text-transform: uppercase;
  }

  ._boxes p,
  ._note {
    color: #777777;
    font-size: 1.4vh;
    line-height: 1.7vh;
    margin-right: 8vw;
    margin-top: 2vh;
  }

  ._note {
    width: 66%;
  }

  &._has-video {
    padding: 3.33vw 3.33vw 0;

    ._boxes {
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;
      margin-right: 0;
    }

    ._video {
      overflow: hidden;
      max-height: 85vh;
      max-width: 100%;

      video {
        width: 100%;
      }
    }
  }
}

._tv-login {
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  color: #fff;
  font-size: 2.78vh;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1;

  @include align-items(center);
  @include display-flex();
  @include justify-content(center);

  ._box {
    @include display-flex();
    @include flex-direction(column);

    background: #fff;
    border-radius: 1vw;
    color: #000;
    min-width: 30vw;
    padding: 3.7vh;
    text-align: left;

    > * {
      margin: 0.93vh 0;
    }

    img {
      margin: 0 auto 1.85vh;
      width: 5.2vw;
    }

    label {
      font-weight: bold;
    }

    input[type="text"] {
      border: 0.09vh solid #e6e6e6;
      border-radius: 0.75vh;
      font: inherit;
      padding: 1.85vh;
    }

    button {
      background: #000;
      border: 0;
      border-radius: 4.6vh;
      color: #fff;
      font-family: inherit;
      font-size: 2.4vh;
      font-weight: bold;
      padding: 1.85vh;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      .loader {
        border-top-color: #aaa;
        border-width: 0.42vw;
        height: 1.85vh;
        position: absolute;
        right: 1.04vw;
        top: 1.48vh;
        width: 1.85vh;
      }
    }
  }
}

//tv -> cookie q
._cookie-q {
  background: #e6e6e6;
  min-height: 100vh;

  ._header {
    background: #000;
    color: #fff;
    font-size: 3.7vh;
    line-height: 4.5vh;
    padding: 2.4vh 2.1vw;
    text-transform: uppercase;

    @include align-items(center);
    @include display-flex();
    @include justify-content(space-between);

    b {
      font-weight: bold;
    }
  }

  ._items {
    @include display-flex();
    padding: 2.96vh 2.86vw 0;

    > div {
      @include display-flex();
      @include flex-direction(column);
    }

    ._box {
      @include display-flex();
      @include flex-direction(row);
      @include flex-grow(1);
      box-sizing: border-box;
      margin: 0 0.5vw;
      overflow: hidden;
      padding: 2.78vh 1.04vw;
      text-align: center;
    }

    ._image {
      margin-bottom: 0.93vh;
      position: relative;

      img {
        margin: 0 1vh;
        width: 20vh;
      }
    }

    ._details {
      @include display-flex();
      @include flex-direction(column);
      @include flex-grow(1);
      @include justify-content(space-around);
      font: 1.85vh $mediumFont;
      line-height: 2.2vh;
      overflow-wrap: break-word;
      position: relative;
      left: -1vh;

      b {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);
        font-size: 7.87vh;
        line-height: 9.53vh;
        margin-top: 0.46vh;
      }
    }

    ._soldOut {
      background: rgba(255, 255, 255, 0.5);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      text-transform: uppercase;
      top: 0;

      &,
      > div {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);
      }

      > div {
        @include flex-direction(column);
        background: #db4156;
        border-radius: 100%;
        box-sizing: border-box;
        font: 1.48vh $semiBoldFont;
        height: 10vh;
        padding: 0 2vh;
        color: #fff;
        width: 10vh;

        b {
          font-size: 3.7vh;
        }
      }
    }
  }

  ._box {
    background: #fff;
    border-radius: 1vw;
    box-shadow: 0px 0.27vh 0.74vh #00000026;
  }

  ._graph-container {
    @include display-flex();
    @include justify-content(space-between);
    padding: 2.96vh 2.86vw;

    ._box {
      padding: 3.7vh 2vw;

      &:not(._debug) {
        @include flex-grow(1);
      }
    }

    ._title {
      @include justify-content(space-between);
      @include display-flex();
      font-size: 2.78vh;
      text-transform: uppercase;
    }

    ._legend {
      font-size: 1.48vh;
      font-weight: bold;
      line-height: 1.76vh;

      &,
      > div {
        @include align-items(center);
        @include display-flex();
      }

      span {
        background: #000;
        border-radius: 0.42vw;
        height: 1.67vh;
        margin-right: 0.52vw;
        width: 1.35vw;
      }

      > div:first-child {
        margin-right: 1.56vw;

        span {
          background-color: #aaa;
        }
      }
    }

    ._chart {
      height: inherit;
      margin-top: 2vh;
      position: relative;

      ._grids {
        bottom: 0;
        height: inherit;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(space-between);

        > * {
          @include align-items(flex-end);
          @include display-flex();
          > div {
            background: #e7e7e7;
            height: 0.09vh;
            @include flex-grow(1);
          }

          span {
            background: #fff;
            box-sizing: border-box;
            color: #777;
            font-size: 1.9vh;
            font-weight: bold;
            margin-bottom: -0.9vh;
            padding-right: 1vh;
            position: relative;
            text-align: right;
            width: 4vw;
          }
        }
      }

      ._data {
        height: inherit;
        margin-left: 4vw;
        @include display-flex();
        @include justify-content(space-between);

        > * {
          height: inherit;
          width: 4%;

          ._bars {
            height: inherit;
            padding: 0 0.1vw;
            @include align-items(flex-end);
            @include display-flex();
            @include justify-content(center);

            > div {
              @include align-items(center);
              @include display-flex();
              @include flex-direction(column);
              @include justify-content(flex-end);

              position: relative;
              margin: 0 2.5%;
              height: inherit;
              width: 80%;

              ._bar {
                border-radius: 0.2vw;
                height: inherit;
                width: 100%;
              }

              ._bar.gray {
                background: #aaa;
              }
              ._bar.black {
                background: #000;
              }
            }
          }

          small {
            color: #aaa;
            display: block;
            font-size: 1.5vh;
            font-weight: bold;
            margin-top: 1vh;
            text-align: center;
          }

          &._highlight {
            background: #fff;
            position: relative;
            z-index: 1;

            span {
              font-size: 1.25vh;
              font-weight: bold;
              margin: -1vh 0 0.5vh;
            }

            &:before {
              background: #fff;
              border: 0.1vw solid #000;
              border-radius: 0.5vw;
              bottom: -4.5vh;
              box-shadow: 0px 3px 8px #00000026;
              content: "";
              display: block;
              left: -0.5vw;
              position: absolute;
              right: -0.5vw;
              top: -2vh;
            }
            small {
              color: #000;
              position: relative;
            }
          }
        }
      }
    }

    ._debug {
      box-sizing: border-box;
      margin-left: 10px;
      width: 450px;

      @media screen and (min-width: 1921px) {
        width: 16vw;
      }

      p {
        font-size: 1.9vh;
        line-height: 2.3vh;
        margin: 1vh 0;
      }
    }
  }
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.25;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.25;
  }
}

._blinky {
  -webkit-animation: blink-1 2s infinite both;
  animation: blink-1 2s infinite both;
}
