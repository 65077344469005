.store-page {
  text-align: center;
  @include page-title;

  .btns {
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
    margin-bottom: 1em;

    .btn {
      padding: 1em 2em;
    }

    > *:first-child {
      margin-right: 0.5em;
    }

    > *:last-child {
      margin-left: 0.5em;
    }
  }
  .store-details {
    padding: 1em 0;

    h5 {
      font-family: inherit;
      font-size: 1.15em;
      font-weight: bold;
    }

    p {
      line-height: 1.15em;
      margin: 1em 0;
    }

    @media screen and (min-width: 768px) {
      @include display-flex();
      @include justify-content(space-between);

      > * {
        @include flex-grow(1);
      }
    }
  }

  .middle-page {
    box-sizing: border-box;
    padding: 0 1em;
  }

  .store-delivery-hours span {
    display: inline-block;
    width: 120px;
  }
}

.onlyfor-desktop,
.onlyfor-mobile {
  display: none;
}

@media screen and (min-width: 768px) {
  .onlyfor-desktop {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .onlyfor-mobile {
    display: block;
  }
}

.stores {
  h2 {
    font-size: 2em;

    @media screen and (min-width: 768px) {
      font-size: 4em;
    }
  }
}

._store-page-width {
  margin: 0 auto;
  max-width: 1280px;

  @include for-tablet {
    padding: 0 40px;
    max-width: unset;
  }

  @include for-phone {
    padding: 0 20px;
  }
}

body._store-page {
  .middle-page {
    max-width: unset;
  }

  ._hero {
    margin: 50px auto 0;
    text-align: center;

    ._logo {
      margin: 80px 0;

      img {
        height: 75px;
      }

      @include for-tablet {
        margin: 60px 0;
      }

      @include for-phone {
        margin: 40px 0;

        img {
          height: 45px;
        }
      }
    }

    ._cookies {
      background: url(/images/cookies.png) no-repeat 50% 0;
      background-size: cover;
      height: 600px;

      @include for-tablet {
        background-position: 0 50%;
        height: 380px;
      }

      @include for-phone {
        background-position: 10% 0;
        height: 340px;
      }
    }

    h1 {
      font-size: 100px;
      font-weight: bold;
      margin-bottom: 16px;

      span {
        display: block;
        font-size: 0.5em;
      }

      @include for-tablet {
        font-size: 55px;
        margin-bottom: 0;
      }

      @include for-phone {
        font-size: 36px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 30px;
      line-height: 40px;
      margin: 0 auto;
      @extend ._store-page-width;

      @include for-tablet {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 60px;
        max-width: 650px;
      }

      @include for-phone {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  h2 {
    font-size: 60px;
    font-weight: bold;
    line-height: 115px;
    text-align: center;

    @include for-tablet {
      font-size: 40px;
      line-height: 49px;
    }
  }

  h3 {
    font-size: 40px;
    font-weight: bold;
    text-align: center;

    @include for-tablet {
      font-size: 30px;
    }

    @include for-phone {
      font-size: 24px;
    }
  }

  @include non-phone {
    ._three-columns,
    ._two-columns {
      @include display-flex();
      @include justify-content(space-between);
    }

    ._three-columns > div {
      @include flex(0 0 30%);
    }
  }

  ._events {
    padding: 40px 0 50px;

    h2 {
      line-height: 72px;
      margin: 20px 20px 0 0;
      text-align: left;

      @include for-tablet {
        line-height: 50px;
        margin-right: 10px;
      }

      @include for-phone {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 20px;
        margin-right: 0;
        text-align: center;
      }
    }

    > div {
      @extend ._store-page-width;
    }

    p {
      font-family: $mediumFont;
      font-size: 30px;
      line-height: 37px;

      @include for-tablet {
        font-size: 24px;
        line-height: 29px;
        margin-top: 20px;
      }

      @include for-phone {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    ._event-details {
      @include flex-shrink(0);

      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 8px #00000026;
      box-sizing: border-box;
      font-size: 18px;
      line-height: 28px;
      margin-left: 20px;
      padding: 32px 48px;
      width: 388px;

      @include for-tablet {
        margin-left: 30px;
        padding: 32px 28px;
        width: 367px;
      }

      @include for-phone {
        font-size: 16px;
        margin-left: 0;
        width: auto;
      }

      b {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;

        &:not(:first-child) {
          margin-top: 10px;
        }

        @include for-phone {
          font-size: 14px;
          margin-bottom: 0;
        }
      }

      ._button {
        @include align-items(center);
        @include display-flex();
        box-sizing: border-box;
        margin: 30px auto 0;

        &:hover {
          color: #fff;
        }

        img {
          margin-right: 10px;
          width: 24px;
        }

        @include for-phone {
          @include justify-content(center);
          font-size: 12px;
          padding: 12px;
          width: 100%;

          img {
            width: 20px;
          }
        }
      }

      small {
        color: #aaa;
        display: block;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;

        @include for-phone {
          font-size: 12px;
        }
      }
    }
  }

  ._location {
    font-size: 24px;
    line-height: 28px;
    padding: 40px 0 80px;

    @include for-tablet {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 60px;
    }

    @include for-phone {
      padding-bottom: 30px;
    }

    h2 {
      margin-bottom: 60px;

      @include for-phone {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 32px;
      }
    }

    > ._two-columns {
      @include justify-content(center);
      @extend ._store-page-width;
    }

    ._map-container {
      @include align-items(center);
      @include display-flex();
      @include flex-direction(column);
      @include flex(0 0 50%);

      @include for-tablet {
        @include align-items(flex-start);
      }

      @include for-phone {
        @include align-items(center);
      }

      ._map {
        background: #333;
        border-radius: 50%;
        height: 462px;
        overflow: hidden;
        margin-bottom: 40px;
        position: relative;
        width: 462px;

        #map {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }

        @include for-tablet {
          height: 288px;
          width: 288px;
        }

        @include for-phone {
          height: 212px;
          width: 212px;
        }
      }

      ._links {
        &,
        > * {
          @include align-items(center);
          @include display-flex();
          @include justify-content(center);
        }

        > * {
          @include flex-direction(column);
          padding: 0 0 0 40px;

          &:first-child {
            border-right: 1px solid #aaa;
            padding: 0 40px 0 0;
          }
        }

        img {
          margin-bottom: 10px;
        }

        @include for-phone {
          margin-bottom: 30px;
        }
      }
    }

    ._location-details {
      margin-bottom: 40px;

      @include for-tablet {
        margin-bottom: 14px;
      }

      @include for-phone {
        @include display-flex();
        @include justify-content(space-between);

        > div {
          @include flex(0 0 48%);
        }
      }

      > div {
        @include flex(0 0 48%);
      }

      b {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
        text-transform: uppercase;

        @include for-tablet {
          font-size: 14px;
          margin-bottom: 6px;
        }
      }

      &._store-hours {
        @include for-phone {
          display: block;
        }

        > div > div {
          @include display-flex();
          padding-bottom: 10px;

          > :first-child {
            @include flex(0 0 180px);

            @include for-tablet {
              @include flex(0 0 120px);
            }
          }
        }
      }
    }

    ._white-button {
      @include align-items(center);
      @include display-flex();
      border-radius: 8px;
      box-shadow: 0px 3px 8px #00000026;
      font-size: 24px;
      margin-bottom: 20px;
      padding: 20px 30px;

      @include for-tablet {
        font-size: 16px;
        margin-bottom: 10px;
        padding: 20px;
      }

      ._icon {
        @include flex-shrink(0);
        background: no-repeat 50% 50%;
        height: 60px;
        margin-right: 20px;
        width: 60px;

        @include for-tablet {
          background-size: 65%;
          height: 46px;
          margin-right: 16px;
          width: 46px;
        }

        &._pickup-icon {
          background-image: url(/images/grey-directions-icon.svg);
        }

        &._delivery-icon {
          background-image: url(/images/grey-order-icon.svg);
        }

        &._catering-icon {
          background-image: url(/images/grey-catering-icon.svg);
        }
      }

      b {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 5px;
        display: block;

        @include for-tablet {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 0;
        }

        @include for-phone {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  ._socials {
    background: #000;
    color: #aaa;
    font-size: 18px;
    padding: 60px 0;

    h3 {
      color: #fff;
      font-size: 45px;
      margin-bottom: 10px;
      text-align: left;
    }

    @include for-phone {
      font-size: 16px;
      padding: 20px 0;

      h3 {
        font-size: 24px;
        margin-bottom: 2px;
      }
    }

    ._links,
    ._store-page-width {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
    }

    ._links {
      font-size: 12px;
      text-transform: uppercase;

      > * {
        margin-left: 40px;
      }

      img {
        display: block;
        margin: 0 auto 16px;
      }

      @include for-tablet {
        img {
          height: 30px;
          margin-bottom: 0;
        }

        span {
          display: none;
        }
      }
    }

    @include for-phone {
      ._store-page-width {
        display: block;
      }

      ._links > * {
        margin: 20px 40px 0 0;
      }
    }
  }

  ._rotating-menu {
    padding-bottom: 50px;
    padding-top: 40px;
    @extend ._store-page-width;

    @include for-tablet {
      padding-top: 30px;
    }

    > * {
      @include flex(0 0 48%);
    }

    h2 {
      line-height: 72px;
      margin-bottom: 30px;
      text-align: left;

      @include for-tablet {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
      }

      @include for-phone {
        font-size: 24px;
        line-height: 28px;
      }
    }

    p {
      font-size: 18px;
      line-height: 28px;

      @include for-tablet {
        font-size: 16px;
        line-height: 24px;
      }

      @include for-phone {
        margin-bottom: 20px;
      }
    }

    ._week {
      background: #000;
      color: #fff;
      display: inline-block;
      font-size: 30px;
      font-weight: bold;
      line-height: 37px;
      margin-bottom: 45px;
      padding: 14px 30px;

      @include for-tablet {
        font-size: 18px;
        line-height: 22px;
        padding: 8px 20px;
      }

      @include for-phone {
        margin-bottom: 20px;
      }
    }

    ._two-columns {
      @include flex-wrap(wrap);

      > * {
        @include flex(0 0 48%);
      }

      @include for-phone {
        @include display-flex();
        @include justify-content(space-between);
      }

      > div {
        @include align-items(center);
        @include display-flex();
        font-family: $mediumFont;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
        text-transform: lowercase;

        img {
          margin-right: 20px;
          width: 40px;

          @include for-tablet {
            margin-right: 6px;
            width: 22px;
          }
        }

        @include for-tablet {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 18px;
        }
      }
    }
  }

  ._testimonials {
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    > div > div {
      margin: 0 1.66%;
      position: relative;

      &:before {
        color: #e8e8e8;
        content: "“";
        display: block;
        font-size: 160px;
        font-weight: bold;
        left: -10px;
        position: absolute;
        top: 40px;
      }

      p,
      b {
        position: relative;
      }
    }

    b {
      display: block;
      margin-top: 10px;
    }

    @include for-tablet {
      font-size: 16px;
      line-height: 28px;
    }

    @include for-phone {
      ._three-columns > * {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 40px 0;

      @include for-phone {
        margin: 30px 0;
      }
    }
  }

  ._reviews {
    border: 1px solid #aaa;
    border-width: 1px 0;
    margin: 60px auto;
    padding: 40px 0;

    &,
    > * {
      @include align-items(flex-end);
      @include display-flex();
      @include justify-content(center);
    }

    > * {
      @include align-items(center);
      @include flex-direction(column);
      padding: 0 80px;

      @include for-phone {
        padding: 0 40px;

        img {
          max-height: 25px;
        }
      }

      > *:not(:last-child) {
        margin-bottom: 16px;

        @include for-phone {
          margin-bottom: 8px;
        }
      }
    }

    @include for-phone {
      display: block;
      font-size: 10px;
      margin: 60px 20px;

      > *:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    ._stars {
      @include align-items(flex-end);
      @include display-flex();
      > * {
        margin: 0 2px;

        @include for-phone {
          height: 16px;
          margin: 0 0.5px;
        }
      }
    }
  }

  ._awards {
    padding-bottom: 100px;
    text-align: center;

    ._award {
      @include for-tablet {
        @include flex(0 0 33%);

        img {
          max-height: 144px;
        }
      }

      @include for-phone {
        padding: 0 50px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    h3 {
      margin-bottom: 40px;
    }

    img {
      margin-bottom: 14px;
    }

    small {
      font-size: 16px;
      line-height: 28px;
      text-transform: uppercase;

      @include for-tablet {
        font-size: 14px;
      }
    }

    b {
      display: block;
      font-size: 30px;
      line-height: 40px;

      @include for-tablet {
        font-size: 28px;
        line-height: 38px;
      }

      @include for-phone {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  ._app {
    ._two-columns {
      @extend ._store-page-width;
      @include align-items(stretch);
      max-width: 1024px;

      @include for-phone {
        @include display-flex();
        padding: 0;
      }
    }

    ._download-app {
      padding: 120px 0 20px;

      h2 {
        line-height: 75px;
        margin-bottom: 30px;
        text-align: left;

        @include for-tablet {
          font-size: 30px;
          line-height: 35px;
        }

        @include for-phone {
          font-size: 24px;
          line-height: 29px;
        }
      }

      @include for-phone {
        padding-top: 50px;
      }
    }

    ._app-image {
      @include flex-shrink(0);
      background: url("/images/app-front.png") no-repeat 100% 0;
      background-size: cover;
      margin-top: -46px;
      margin-right: 64px;
      width: 315px;

      @include for-x2 {
        background-image: url(/images/app-front@2x.png);
      }

      @include for-tablet {
        width: 210px;
      }

      @include for-phone {
        margin-right: 20px;
        width: 145px;
      }
    }

    ._qr {
      @include align-items(center);
      @include display-flex();

      @include for-phone {
        display: none;
      }

      img {
        margin-right: 20px;
        width: 72px;

        @include for-tablet {
          width: 72px;
        }
      }

      p {
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        max-width: 300px;

        @include for-tablet {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }

    ._mobile-apps {
      margin-right: 20px;

      @include non-phone {
        display: none;
      }

      ._button {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);
        background: #fff;
        box-sizing: border-box;
        color: #000;
        filter: invert(1);
        font-family: $regularFont;
        font-size: 11px;
        font-weight: 400;
        margin-bottom: 20px;
        padding: 12px;
        text-transform: unset;
        width: 100%;

        img {
          margin-right: 6px;
          width: 16px;
        }
      }
    }
  }

  ._marketing-description {
    line-height: 1.15;
    margin-bottom: 60px;

    strong,
    b {
      font-weight: bold;
    }

    span {
      font-family: inherit !important;
      font-size: inherit !important;
    }
  }
}

._feature-video {
  background: #000;
  max-height: 787px;
  overflow: hidden;
  text-align: center;
  width: 100%;

  video {
    width: 100%;
  }
}

._kitchen-video {
  position: relative;

  video {
    display: block;
    width: 100%;
  }

  > div {
    @include align-items(center);
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(center);
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-size: 30px;
    left: 0;
    line-height: 35px;
    right: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity 3s;

    p {
      margin-bottom: 40px;
    }

    @include for-tablet {
      font-size: 20px;

      h2 {
        font-size: 36px;
      }
      img {
        width: 70px;
      }
    }

    @include for-phone {
      font-size: 16px;

      p {
        line-height: 21px;
        padding: 0 34px;
      }

      h2 {
        font-size: 24px;
      }
    }
  }

  &._playing > div {
    opacity: 0;
  }
}

.outlined-text {
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}
.event-background {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
@include for-phone {
  .prizes-box {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  }
}

section.gallery {
  width: 100vw;
  overflow: hidden;
  position: relative;
  --v-offset: 60px;
  --curve-height: 120px;

  &:before,
  &:after {
    content: "";
    display: block;
    background: white;
    width: calc(100vw + 2 * var(--v-offset));
    height: var(--curve-height);
    position: absolute;
    border-radius: 50%;
    left: calc(-1 * var(--v-offset));
    right: calc(-1 * var(--v-offset));
  }

  &:before {
    top: calc(-0.6 * var(--curve-height));
  }
  &:after {
    bottom: calc(-0.6 * var(--curve-height));
  }

  .wrapper {
    display: grid;
    grid-template-rows: 500px;
    grid-auto-flow: column;
    grid-gap: 24px;
    overflow: auto;
    scroll-snap-type: x mandatory;
    img {
      scroll-snap-align: center;
      max-width: 500px;
    }

    @include for-phone {
      grid-template-rows: 300px;

      img {
        max-width: 300px;
      }
    }
  }
}
