$stripe1: black;
$stripe2: #1f1f1f;
$striped-background: repeating-linear-gradient(45deg, $stripe1, $stripe1 4px, $stripe2 4px, $stripe2 8px);
$slow-transition: width 0.75s ease-out;

._order-confirmation-modal {
  .mapboxgl-map {
    // This height is tied to the calculations in DeliveryTracker/helpers.js for zoom level, updates here need to be done there as well
    height: 425px;
  }

  .map-marker {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
  }

  #delivery-tracker {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    margin-bottom: 16px;

    .icon {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: none;
      background-color: #ebebeb;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      svg.white {
        fill: white;

        path {
          fill: white;
        }
      }

      svg.black {
        fill: black;

        path {
          fill: black;
        }
      }

      &.active {
        background: $striped-background;
        background-size: 200% 200%;
      }
    }

    &:before {
      content: "";
      background-color: #ebebeb;
      display: block;
      height: 20px;
      width: 100%;
      position: absolute;
      z-index: -1;
      border-radius: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .progress-bar {
      background: $striped-background;
      background-size: 200% 200%;
      display: block;
      height: 12px;
      position: absolute;
      z-index: -1;
      border-radius: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      -webkit-animation: background-shift 16s linear infinite;
      -moz-animation: background-shift 16s linear infinite;
      animation: background-shift 16s linear infinite;

      transition: $slow-transition;
      -webkit-transition: $slow-transition;
      -moz-transition: $slow-transition;
      -ms-transition: $slow-transition;
      -o-transition: $slow-transition;
    }
  }
}
