.store-info {
  margin-bottom: 8px;
  font-family: $regularFont;

  @include display-flex();
  @include align-items(flex-start);

  > img {
    margin-right: 8px;
    width: 24px;
  }

  i {
    display: inline-block;
    font-weight: bold;
    opacity: 0.5;
    margin-top: 3px;
    text-transform: uppercase;
  }
}
.contact {
  h1,
  h2 {
    font-family: "Avenir", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
      "Lucida Grande", sans-serif;
    font-size: 2em;
    font-weight: bold;
    line-height: 1em;
    margin: 40px auto 0 auto;
    text-transform: lowercase;
    text-align: center;
    display: inline-block;
    @media screen and (max-width: 401px) {
      font-size: 1.5em;
    }
  }

  padding: 0 20px;
  color: #4a4a4a;
  font-size: 1.3rem;
  line-height: 1.625;
  font-family: "Avenir", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  p {
    margin: 0 0 20px;
  }

  .pink-bar {
    background: #ffb3c5;
    content: "";
    display: block;
    height: 5px;
    margin: 0.5em 0;
    width: 100px;
  }

  span {
    color: #de7b91;
  }

  h1 {
    font-size: 3rem;
    line-height: 1.225;
    font-family: Baskerville MT, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
    font-weight: 400;
    margin: 20px 0;
    text-align: center;
  }
}

.contact-page.items-cnt .items > div {
  text-align: center;

  .onlyfor-mobile .btn {
    border-radius: 25px;
    background-color: #000;
    width: inherit;
    margin-top: 10px;
  }
}

@media screen and (max-width: 766px) {
  .contact-page.items-cnt .items {
    display: block;
  }
}

@media screen and (min-width: 767px) {
  .contact-page.items-cnt .items {
    @include justify-content(space-between);

    > div {
      @include flex(0 0 30%);
    }
  }
}

._support-page {
  ._static-page-hero {
    @include non-phone {
      background: url(/images/customer-support-image.png) no-repeat 100% 50%;
      background-size: contain;
      margin-top: -88px;
      padding: 200px 0 60px;

      @include for-x2 {
        background-image: url(/images/customer-support-image@2x.png);
      }
    }

    @include for-phone {
      h1,
      h2 {
        font-size: 40px;
      }

      img {
        margin: 0 -20px 30px;
        max-width: 100vw;
      }
    }

    ._button {
      display: block;
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 50px;
      width: 80%;

      @include non-phone {
        max-width: 60%;
      }

      &:hover {
        color: #fff;
      }
    }

    p a {
      text-decoration: underline;
    }
  }
}

._support-info {
  font-size: 18px;

  @include non-phone {
    @include display-flex();
    @include justify-content(center);
  }

  > * {
    @include align-items(flex-start);
    @include display-flex();
    margin: 40px 0;
    padding: 0 40px;

    &:first-child {
      border: 1px solid #aaaaaa;
      border-width: 0 1px 0 0;

      @include for-phone {
        border-width: 1px 0 0;
        margin-top: 10px;
      }
    }

    @include for-phone {
      margin: 0;
      padding: 25px;
    }

    > div > div {
      @include non-phone {
        @include display-flex();
      }

      line-height: 30px;

      > * {
        margin-right: 20px;
      }

      b {
        display: block;
      }
    }
  }

  ._icon {
    margin: 4px 20px 0 0;
  }

  h4 {
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
    margin-bottom: 10px;

    @include for-phone {
      font-size: 24px;
      line-height: 29px;
    }
  }

  a {
    font-weight: bold;

    img {
      margin-left: 10px;
    }
  }
}
