$pagewidth2: 1020px;

._site.catering-page {
  font-family: $regularFont;

  ._page-width {
    padding-bottom: 0;
    padding-top: 0;
  }

  ._call-to-action {
    margin-top: 50px;

    ._button {
      display: inline-block;

      &:hover {
        color: #fff;
      }
    }

    > div {
      margin-top: 30px;

      @include for-phone {
        margin-top: 15px;
      }

      &,
      > a {
        @include display-flex();
        @include align-items(center);
      }

      a {
        font-family: $boldFont;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;

        @include for-tablet {
          font-size: 14px;
          line-height: 18px;
        }

        @include for-phone {
          font-size: 9px;
          line-height: 11px;
        }

        img {
          height: 42px;
          margin-right: 10px;

          @include for-tablet {
            height: 38px;
          }

          @include for-tablet {
            height: 26px;
          }
        }

        &:not(:last-child) {
          border-right: 1px solid #aaa;
          margin-right: 30px;
          padding-right: 30px;
        }
      }
    }
  }

  ._section-title {
    font-size: 44px;
    font-weight: bold;
    line-height: 75px;
    text-align: center;

    @include for-tablet {
      font-size: 40px;
      line-height: 60px;
    }

    @include for-phone {
      font-size: 36px;
      line-height: 44px;
    }
  }

  ._grey-bg {
    background: #efefef;
  }

  ._box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 8px #00000026;
    box-sizing: border-box;
  }

  ._how-it-works {
    font-size: 18px;
    line-height: 28px;

    @include for-tablet {
      font-size: 16px;
      line-height: 20px;
    }

    @include for-tablet {
      font-size: 14px;
    }

    h5 {
      font-size: 36px;
      font-weight: bold;
      line-height: 58px;
      margin-bottom: 10px;
      text-align: center;

      @include for-tablet {
        font-size: 30px;
        line-height: 37px;
      }

      @include for-phone {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 5px;
      }
    }

    h6 {
      font-size: 32px;
      font-weight: bold;
      line-height: 44px;
      margin-bottom: 5px;

      @include for-tablet {
        font-size: 20px;
        line-height: 24px;
      }
    }

    ._select-date {
      @include align-items(center);
      @include display-flex();

      ._page-width {
        max-width: $pagewidth2;
      }

      h5 {
        text-align: left;
      }

      ._date-selector-image {
        @include flex(0 0 320px);
        margin-bottom: -80px;
        margin-right: 28px;
        width: 320px;

        @include for-tablet {
          @include flex(0 0 190px);
          width: 190px;
        }

        @include for-phone {
          @include flex(0 0 149px);
          margin: 0 20px -40px -40px;
          width: 140px;
        }
      }
    }

    ._select-addons,
    ._select-size,
    ._select-flavors {
      padding: 80px 0 40px;
      text-align: center;

      @include for-tablet {
        padding-top: 40px;
      }
    }

    ._select-size {
      ._page-width {
        max-width: $pagewidth2;
      }

      small {
        display: block;
        font-size: 16px;
        margin-top: 60px;
        text-align: center;

        @include for-phone {
          margin: 22px 40px 0;
        }
      }

      ._cookie-sizes {
        @include align-items(flex-end);
        @include display-flex();
        margin-top: 40px;

        > span {
          font-size: 24px;
          font-weight: bold;
          line-height: 29px;
          margin-bottom: 64px;
        }
      }
    }

    ._select-flavors {
      small {
        color: #aaaaaa;

        @include for-phone {
          font-size: 12px;
        }
      }

      h6 {
        margin-top: 50px;
      }

      p {
        margin: 0 auto;
        max-width: 700px;

        @include for-phone {
          max-width: 70%;
        }
      }

      ul {
        display: grid;
        font-size: 24px;
        line-height: 35px;
        grid-template-columns: 33.33% 33.33% 33.33%;

        @include for-phone {
          font-size: 14px;
          line-height: 18px;
          grid-template-columns: 50% 50%;
        }

        li > div {
          margin: 30px;

          @include for-phone {
            margin: 10px;
          }
        }

        img {
          display: block;
          margin: 0 auto 12px;
        }
      }
    }

    ._select-addons {
      ._page-width {
        @include display-flex();
        @include justify-content(space-between);
        margin: 40px auto;

        @include for-phone {
          margin-top: 30px;
          display: block;

          > * {
            margin-bottom: 10px;
          }
        }

        > * {
          @include flex(0 0 48%);
          padding: 40px;

          @include for-tablet {
            padding: 30px 20px;
          }
        }
      }

      @include non-phone {
        ._image-container {
          @include align-items(center);
          @include display-flex();
          height: 300px;
          margin: 30px auto;
        }
      }

      ._options {
        margin: 0 auto;
        max-width: 340px;
      }

      small {
        font-size: 16px;
      }
    }

    ._select-payment {
      padding: 80px 0 60px;
      text-align: center;

      @include for-tablet {
        padding: 40px;
      }

      @include for-phone {
        padding: 40px 20px;
      }

      ul {
        font-family: $semiBoldFont;
        font-size: 20px;
        line-height: 24px;
        margin-top: 40px;

        @include display-flex();
        @include justify-content(center);

        @include for-tablet {
          font-size: 15px;
          line-height: 19px;
        }

        @include for-phone {
          @include flex-wrap(wrap);
        }

        > * {
          @include display-flex();
          @include flex(0 0 20%);
          @include flex-direction(column);
          @include justify-content(space-between);

          @include for-phone {
            @include flex(0 0 40%);
            @include justify-content(center);
          }

          img {
            margin-bottom: 20px;
          }

          small {
            color: #595959;
            font-family: $regularFont;

            @include for-tablet {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  ._bottom-cta {
    padding: 170px 0;

    @include for-phone {
      padding: 60px 30px;
    }

    ._box {
      margin: 0 auto;
      max-width: 630px;
      padding: 60px 90px;
      text-align: center;

      @include for-phone {
        padding: 30px 10px;

        ._section-title {
          font-size: 24px;
        }
      }
    }

    p {
      font-size: 30px;
      margin-bottom: 30px;

      @include for-phone {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    ._call-to-action {
      > div {
        @include justify-content(center);
      }

      @include for-phone {
        margin-top: 0;
      }
    }
  }

  ._gallery {
    padding: 80px 0;

    @include for-phone {
      padding: 40px 0;
    }

    ._section-title {
      margin-bottom: 40px;

      @include for-phone {
        margin-bottom: 30px;
      }
    }

    ul {
      @include display-flex();
      @include flex-wrap(wrap);
      @include justify-content(space-between);

      > * {
        @include flex(0 0 31%);
        margin-bottom: 30px;

        @include for-phone {
          @include flex(0 0 48%);
          margin-bottom: 10px;
        }
      }
    }

    small {
      display: block;
      font-size: 24px;
      text-align: center;

      a {
        border-bottom: 1px solid #000;
        font-weight: bold;
      }

      @include for-phone {
        font-size: 14px;
      }
    }
  }

  ._faq-section {
    padding: 80px 0 120px;

    @include for-tablet {
      padding: 40px 0 60px;
    }

    @include for-phone {
      padding-top: 30px;
    }

    ._page-width {
      max-width: $pagewidth2;
    }

    ._section-title {
      margin-bottom: 40px;
    }

    ul li {
      background: #fff;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      padding: 20px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      b {
        font-size: 24px;
        line-height: 29px;

        @include for-phone {
          font-size: 18px;
          line-height: 22px;
        }
      }

      div {
        @include display-flex();

        > :first-child {
          @include flex(0 0 60px);
          text-align: center;
        }

        &:last-child {
          padding-top: 0;
        }

        &:not(:last-child) {
          padding-bottom: 0;
        }
      }
    }
  }

  ._app-download {
    padding-top: 120px;

    @include for-phone {
      padding-top: 50px;
    }

    ._section-title {
      text-align: left;

      @include for-tablet {
        font-size: 30px;
        line-height: 35px;
      }

      @include for-phone {
        font-size: 24px;
        line-height: 29px;
      }
    }

    > div {
      @include align-items(flex-end);
      @include display-flex();

      > img {
        margin: -160px 60px 0 0;

        @include for-tablet {
          margin-right: 50px;
          width: 200px;
        }

        @include for-phone {
          margin: -80px 18px 0 -60px;
        }
      }

      ._download-buttons {
        @include non-phone {
          display: none;
        }

        filter: invert(1);
        margin: 20px 0;

        .btn {
          @include align-items(center);
          @include display-flex();
          @include justify-content(center);
          font-size: 13px;
          margin-bottom: 20px;

          img {
            margin-right: 4px;
          }
        }
      }

      ._qr-container {
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        margin-top: 30px;
        max-width: 500px;
        @include display-flex();

        @include for-phone {
          display: none;
        }

        @include for-tablet {
          font-size: 24px;
          line-height: 29px;
          margin-top: 45px;
        }

        > img {
          margin-bottom: 60px;
          margin-right: 20px;
          max-width: 120px;

          @include for-tablet {
            width: 72px;
          }
        }
      }
    }
  }
}

._select-flavors ._stores-typeahead {
  margin: 20px auto;
  max-width: 500px;
}

._stores-typeahead {
  position: relative;

  ._input-container {
    position: relative;

    input {
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px 20px;
      text-align: center;
      width: 100%;
    }

    .loader {
      border-color: rgba(0, 0, 0, 0.15);
      border-top-color: #aaa;
      position: absolute;
      right: 10px;
      top: 22%;
    }
  }

  ._results {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: -1px;

    li {
      cursor: pointer;
      font-family: $semiBoldFont;
      padding: 10px 20px;
      text-align: left;
    }
  }
}
