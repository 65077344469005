.thank-you {
  text-align: center;
  padding-bottom: 20px;
  .big {
    padding-bottom: 8px;
    font-size: 1.6em;
  }

  .kinda-big {
    font-size: 1.5em;
  }

  .less-big {
    font-size: 1.1em;
    padding: 10px 0;
  }

  .small {
    margin: auto;
    padding: 10px;
    font-size: 0.7em;
    color: #aaa;
    max-width: 400px;
    line-height: 1.5em;
  }

  i {
    color: #000;
  }

  iframe {
    margin: 5px 0 10px 0;
    width: 100%;
    height: 500px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }

  .bottom-half {
    padding: 30px;
  }

  .delivery-window {
    border-top: 1px solid #e0e0e0;
    background: #f9f9f9;
    padding: 30px;
  }

  .zig-zag {
    background: linear-gradient(-20deg, #ffffff 16px, transparent 0),
      linear-gradient(20deg, #ffffff 16px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 64px 32px;
    content: " ";
    display: block;
    position: relative;
    width: 100%;
    height: 32px;
    top: -32px;
  }

  ul {
    max-width: 400px;
    margin: auto;
    padding: 10px 0;
    li {
      display: flex;
      padding: 5px 0;

      .on-the-left {
        flex: 1;
        align-self: flex-start;
        text-align: left;
        color: #555;
        font-size: 0.9em;
        text-transform: lowercase;
      }

      .on-the-right {
        align-self: flex-end;
        color: #555;
        font-weight: 700;
      }

      .biggie {
        font-size: 1.2em;
      }
    }
    .line {
      background: #e0e0e0;
      border: 0;
      height: 1px;
      margin: 10px 0;
      width: 100%;
    }
  }

  .btn {
    margin: 10px auto;
    max-width: 400px;
  }
}
