._site._franchising {
  overflow: hidden;
  width: 100vw;

  ._franchising-header {
    background: #fff;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
  ._page-width {
    padding: 0 20px;
    max-width: 1280px;
  }

  nav {
    font-size: 14px;
    padding: 16px 0;
    text-transform: uppercase;

    &,
    > div:not(._logo) {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
    }

    > div:not(._logo) {
      @include flex(0 0 40%);
    }

    ._button {
      padding-bottom: 12px;
      padding-top: 13px;
    }

    a:after {
      background: #000;
      border-radius: 2px;
      bottom: -10px;
      content: "";
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      right: 0;
      transition: transform 0.35s;
      transform: scale(0);
    }

    ._selected {
      font-weight: bold;
      position: relative;

      &:after {
        transform: scale(1);
      }
    }

    @include for-phone {
      > div:first-child,
      > div:last-child {
        a {
          display: none;
        }
      }

      > div:last-child {
        @include justify-content(flex-end);
      }

      button span {
        display: none;
      }
    }
  }

  h2 {
    font-size: 80px;
    font-weight: bold;
    line-height: 97px;
    text-transform: uppercase;

    @include for-phone {
      font-size: 36px;
      line-height: 44px;
    }
  }

  h3 {
    font-size: 45px;
    font-weight: bold;
    line-height: 55px;

    @include for-phone {
      font-size: 24px;
      line-height: 29px;
    }
  }

  ._hero {
    background: #fad0dc url(/images/franchising-hero.png) no-repeat 100% 50%;
    background-size: 1280px;
    height: 683px;
    margin-top: 85px; //header
    position: relative;

    @include for-x2 {
      background-image: url(/images/franchising-hero@2x.png);
    }

    @include for-phone {
      background-image: url(/images/franchising-hero-mobile@2x.png);
      background-size: contain;
      background-position: 100% 0;
      height: unset;
    }

    ._floating {
      position: absolute;
      right: 100px;
      top: 200px;

      @include for-phone {
        right: 0;
        top: 30px;
        width: 112px;
      }
    }

    p {
      font: 36px $mediumFont;
      line-height: 44px;

      @include for-phone {
        font: 16px $regularFont;
        line-height: 20px;
        margin-top: 80px;
      }
    }

    ._page-width {
      @include display-flex();
      box-sizing: border-box;
      height: inherit;
      padding: 96px 0 64px;

      > div {
        max-width: 50%;
        @include display-flex();
        @include flex-direction(column);
        @include flex-grow(1);
        @include justify-content(space-between);
      }

      @include for-phone {
        padding: 273px 20px 92px;

        > div {
          max-width: 280px;
        }
      }
    }
  }

  #the-crumbl-experience {
    ._page-width {
      padding: 60px 20px 30px;

      @include non-phone {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
        padding-bottom: 70px;
        padding-top: 70px;
      }

      p {
        font: 14px $mediumFont;
        line-height: 20px;
        margin: 20px 0 0 0;

        @include non-phone {
          font-size: 16px;
          margin: 0 0 0 180px;
        }
      }

      > div {
        @include flex(0 0 600px);
      }
    }
  }

  ._weekly-menu {
    > img {
      display: block;
      width: 100vw;
    }

    > ._page-width {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-top: 20px;
      max-width: 700px;

      @include for-phone {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
      }
    }

    @include for-phone {
      h3 {
        width: 246px;
      }
    }
  }

  ._tech,
  ._app {
    @include non-phone {
      ._page-width {
        @include align-items(center);
        @include display-flex();
        @include justify-content(space-between);
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      margin-top: 10px;

      @include for-phone {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  ._tech {
    background: #efefef;
    padding: 65px;

    @include for-phone {
      padding: 40px 0 25px;

      ._tech-screenshot {
        margin-bottom: 20px;
      }
    }

    @include non-phone {
      img {
        margin-right: 56px;
        max-width: 567px;
      }
    }

    ._tech-screenshot {
      @include flex-shrink(0);
      position: relative;
      transform: translateX(100vw);
      transition: transform 0.35s;

      &._show {
        transform: translateX(0);
      }
    }
  }

  ._app {
    padding: 100px 0 120px;

    h3 {
      margin-bottom: 20px;
    }

    ._screenshot {
      @include flex-shrink(0);
      height: 581px;
      margin: -142px 0 -120px 170px;
      transform: translateX(-100vw);
      transition: transform 0.35s;
      width: 317px;

      &._show {
        transform: translateX(0);
      }

      @include for-phone {
        height: 415px;
        margin: -400px 0 0 -100px;
        width: 220px;
      }
    }

    @include for-phone {
      padding: 40px 0 0;

      p,
      ul {
        margin-left: 140px;
      }

      ._page-width {
        overflow: hidden;
        position: relative;
      }

      ._screenshot {
        left: -80px;
        margin: 0;
        position: absolute;
        top: 100px;
      }
    }
  }

  ._testimonials {
    background: #efefef;
    padding: 60px 0 65px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    h3 {
      margin-bottom: 66px;
      text-align: center;

      @include for-phone {
        margin-bottom: 0;
      }
    }
  }

  ._title {
    p {
      line-height: 20px;

      @include for-phone {
        font-size: 14px;
        margin-top: 20px;
      }
    }

    @include non-phone {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);

      p {
        font-size: 16px;
        max-width: 40%;
      }
    }
  }

  ._stats {
    background: #000;
    padding: 98px 0 68px;

    ._title {
      color: #fff;
      margin-bottom: 98px;

      @include for-phone {
        margin-bottom: 38px;
      }
    }

    ul {
      @include non-phone {
        @include display-flex();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }

      li {
        background: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        margin: 10px 0;
        padding: 30px 40px;
        @include flex(0 0 49%);

        b {
          display: block;
          font: 30px $semiBoldFont;
          line-height: 37px;
          margin-bottom: 10px;
        }

        div {
          @include display-flex();
          font-size: 60px;
          font-weight: bold;
          line-height: 73px;

          span {
            margin-left: 16px;
          }
        }

        @include for-phone {
          padding: 16px 24px;

          b {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 8px;
          }

          div {
            font-size: 33px;
            line-height: 44px;
          }

          img {
            max-height: 45px;
          }
        }
      }
    }

    ._button-container {
      margin-top: 60px;
      text-align: center;

      @include for-phone {
        margin-top: 54px;

        button {
          width: 100%;
        }
      }
    }
  }

  ._story {
    padding-bottom: 60px;
    padding-top: 100px;

    ._title {
      margin-bottom: 169px;

      @include for-phone {
        margin-bottom: 0;
        text-align: center;
      }
    }

    ._row {
      @include align-items(center);
      @include display-flex();
      @include justify-content(space-between);
      margin-top: 160px;
      position: relative;

      @include non-phone {
        &:not(:last-child):after {
          background: url(/images/swirly-1.svg) no-repeat 50% 60%;
          background-size: 800px;
          bottom: -180px;
          content: "";
          display: block;
          height: 186px;
          left: 0;
          width: 100%;
          position: absolute;
          right: 0;
        }

        &:nth-child(2):after {
          background-image: url(/images/swirly-1.svg);
        }
        &:nth-child(3):after {
          background-image: url(/images/swirly-2.svg);
        }
        &:nth-child(4):after {
          background-image: url(/images/swirly-4.svg);
        }
        &:nth-child(5):after {
          background-image: url(/images/swirly-3.svg);
          bottom: -200px;
          height: 212px;
        }
      }

      ._floating {
        position: absolute;

        &._top-right {
          right: 0;
          top: -70px;
        }

        &._top-left {
          left: 0;
          top: -65px;
        }
      }

      p {
        font-size: 18px;
        line-height: 28px;
      }

      h3 {
        text-transform: capitalize;
        margin-bottom: 32px;
      }

      ._image-container {
        @include flex-shrink(0);
        border-radius: 50%;
        height: 526px;
        overflow: hidden;
        position: relative;
        width: 526px;

        &:first-child {
          margin-right: 92px;
        }

        &:last-child {
          margin-left: 92px;
        }
      }

      #franchise-map {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      @include for-phone {
        @include flex-direction(column);
        margin-top: 65px;

        #franchise-map:after {
          background: url(/images/mobile-map-overlay.png) no-repeat 50% 50%;
          background-size: contain;
          bottom: -1px;
          content: "";
          display: block;
          left: -1px;
          position: absolute;
          right: -1px;
          top: -1px;
          z-index: 3;
        }

        ._image-container {
          @include flex-order(1);
          height: 288px;
          margin: 0 auto 20px !important;
          width: 288px;
        }

        > *:not(._image-container) {
          @include flex-order(2);
        }

        ._floating._top-left {
          top: -12px;
          width: 72px;
        }
        ._floating._top-right {
          top: -12px;
          width: 84px;
        }
      }
    }
  }

  ._how-it-works {
    background: #000;
    padding: 106px 0 222px;

    ._title {
      color: #fff;
      margin-bottom: 110px;
    }

    @include for-phone {
      padding: 60px 0 116px;

      ._title {
        margin-bottom: 40px;
      }
    }

    @include non-phone {
      ._requirements,
      ul {
        margin: 0 110px;
      }
    }

    ._requirements {
      p {
        line-height: 1.35;
        margin: 20px 20px 40px;
        text-align: center;
      }

      > div {
        @include align-items(center);
        @include display-flex();
        @include justify-content(center);

        > * {
          margin: 0 5px !important;
        }
      }

      border-top: 1px solid #aaaaaa;
      color: #fff;
      padding: 40px 0;
      text-align: center;

      @include for-phone {
        display: block;
      }

      b:first-child {
        text-transform: uppercase;

        @include for-phone {
          font-size: 18px;
        }
      }
    }

    ul li {
      @include align-items(center);
      @include display-flex();
      background: #fff;
      padding: 0 40px;

      @include for-phone {
        @include align-items(flex-start);
        padding: 0 20px;
      }

      &:nth-child(even) {
        background: #efefef;
      }

      ._icon {
        @include flex(0 0 64px);
        text-align: center;

        @include for-phone {
          @include flex(0 0 28px);
          margin-top: 60px;
        }
      }

      > div:last-child {
        @include flex-grow(1);
        border-left: 4px solid #000;
        margin-left: 20px;
        padding: 40px 0;

        > div {
          padding: 20px;
        }

        @include for-phone {
          padding: 20px 0;

          > div {
            padding: 16px;
          }
        }
      }

      &:first-child > div:last-child {
        margin-top: 40px;
        padding-bottom: 40px;
        padding-top: 0;

        @include for-phone {
          margin-top: 20px;
          padding-bottom: 20px;
        }
      }

      &:last-child > div:last-child {
        border-color: transparent;
        position: relative;

        &:before {
          background: #000;
          content: "";
          display: block;
          height: 40px;
          left: -4px;
          position: absolute;
          top: 0;
          width: 4px;

          @include for-phone {
            height: 20px;
          }
        }
      }

      h4 {
        font-size: 40px;
        font-weight: bold;
        line-height: 49px;
        margin-bottom: 10px;

        @include for-phone {
          font-size: 16px;
          line-height: 19px;
        }
      }

      p {
        font: 16px $mediumFont;
        line-height: 20px;

        @include for-phone {
          font: 14px $regularFont;
        }
      }

      small {
        background: #000;
        color: #fff;
        display: inline-block;
        font: 20px $mediumFont;
        line-height: 24px;
        margin-left: -40px;
        margin-bottom: 10px;
        padding: 6px 24px;
        text-transform: uppercase;

        @include for-phone {
          font-size: 12px;
          line-height: 15px;
          margin-left: -20px;
          padding: 2px 8px;
        }
      }
    }
  }

  ._join {
    background: #fad0dc url(/images/franchising-apply.png) no-repeat 50% 0;
    background-size: contain;
    padding: 783px 0 160px;

    @include for-x2 {
      background-image: url(/images/franchising-apply@2x.png);
    }

    @include for-phone {
      padding: 250px 20px 90px;

      h2 {
        font-size: 24px;
        line-height: 29px;
      }

      ._button {
        width: 100%;
      }
    }

    > div {
      margin: 0 auto;
      max-width: 890px;

      p {
        margin: 20px 0;

        @include for-phone {
          font-size: 16px;
          line-height: 20px;
        }
      }

      small {
        display: block;
        font: 12px $mediumFont;
        line-height: 16px;
        margin-top: 160px;

        @include for-phone {
          margin-top: 100px;
        }
      }
    }
  }

  ._availability {
    padding: 100px 0;

    ._title {
      margin-bottom: 110px;
    }

    @include for-phone {
      padding: 60px 0;

      ._title {
        margin-bottom: 20px;
      }
    }

    ul {
      @include align-items(center);
      @include display-flex();
      @include justify-content(center);
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 40px;
      text-transform: uppercase;

      li {
        cursor: pointer;
        padding: 8px 30px;
      }

      ._selected {
        background: #000;
        border-radius: 30px;
        color: #fff;
      }
    }

    ._map-container {
      @include align-items(center);
      @include display-flex();

      ._map {
        @include flex-grow(1);
        height: 500px;
        overflow: hidden;

        @include for-phone {
          height: 202px;
          overflow: hidden;
        }
      }
    }

    ._counts {
      background: #efefef;
      border-radius: 10px;
      box-sizing: border-box;
      font: 30px $semiBoldFont;
      line-height: 37px;
      margin-left: 40px;
      padding: 40px;
      width: 306px;

      b {
        @include align-items(flex-end);
        @include display-flex();
        font-size: 60px;
        line-height: 48px;

        > div {
          margin-right: 16px !important;
        }
      }

      > div:not(:last-child) {
        margin-bottom: 50px;
      }

      @include for-phone {
        &._mobile-only {
          @include align-items(flex-end);
          @include display-flex();
          @include justify-content(center);
          margin: 22px auto 0;
          padding: 20px;
          font-size: 12px;
          line-height: 15px;
          width: 100%;

          > div {
            @include align-items(center);
            @include display-flex();
            @include flex(0 0 30%);
            @include flex-direction(column);
            margin-bottom: 0 !important;

            > div {
              margin-bottom: 5px !important;
            }
          }

          b {
            font-size: 24px;
            line-height: 29px;
          }
        }
      }
    }

    ._input {
      appearance: none;
      background: #fff url(/images/arrow-down.svg) no-repeat calc(100% - 24px) 50%;
      border-radius: 30px;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      font-weight: bold;
      margin-bottom: 20px;
      padding: 8px 20px;
      width: 100%;
    }

    ._legend {
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      margin-top: 47px;
      text-transform: uppercase;
      @include justify-content(center);

      @include for-phone {
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px;
      }

      &,
      div {
        @include align-items(center);
        @include display-flex();
      }

      div {
        margin: 0 20px;
      }

      span {
        border: 1px solid #000;
        border-radius: 17px;
        height: 32px;
        margin-right: 10px;
        width: 32px;

        @include for-phone {
          border-radius: 10px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

._modal._franchising-form-modal ._modal-content {
  display: flex;
  align-items: center;
  height: 80vh;
  overflow: hidden;
  padding: 0 !important;
  position: relative;

  @include non-phone {
    height: 90vh;
    width: 608px !important;
  }

  iframe {
    height: inherit;
    width: 100%;
    z-index: 1;
  }

  ._loader-container {
    @include align-items(center);
    @include display-flex();
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
