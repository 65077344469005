.survey-page {
  margin: 3em auto;
  max-width: 600px;

  @media screen and (max-width: 599px) {
    margin: 1em;
  }

  ._instructions,
  ._survey,
  ._choices {
    padding: 30px 0;
  }

  ._instructions,
  ._survey {
    color: #757575;
    font-size: 1.35em;
    line-height: 1.25em;
  }

  ._instructions {
    font-style: italic;
  }

  ._survey {
    margin: 1em 0 1em 0;

    .catering-dropdown {
      margin-left: 0;
    }

    > li {
      line-height: 1.35em;
    }

    a {
      cursor: pointer;
      font-size: 3em;
      transition: 0.1s;
      width: 80px;
      text-align: center;
      display: inline-block;
    }

    a.selected {
      text-shadow: 0 0 6px black;
    }

    a:active {
      font-size: 2.8em;
    }

    ._choices li {
      background: #efefef;
      cursor: hand;
      cursor: pointer;
      font-size: 0.9em;
      line-height: 1.15em;
      margin-bottom: 8px;
      padding: 16px 10px;

      &:hover {
        background: #e9e9e9;
      }
    }

    ._radio {
      @include align-items(center);
      @include display-flex();

      & > span {
        border: 2px solid #999;
        border-radius: 18px;
        height: 18px;
        margin-right: 10px;
        width: 18px;

        &._checked {
          background: #fff;
          border-color: #febdcc;

          &:before {
            background: #febdcc;
            border-radius: 6px;
            content: "";
            display: block;
            height: 12px;
            margin: 3px;
            width: 12px;
          }
        }
      }
    }

    textarea,
    ._inputs input[type="text"] {
      background: #efefef;
      border: 2px solid #9b9b9b;
      box-sizing: border-box;
      font-size: 1em;
      padding: 8px;
    }

    textarea {
      color: #777;
      display: block;
      line-height: 1.15em;
      min-width: 100%;
      max-width: 100%;
      margin-top: 30px;
      width: 100%;
    }

    ._inputs {
      li {
        margin: 16px 0;
      }
      input {
        color: #777;
        margin-right: 8px;
        text-align: center;
        width: 60px;

        &._longer {
          margin-left: 8px;
          text-align: initial;
          width: auto;
        }
      }
    }

    textarea:focus,
    ._inputs input:focus {
      border-color: #febdcc;
    }
  }

  ._btn-cnt {
    text-align: right;

    button,
    ._loader-cnt {
      background: #febdcc;
      border: 0;
      border-radius: 3px;
    }

    button {
      color: #fff;
      cursor: hand;
      cursor: pointer;
      font-size: 24px;
      font-weight: 200;
      padding: 0 15px 5px 17px;
    }

    ._loader-cnt {
      display: inline-block;
      padding: 5px;

      span {
        display: inline-block;
        margin-bottom: -2px;
      }
    }
  }

  ._2ps p:first-child {
    margin-bottom: 30px;
  }

  ._ratings-cnt {
    font-size: 0.9em;
    margin: 30px 0;

    ._ratings-head {
      margin-bottom: 16px;
    }

    ._ratings-choices,
    ._ratings-head {
      @include display-flex();
      @include justify-content(space-between);
    }

    ._ratings-choices {
      font-size: 0.8em;
      text-align: center;
      ._radio > span {
        cursor: hand;
        cursor: pointer;
        margin-right: 0;
      }
    }
  }
}
