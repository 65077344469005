$baseFont: "Montserrat", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
  "Lucida Grande", sans-serif;
@function extendFont($font) {
  @return #{$font + ", " + $baseFont};
}

$thinFont: extendFont("Montserrat Thin");
$extraLightFont: extendFont("Montserrat ExtraLight");
$lightFont: extendFont("Montserrat Light");
$regularFont: extendFont("Montserrat Regular");
$mediumFont: extendFont("Montserrat Medium");
$semiBoldFont: extendFont("Montserrat SemiBold");
$boldFont: extendFont("Montserrat Bold");
$extraBoldFont: extendFont("Montserrat ExtraBold");
$blackFont: extendFont("Montserrat Black");
